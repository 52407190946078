import React, { useState, useEffect } from "react";
import SiteHeader from "../components/header";
import MiniHeader from "../components/miniHeader";
import { LoadingOutlined } from "@ant-design/icons";
import "../styles/about.css";
import { Spin } from "antd";
import { getSingleBlog } from "../api/requests";
import moment from "moment";
import Footer from "../components/footer";
import Workout from "../components/getWorkout";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import * as Sentry from "@sentry/react";

const BlogDetails = () => {
  Sentry.configureScope((scope) =>
    scope.setTransactionName("Blog Details Page")
  );

  const [blogData, setBlogData] = useState();
  const [loading, setLoading] = useState(true);
  const { id, name } = useParams();
  const [title, setTitle] = useState("Loading..");

  console.log("ID: ", id);
  console.log("name: ", name);

  useEffect(() => {
    getSingleBlog(id).then((res) => {
      console.log(res);
      setBlogData(res.data);
      setLoading(false);
      setTitle(name.split("-").join(" "));
    });
  }, []);
  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 45, color: "#FF1E1E", marginBottom: 20 }}
      spin
    />
  );
  if (loading) {
    return (
      <div class="loader">
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Spin className="spinner" indicator={antIcon} tip="Loading" />
      </div>
    );
  }

  return (
    <div className="container-div">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div style={{ marginBottom: "50px" }}>
        <SiteHeader />
      </div>
      <MiniHeader name={"BLOG"} />
      <div className="blog-details-image-div">
        <img src={blogData.feature_image} width={"100%"} height={"auto"} />
      </div>
      <div className="blog-content-div">
        <d className="content-column-class">
          <p className="blog-mini-title">{blogData.categories.name}</p>
          <p className="blog-title">{blogData.title}</p>
          <div className="blog-author-div">
            <p className="blog-author">{blogData.user.username}</p>
            <p className="blog-author" style={{ marginLeft: "20px" }}>
              {moment(blogData.created_at).format("MMM DD, YYYY")}
            </p>
          </div>
          <div className="blog-main-content">
            <div
              className="blog-main-text"
              dangerouslySetInnerHTML={{
                __html: blogData.converted_description,
              }}
            />
          </div>
        </d>
      </div>
      <Workout />
      <Footer />
    </div>
  );
};

export default BlogDetails;
