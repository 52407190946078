import React, { useState, useEffect } from "react";
import "../styles/trainingplans.css";
import SiteHeader from "../components/header";
import TrainingPlansCard from "../components/trainingPlansCard";
import WhiteGetWorkout from "../components/whiteGetworkout";
import FooterDark from "../components/darkFooter";
import { getTrainingPlans } from "../api/requests";
import * as Sentry from "@sentry/react";
import { useNavigate, useParams } from "react-router-dom";
import { TrainingVideosSkeleton } from "../components/Skeleton/TrainingPlansSkeleton";
import { useTranslation } from "react-i18next";

const AllTrainingPlans = (props) => {
  const { t, i18n } = useTranslation();
  const [items, setItems] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(async () => {
    setLoading(true);
    const value = localStorage.getItem("jwt");
    const initialValue = JSON.parse(value);
    console.log("initialValue", initialValue);
    let params = {
      language_id: i18n.language === "en" ? 7 : 8,
    };
    getTrainingPlans(params, initialValue).then((data) => {
      console.log("data is :::", data);
      if (data.status === 200) {
        setItems(data.series);
        console.log("training plans data:", data);
        setLoading(false);
      }
    });
  }, [i18n.language]);
  Sentry.configureScope((scope) =>
    scope.setTransactionName("All Training Plans")
  );
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      }}
    >
      <div style={{ marginBottom: "60px" }}>
        <SiteHeader />
      </div>
      <div className="training-plans-container2">
        {/* <div className="training-plans-banner-div">
        <div className="training-plans-banner-content-div">
          <img src={blog_img} width={"70%"} height={"auto"} />
        </div>
      </div> */}
        <div className="remove-margin-tp">
          <div className="search-and-date-container-div">
            <p
              style={{
                fontSize: "1.5em",
                color: "#ff1e1e",
                fontWeight: "700",
                marginBottom: 0,
                // marginLeft: "150px",
              }}
            >
              {t("training_plans")}
            </p>

            {/* Training plan search div was here */}
          </div>
          <div className="display-all-training-plans-div">
            {loading ? (
              <TrainingVideosSkeleton />
            ) : (
              items.length > 0 &&
              items.map((id, key) => (
                <TrainingPlansCard
                  id={id.series_id}
                  title={id.series_name}
                  seriesId={id.series_id}
                  thumbnail={id.thumbs.original}
                  training_plan_duration={id.training_plan_duration}
                  views={id.views}
                  tags={id.series_tags}
                  description={id.series_description}
                  allTrainingPlans={items}
                  duration={id.duration}
                />
              ))
            )}
          </div>
        </div>
        <WhiteGetWorkout />
        <FooterDark />
      </div>
    </div>
  );
};
export default Sentry.withProfiler(AllTrainingPlans);
