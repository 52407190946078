import React, { useState, useEffect } from "react";
import "../styles/paymentCard.css";
import {
  Button,
  Input,
  Menu,
  Dropdown,
  Modal,
  DatePicker,
  Form,
  Spin,
  Result,
  InputNumber,
} from "antd";
import {
  CreditCardOutlined,
  CheckOutlined,
  DownOutlined,
} from "@ant-design/icons";
import Balance from "../assets/images/balance.png";
import Cash from "../assets/images/cash.png";
import Visa from "../assets/images/visa.svg";
import moment from "moment";
import {
  jazzCashAccount,
  isAuthenticated,
  getNumber,
  iraqLandingCharge,
} from "../api/requests";
import { LoadingOutlined } from "@ant-design/icons";
import OTPInput, { ResendOTP } from "otp-input-react";
import * as Sentry from "@sentry/react";
import HmacSHA256 from "crypto-js/hmac-sha256";
// import CryptoJS from "crypto-js";
// import Base64 from "crypto-js/enc-base64";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#FF1E1E" }} spin />
);

const PaymentMethod = (props) => {
  Sentry.configureScope((scope) => scope.setTransactionName("Payment Method "));

  const [selected, setSelected] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [jazzOpen, setJazzOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [loadText, setLoadText] = useState(false);
  const [name, setName] = useState("");
  const [cnic, setCnic] = useState("");
  const [phone, setPhone] = useState("");
  const [url, setUrl] = useState("");
  const [userData, setUserData] = useState("");
  const [success, setSuccess] = useState(props.packageId === 23 ? true : false);
  const [otpModal, setOtpModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [requestId, setRequestId] = useState("");
  const [hashString, setHashString] = useState("");
  const [hash, setHash] = useState("");
  let number = getNumber();
  let operatorKsa = "";
  if (number.substring(4, 6) === "54" || number.substring(4, 6) === "56") {
    operatorKsa = "KSA-MOBILY";
  }
  const [resendTimer, setResendTimer] = useState(
    operatorKsa === "KSA-MOBILY" ? 90 : 300
  );
  const [resendStatus, setResendStatus] = useState(false);
  const token = isAuthenticated();
  const country = localStorage.getItem("country");

  let operatorZong = JSON.parse(localStorage.getItem("operator"));
  console.log("operator zong xxx is :::", operatorZong);

  let change = "";

  console.log("number is :::", "92" + number);
  console.log("Mobile Number: ", "0" + parseInt(number.substring(1)));

  const dateFormat = "MM/YY";

  useEffect(() => {
    console.log("Trigger");
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // window.scrollTo(0, 0);
    if (resendStatus) {
      resendTimer > 0 &&
        setTimeout(() => setResendTimer(resendTimer - 1), 1000);
    }
  }, [resendTimer, resendStatus]);

  useEffect(() => {
    let requestID = "ReqId" + Math.floor(Math.random() * 10000000000);
    console.log("request id is :::", requestID);
    setRequestId(requestID);
  }, []);

  let serialize = function (obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  };

  const generateHashForJazzCash = () => {
    let hashString =
      "41v8ez82ww" +
      "&" +
      number +
      "&" +
      "99744651" +
      "&" +
      "4y0068bzz3" +
      "&" +
      requestId +
      "&" +
      "https://qa.fitflexapp.com:7172/user_segmentation/jc_callback_api.php";
    let hash = HmacSHA256(hashString, "41v8ez82ww");
    console.log("hash string is :::", hashString);
    setHashString(hashString);
    setHash(hash.toString());
    console.log("hash is ::::", hash.toString());
  };

  const changePlan = () => {
    props.changePlan(change);
    props.reloading();
  };

  const chargingFunction = (e) => {
    e.preventDefault();
    console.log("Package", props.packageId);
    setLoading(true);
    if (name == "" || cnic == "" || phone == "") {
      setMessage("All fields are required");
      setLoading(false);
      return;
    }
    setMessage("");
    const payload = new FormData();
    payload.append("name", name);
    payload.append("phone", phone);
    payload.append("cnic", cnic);
    payload.append("gateway", "JazzAccount");
    payload.append("package_id", parseInt(props.packageId));

    console.log("load", typeof payload.get("package_id"));

    for (let [key, value] of payload.entries()) {
      console.log(key, value);
    }
    jazzCashAccount(payload, token).then((data) => {
      if (data.message == "Error") {
        console.log(data);
      } else {
        if (data.status === 200) {
          console.log("data", data);
          setLoading(false);
          setSuccess(true);
        } else {
          setLoading(false);
          setMessage(data.data.pp_ResponseMessage);
        }
      }
    });
  };

  const mobileBalancePayment = () => {
    setLoading(true);
    setMessage("");
    let payload = new FormData();
    payload.append("package_id", props.packageId);
    payload.append("gateway", "jazz_balance");

    console.log("mobile balance payment payload is :::", payload);
    jazzCashAccount(payload, token).then((data) => {
      if (data.status === 200) {
        console.log("data", data);
        setLoading(false);
        setSuccess(true);
      } else {
        console.log(data);
        setLoading(false);
        setMessage(data.message);
      }
    });
  };

  const CreditCardFunction = () => {
    console.log("credit card", props.packageId);
    // setRedirectLoading(true);
    let payload = new FormData();
    payload.append("package_id", props.packageId);
    payload.append("gateway", "JazzCard");

    for (let [key, value] of payload.entries()) {
      console.log(key, value);
    }
    jazzCashAccount(payload, token)
      .then((data) => {
        if (data.status === 200) {
          console.log(data.data.url);
          let url_data = serialize(data.data.data);
          let final = data.data.url + url_data;
          console.log(final);
          setUrl(data.data.url);
          setUserData(data.data.data);
          console.log(data.data.data);
          setOpen(true);
          // setRedirectLoading(false);
          // setRedirect(true);
          // setLoading(false);
          // return <Navigate to={final} />;
          // window.location.assign(final);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const jazzPayment = (phone) => {
    console.log("in");
    setResendStatus(false);
    setResendTimer(60);

    setLoading(true);
    setLoadText(true);
    setOtp("");
    setMessage("");
    setSelected(2);

    // setOtpModal(true);
    let user = {
      msisdn: parseInt("92" + number.substring(1)),
      package_id: parseInt(props.packageId),
      country_id: 162,
    };
    iraqLandingCharge(user)
      .then((data) => {
        if (data.status === 200) {
          console.log(data);
          setLoading(false);
          setResendStatus(true);
          setLoadText(false);
        } else {
          console.log("fail response: ", data);
          setMessage(data.message);
          setLoading(false);
          setLoadText(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoadText(false);
      });
  };

  const jazzPaymentSubmission = () => {
    setLoading(true);
    setMessage("");
    let user = {
      msisdn: parseInt("92" + number.substring(1)),
      package_id: parseInt(props.packageId),
      country_id: 162,
      otp: otp,
      marketPartner: "web",
    };
    console.log("user payload is :::", user);
    iraqLandingCharge(user)
      .then((data) => {
        if (data.status === 200) {
          // if (data.data.status !== undefined) {
          // if (data.data.status === 400) {
          //   console.log("fail response: ", data);
          //   setMessage("Invalid OTP, try again");
          //   setLoading(false);
          // } else {
          console.log("Succcesss: ", data);
          setOtp("");
          setResendTimer(0);
          setLoading(false);
          setSuccess(true);
          // }
          // } else {
          //   console.log("Succcesss: ", data);
          //   setOtp("");
          //   setLoading(false);
          //   setSuccess(true);
          // }
        }
      })
      .catch((err) => {
        console.log("fail response: ", err);
        setLoading(false);
      });
  };

  const iraq_indonesia_payment = () => {
    console.log("saudi in :::");
    if (
      country === "QA" ||
      country === "KSA" ||
      country === "CZ" ||
      (country === "PK" && operatorZong === 9)
    ) {
      console.log("QA FUNCTION", "420" + number.substring(1));
      console.log("number zong pak is :::", number.substring(1));

      setResendTimer(operatorKsa === "KSA-MOBILY" ? 90 : 180);
      setResendStatus(true);
      setLoading(true);
      setLoadText(true);
      setOtp("");
      setMessage("");
      setSelected(2);
      setOtpModal(true);

      // setOtpModal(true);
      console.log("zong package id is ", props.packageId);
      let user = {
        msisdn:
          country === "CZ" ? "420" + number.substring(1) : number.substring(1),
        package_id: props.packageId,
        country_id:
          country === "QA"
            ? 174
            : country === "CZ"
            ? 57
            : country === "PK"
            ? 162
            : 187,
      };
      console.log("zong user is :::", user);
      iraqLandingCharge(user)
        .then((data) => {
          console.log("iraq landing charge hit :::", data);
          if (data.status === 200) {
            console.log("ZONG DATA IS dishkaon dishkaon::;", data);
            // if (data.data.status === 400) {
            //   setMessage(data.data.msg);
            // }
            setLoading(false);
            setResendStatus(true);
            setLoadText(false);
          } else {
            console.log("fail response: ", data);
            // setMsg(data.data.message);
            setMessage(data.message);
            setLoading(false);
            setLoadText(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          setLoadText(false);
          console.log("iraq landing charge error is", err);
        });
    } else {
      setResendTimer(operatorKsa === "KSA-MOBILY" ? 90 : 300);
      setResendStatus(true);
      setLoading(true);
      setLoadText(true);
      setOtp("");
      setMessage("");
      setSelected(2);
      setOtpModal(true);
      let payload = new FormData();
      payload.append("package_id", parseInt(props.packageId));
      payload.append("gateway", "iraq_ind");
      jazzCashAccount(payload, token)
        .then((data) => {
          console.log(data);
          if (data.status === 200) {
            console.log(data);
            setLoadText(false);
            setLoading(false);
          } else {
            console.log("fail response: ", data);
            setMessage(data.data.message);
            setLoading(false);
            setLoadText(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const iraq_indonesia_payment_withOtp = () => {
    if (
      country === "QA" ||
      country === "KSA" ||
      country === "CZ" ||
      (country === "PK" && operatorZong === 9)
    ) {
      setLoading(true);
      setMessage("");
      let user = {
        msisdn: number.substring(1),
        package_id: props.packageId,
        country_id:
          country === "QA"
            ? 174
            : country === "CZ"
            ? 57
            : country === "PK"
            ? 162
            : 187,
        otp: otp,
        marketPartner: "web",
      };
      console.log("KSA user payload is :::", user);
      iraqLandingCharge(user)
        .then((data) => {
          console.log("ksa iraq landing charge :::", data);
          if (data.status === 200) {
            // if (data.data.status !== undefined) {

            // if (data.data.status === 400) {
            //   console.log("fail response: ", data);
            //   setMessage("Invalid OTP, try again");
            //   setLoading(false);
            // } else {
            console.log("Succcesss new dishkaon dishkaon: ", data);
            setOtp("");
            // console.log("data status is :::", data.data.status);
            setLoading(false);
            setSuccess(true);
            setResendTimer(0);
            // }
          } else {
            setMessage(data.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log("fail response: ", err);
          setLoading(false);
        });
    } else {
      setLoading(true);
      setMessage("");
      let payload = new FormData();
      payload.append("package_id", parseInt(props.packageId));
      payload.append("gateway", "iraq_ind");
      payload.append("otp", otp);
      jazzCashAccount(payload, token)
        .then((data) => {
          if (data.status === 200) {
            console.log("Succcesss: ", data);
            setOtp("");
            // setOtpModal(false);
            setLoading(false);
            setOtpModal(false);
            setSuccess(true);
          } else {
            console.log("fail response: ", data);
            setMessage("Invalid OTP, try again");
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log("fail response: ", err);
          console.log(err);
        });
    }
  };

  const menu = (
    <Menu>
      {/* <Menu.Item key="0">
        <span onClick={CreditCardFunction}>Credit/Debit Card</span>
      </Menu.Item> */}
      <Menu.Item key="1">
        <span onClick={selected !== 2 ? jazzPayment : console.log("click")}>
          Mobile Balance
        </span>
      </Menu.Item>
      <Menu.Item key="2">
        <span
          onClick={() => {
            setResendStatus(false);
            setSelected(3);
          }}
        >
          JazzCash Account
        </span>
      </Menu.Item>
      {/* <Menu.Divider />
      <Menu.Item key="3">3rd menu item</Menu.Item> */}
    </Menu>
  );

  return (
    <div
      className="container1"
      style={{ width: operatorKsa === "KSA-MOBILY" && "65%" }}
    >
      {country === "PK" && (operatorZong !== 9 || operatorZong !== 11) ? (
        <div
          className="leftDiv"
          style={{
            marginTop: 20,
          }}
        >
          {success ? (
            <Result
              status="success"
              title="You have successfully subscribed!"
              subTitle={message}
              // extra={[
              //   <Button key="buy" color="black" onClick={() => setSuccess(false)}>
              //     Buy Again
              //   </Button>,
              // ]}
            />
          ) : selected != "" ? (
            selected === 1 ? (
              <>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <p
                    className="heading"
                    style={{
                      marginBottom: 60,
                    }}
                  >
                    Credit Card
                  </p>
                  <Dropdown overlay={menu} trigger={["click"]}>
                    <a
                      className="ant-dropdown-link"
                      style={{
                        height: "fit-content",
                        marginTop: 14,
                        color: "#000",
                        fontWeight: "500",
                      }}
                      onClick={(e) => e.preventDefault()}
                    >
                      Credit/Debit Card <DownOutlined />
                    </a>
                  </Dropdown>
                </div>
                <p className="input-heading" style={{ alignSelf: "baseline" }}>
                  Card Number
                </p>
                <Input
                  placeholder={"XXXX XXXX XXXX XXXX"}
                  type="number"
                  className="input-field"
                  suffix={<img src={Visa} />}
                />
                <p className="input-heading" style={{ alignSelf: "baseline" }}>
                  Card Name
                </p>
                <Input
                  placeholder={"Enter Your Name"}
                  className="input-field"
                />
                <div className="input-columns">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      className="input-heading"
                      style={{ alignSelf: "baseline" }}
                    >
                      Expiry Date
                    </p>
                    <DatePicker
                      defaultValue={moment("03/26", dateFormat)}
                      format={dateFormat}
                      className="input-field"
                    />
                  </div>
                  <div className="ccv">
                    <p
                      className="input-heading"
                      style={{ alignSelf: "baseline", marginLeft: 5 }}
                    >
                      CCV
                    </p>
                    <Input
                      placeholder={"..."}
                      className="input-field"
                      maxLength={3}
                      type={"password"}
                    />
                  </div>
                </div>
                <Button className="proceed-btn" block>
                  Proceed
                </Button>
              </>
            ) : selected === 2 ? (
              <>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <p
                    className="heading"
                    style={{
                      marginBottom: 60,
                    }}
                  >
                    Payment Method
                  </p>
                  <Dropdown overlay={menu} trigger={["click"]}>
                    <a
                      className="ant-dropdown-link"
                      style={{
                        height: "fit-content",
                        marginTop: 14,
                        color: "#000",
                        fontWeight: "500",
                      }}
                      // onClick={jazzPayment}
                    >
                      Mobile Balance <DownOutlined />
                    </a>
                  </Dropdown>
                </div>
                {/* <p className="input-heading">Mobile Number</p>
                <Input
                  placeholder="03XX-XXXXXXX"
                  className="input-field"
                  type="number"
                  disabled={true}
                  value={getNumber() === false ? "03XX-XXXXXXX" : getNumber()}
                />
                {loading ? (
                  <div className="loading-div">
                    <Spin indicator={antIcon} tip="Processing..." />
                  </div>
                ) : (
                  <Button
                    className="proceed-btn"
                    block
                    onClick={mobileBalancePayment}
                  >
                    Proceed
                  </Button>
                )} */}
                <>
                  <h1
                    style={{
                      // color: "#ff1e1e",
                      fontWeight: "600",
                      fontSize: 17,
                      textAlign: "left",
                    }}
                  >
                    {!loadText
                      ? "Enter OTP recieved on your number"
                      : "Sending OTP to your number"}
                  </h1>
                  <p
                    style={{
                      // color: "#ff1e1e",
                      color: "black",
                      fontWeight: "600",
                      fontSize: 14,
                      textAlign: "left",
                    }}
                  >
                    Didn't get it?
                    {resendTimer <= 0 ? (
                      <span
                        onClick={jazzPayment}
                        style={{
                          color: "#ff1e1e",
                          textDecoration: "underline",
                          marginLeft: 5,
                        }}
                      >
                        Resend OTP
                      </span>
                    ) : (
                      <span
                        style={{
                          color: "#ff1e1e",
                          textDecoration: "underline",
                          marginLeft: 5,
                        }}
                      >
                        OTP will Expire in {resendTimer} seconds
                      </span>
                    )}
                  </p>
                  {message !== "" && (
                    <p style={{ color: "red", textAlign: "left" }}>{message}</p>
                  )}
                  <OTPInput
                    value={otp}
                    onChange={setOtp}
                    autoFocus
                    OTPLength={country === "IQ" ? 5 : 4}
                    otpType="number"
                    disabled={false}
                    // secure
                    inputClassName="subs-otpinput"
                    className="subs-otp"
                  />
                  {loading ? (
                    <div className="loading-div">
                      <Spin indicator={antIcon} tip="Please wait..." />
                    </div>
                  ) : (
                    <Button
                      className="proceed-btn"
                      disabled={otp.length === 4 ? false : true}
                      style={{
                        width: "100%",
                      }}
                      block
                      onClick={jazzPaymentSubmission}
                    >
                      Proceed
                    </Button>
                  )}
                </>
              </>
            ) : (
              selected === 3 && (
                <>
                  {/* <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <p
                      className="heading"
                      style={{
                        marginBottom: 60,
                      }}
                    >
                      Payment Method
                    </p>
                    <Dropdown overlay={menu} trigger={["click"]}>
                      <a
                        className="ant-dropdown-link"
                        style={{
                          height: "fit-content",
                          marginTop: 14,
                          color: "#000",
                          fontWeight: "500",
                        }}
                        onClick={(e) => e.preventDefault()}
                      >
                        JazzCash Account <DownOutlined />
                      </a>
                    </Dropdown>
                  </div>
                  {message != "" && <p style={{ color: "red" }}>{message}</p>}
                  <p className="input-heading">Name</p>
                  <Input
                    placeholder="Enter your name"
                    className="input-field"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <p className="input-heading">JazzCash Account Number</p>
                  <Input
                    placeholder="03XXXXXXXXX"
                    className="input-field"
                    maxLength={11}
                    value={phone}
                    controls={false}
                    onChange={(e) => {
                      const re = /^[0-9\b]+$/;
                      // if value is not blank, then test the regex
                      if (e.target.value === "" || re.test(e.target.value)) {
                        setPhone(e.target.value);
                      }
                    }}
                  />
                  <p className="input-heading">CNIC (last 6 digits)</p>
                  <Input
                    placeholder="Last 6 digits of your CNIC"
                    className="input-field"
                    // type="number"
                    maxLength={6}
                    value={cnic}
                    onChange={(e) => setCnic(e.target.value)}
                  /> */}
                  {/* <Checkbox className="checkbox">
                  Save your JazzCash Account for future use.
                </Checkbox> */}
                  {/* {loading ? (
                    <div className="loading-div">
                      <Spin
                        indicator={antIcon}
                        tip="Communicating with jazzcash server..."
                      />
                    </div>
                  ) : (
                    <Button
                      className="proceed-btn"
                      block
                      onClick={chargingFunction}
                    >
                      Proceed
                    </Button>
                  )} */}
                  <Modal
                    title="Info"
                    className="alert-modal"
                    visible={jazzOpen}
                    footer={null}
                    onCancel={() => setJazzOpen(false)}
                  >
                    This will redirect your page to JazzCash payment screen,
                    press submit to redirect.
                    <form
                      method="post"
                      action={
                        "https://payments.jazzcash.com.pk/WalletLinkingPortal/wallet/LinkWallet"
                      }
                    >
                      <input
                        type={"hidden"}
                        name="integeritySalt"
                        value={"41v8ez82ww"}
                      />
                      <input type={"hidden"} name="pp_MSISDN" value={number} />
                      <input
                        type={"hidden"}
                        name="pp_MerchantID"
                        value={"99744651"}
                      />
                      <input
                        type={"hidden"}
                        name="pp_Password"
                        value={"4y0068bzz3"}
                      />
                      <input
                        type={"hidden"}
                        name="pp_RequestID"
                        value={requestId}
                      />
                      <input
                        type={"hidden"}
                        name="pp_ReturnURL"
                        value={
                          "https://qa.fitflexapp.com:7172/user_segmentation/jc_callback_api.php"
                        }
                      />
                      <input
                        type={"hidden"}
                        name="pp_SecureHash"
                        value={hash + ""}
                      />
                      <input
                        name="jazzcashform"
                        type="submit"
                        style={{
                          float: "right",
                          background: "#ff1e1e",
                          color: "white",
                          border: 0,
                          borderRadius: 10,
                          padding: 10,
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      />
                      Submit
                    </form>
                  </Modal>
                </>
              )
            )
          ) : (
            <>
              <p className="heading">Payment Method</p>
              <div className="part">
                <Modal
                  title="Info"
                  className="alert-modal"
                  visible={open}
                  footer={null}
                  onCancel={() => setOpen(false)}
                >
                  This will redirect your page to credit card payment screen,
                  press submit to redirect.
                  <form method="POST" action={url}>
                    <input
                      type={"hidden"}
                      name="pp_Amount"
                      value={userData.pp_Amount}
                    />
                    <input
                      type={"hidden"}
                      name="pp_BankID"
                      value={userData.pp_BankID}
                    />
                    <input
                      type={"hidden"}
                      name="pp_BillReference"
                      value={userData.pp_BillReference}
                    />
                    <input
                      type={"hidden"}
                      name="pp_Description"
                      value={userData.pp_Description}
                    />
                    <input
                      type={"hidden"}
                      name="pp_Language"
                      value={userData.pp_Language}
                    />
                    <input
                      type={"hidden"}
                      name="pp_MerchantID"
                      value={userData.pp_MerchantID}
                    />
                    <input
                      type={"hidden"}
                      name="pp_Password"
                      value={userData.pp_Password}
                    />
                    <input
                      type={"hidden"}
                      name="pp_ProductID"
                      value={userData.pp_ProductID}
                    />
                    <input
                      type={"hidden"}
                      name="pp_ReturnURL"
                      value={userData.pp_ReturnURL}
                    />
                    <input
                      type={"hidden"}
                      name="pp_SecureHash"
                      value={userData.pp_SecureHash}
                    />
                    <input
                      type={"hidden"}
                      name="pp_SubMerchantID"
                      value={userData.pp_SubMerchantID}
                    />
                    <input
                      type={"hidden"}
                      name="pp_TxnCurrency"
                      value={userData.pp_TxnCurrency}
                    />
                    <input
                      type={"hidden"}
                      name="pp_TxnDateTime"
                      value={userData.pp_TxnDateTime}
                    />
                    <input
                      type={"hidden"}
                      name="pp_TxnExpiryDateTime"
                      value={userData.pp_TxnExpiryDateTime}
                    />
                    <input
                      type={"hidden"}
                      name="pp_TxnRefNo"
                      value={userData.pp_TxnRefNo}
                    />
                    <input
                      type={"hidden"}
                      name="pp_TxnType"
                      value={userData.pp_TxnType}
                    />
                    <input
                      type={"hidden"}
                      name="pp_Version"
                      value={userData.pp_Version}
                    />
                    <input
                      type={"hidden"}
                      name="ppmpf_1"
                      value={userData.ppmpf_1}
                    />
                    <input
                      type={"hidden"}
                      name="ppmpf_2"
                      value={userData.ppmpf_2}
                    />
                    <input
                      type={"hidden"}
                      name="ppmpf_3"
                      value={userData.ppmpf_3}
                    />
                    <input
                      type={"hidden"}
                      name="ppmpf_4"
                      value={userData.ppmpf_4}
                    />
                    <input
                      type={"hidden"}
                      name="ppmpf_5"
                      value={userData.ppmpf_5}
                    />

                    <input
                      type="submit"
                      style={{
                        float: "right",
                        background: "#ff1e1e",
                        color: "white",
                        border: 0,
                        borderRadius: 10,
                        padding: 10,
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    />
                  </form>
                </Modal>
                <Button
                  icon={<img src={Balance} className="left-icon" />}
                  className={selected === 2 ? "btn1" : "btn-clear"}
                  onClick={jazzPayment}
                >
                  Mobile Balance
                  <div className="tick">
                    <CheckOutlined
                      style={{
                        color: "red",
                        display: selected != 2 && "none",
                      }}
                    />
                  </div>
                </Button>
                <Button
                  icon={<img src={Cash} className="left-icon" />}
                  className={selected === 3 ? "btn1" : "btn-clear"}
                  onClick={() => {
                    setSelected(3);
                    setJazzOpen(true);
                    generateHashForJazzCash();
                  }}
                >
                  Jazz Cash
                  <div className="tick">
                    <CheckOutlined
                      style={{
                        color: "red",
                        display: selected != 3 && "none",
                      }}
                    />
                  </div>
                </Button>
                <Button
                  icon={<CreditCardOutlined className="payment-icons" />}
                  className={selected === 1 ? "btn1" : "btn-clear"}
                  onClick={CreditCardFunction}
                >
                  Credit/Debit Card
                  <div className="tick">
                    <CheckOutlined
                      style={{
                        color: "red",
                        display: selected != 1 && "none",
                      }}
                    />
                  </div>
                </Button>
              </div>
            </>
          )}
        </div>
      ) : success ? (
        <Result
          status="success"
          title="You have successfully subscribed!"
          subTitle={message}
          // extra={[
          //   <Button key="buy" color="black" onClick={() => setSuccess(false)}>
          //     Buy Again
          //   </Button>,
          // ]}
        />
      ) : (
        <div
          className="leftDiv"
          style={{
            marginTop: 20,
          }}
        >
          <p className="heading">
            {otpModal ? "Mobile Balance" : "Payment Method"}
          </p>
          <div className="part">
            {!otpModal ? (
              <>
                <Button
                  icon={<img src={Balance} className="left-icon" />}
                  className={selected === 2 ? "btn1" : "btn-clear"}
                  onClick={iraq_indonesia_payment}
                >
                  Mobile Balance
                  <div className="tick">
                    <CheckOutlined
                      style={{ color: "red", display: selected != 2 && "none" }}
                    />
                  </div>
                </Button>
                <Button
                  disabled
                  icon={<img src={Cash} className="left-icon" />}
                  className={"btn-clear"}
                  onClick={iraq_indonesia_payment}
                >
                  Mobile Wallet
                  <div className="tick">
                    <CheckOutlined style={{ color: "red", display: "none" }} />
                  </div>
                </Button>
                <Button
                  disabled
                  icon={<CreditCardOutlined className="payment-icons" />}
                  className={"btn-clear"}
                  onClick={() => setSelected(2)}
                >
                  Credit/Debit Card
                  <div className="tick">
                    <CheckOutlined style={{ color: "red", display: "none" }} />
                  </div>
                </Button>
              </>
            ) : (
              <>
                <h1
                  style={{
                    // color: "#ff1e1e",
                    fontWeight: "600",
                    fontSize: 17,
                    textAlign: "left",
                  }}
                >
                  {!loadText
                    ? "Enter OTP recieved on your number"
                    : "Sending OTP to your number"}
                </h1>
                <p
                  style={{
                    // color: "#ff1e1e",
                    color: "black",
                    fontWeight: "600",
                    fontSize: 14,
                    textAlign: "left",
                  }}
                >
                  Didn't get it?
                  {resendTimer <= 0 ? (
                    <span
                      onClick={iraq_indonesia_payment}
                      style={{
                        color: "#ff1e1e",
                        textDecoration: "underline",
                        marginLeft: 5,
                      }}
                    >
                      Resend OTP
                    </span>
                  ) : (
                    <span
                      style={{
                        color: "#ff1e1e",
                        textDecoration: "underline",
                        marginLeft: 5,
                      }}
                    >
                      OTP will Expire in {resendTimer} seconds
                    </span>
                  )}
                </p>
                {message != "" && (
                  <p style={{ color: "red", textAlign: "left" }}>{message}</p>
                )}
                <OTPInput
                  value={otp}
                  onChange={setOtp}
                  autoFocus
                  OTPLength={
                    country === "IQ" ? 5 : operatorKsa === "KSA-MOBILY" ? 6 : 4
                  }
                  otpType={
                    country === "PK" && operatorZong === 9 ? "any" : "number"
                  }
                  disabled={false}
                  // secure
                  inputClassName="subs-otpinput"
                  className="subs-otp"
                />
                {loading ? (
                  <div className="loading-div">
                    <Spin indicator={antIcon} tip="Please wait..." />
                  </div>
                ) : (
                  <Button
                    className="proceed-btn"
                    disabled={
                      country === "IQ"
                        ? otp.length === 5
                          ? false
                          : true
                        : operatorKsa === "KSA-MOBILY"
                        ? otp.length === 6
                          ? false
                          : true
                        : otp.length === 4
                        ? false
                        : true
                    }
                    style={{
                      width: "100%",
                    }}
                    block
                    onClick={iraq_indonesia_payment_withOtp}
                  >
                    Proceed
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      )}
      <div
        className="rightDiv"
        style={{
          paddingTop: 50,
          textTransform: "capitalize",
        }}
      >
        <span>
          <span className="heading">Selected Plan</span>
          <span onClick={changePlan} className="change">
            Select Other Plan
          </span>
        </span>
        <p className="subHeading-small">{props.type}</p>
        <p className="subHeading-big"> {props.rate}</p>
        <p className="subHeading-small" style={{ marginTop: "-20px" }}>
          {props.duration}
        </p>
        {/* <p className="para">{props.duration}</p> */}
        <div className="endDiv">
          <p className="para">Total</p>
          <p className="total">{props.money}</p>
        </div>
      </div>
    </div>
  );
};

export default Sentry.withProfiler(PaymentMethod);
