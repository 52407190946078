import React, { useState, useEffect } from "react";
import SiteHeader from "../components/header";
import WorkoutVideoCard from "../components/workoutVideoCard";
import "../styles/workout.css";
import { Pagination } from "antd";
import WhiteGetWorkout from "../components/whiteGetworkout";
import FooterDark from "../components/darkFooter";
import { useLocation } from "react-router-dom";
import ExerciseVideoCard from "../components/ExerciseVideoCard";

const ExerciseCategoryDetails = (props) => {
  const location = useLocation();
  const [minValue, setMinValue] = useState("0");
  const [maxValue, setMaxValue] = useState("12");
  const [catVideos, setCatVideos] = useState([]);
  const [catName, setCatName] = useState();
  const handleChange = (value) => {
    if (value <= 1) {
      setMinValue("0");
      setMaxValue("12");
    } else {
      setMinValue(minValue * 12);
      setMaxValue(value * 12);

      // window.scrollTo(100, 100);
    }
  };
  useEffect(async () => {
    setCatVideos(JSON.parse(localStorage.getItem("category_videos")));
    setCatName(localStorage.getItem("category_name"));
  }, []);

  return (
    <div className="container-div">
      <div style={{ marginBottom: "50px" }}>
        <SiteHeader />
      </div>
      {console.log("catVides :", catName)}
      <div
        style={{
          marginTop: "10px",
          background: "black",
          color: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="category-name-videos-container-div">
          <div className="category-name-div ">
            <h1 style={{ color: "white" }}>{catName} Exericse</h1>
          </div>
          <div className="category-videos-div">
            {catVideos.length > 0 &&
              catVideos
                .map((id, key) => {
                  return (
                    <ExerciseVideoCard
                      thumbnail={id["secondary-image"]}
                      //   catagoryType={item.tag_name}
                      duration={id["duration-in-seconds"]}
                      // views={index.views}
                      title={id.title}
                      // categories_videos={item.videos}
                      // tags={index.tags}
                      description={id.description}
                      name={id.name}
                      id={id.id}
                      key={key}
                      // video_id={index.videoid}
                    />
                  );
                })
                .slice(minValue, maxValue)}
            {console.log("min-max", minValue, "-", maxValue)}
          </div>
          <Pagination
            defaultCurrent={1}
            defaultPageSize={12}
            onChange={handleChange}
            total={catVideos.length}
            style={{ marginBottom: "20px" }}
          />
        </div>
        <WhiteGetWorkout />
        <FooterDark />
      </div>
    </div>
  );
};

export default ExerciseCategoryDetails;
