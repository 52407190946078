import React from "react";
import { Layout } from "antd";
import "../styles/homescreen.css";
import SiteHeader from "../components/header";
import Footer from "../components/footer";
import Nutrition from "../components/getNutritions";
import Workout from "../components/getWorkout";
import SubHeader from "../components/SubHeader";
import Training from "../components/trainingPlans";
import WorkoutSection from "../components/workout";
import Fitness from "../components/fitnessGoals";
import Cats from "../components/cats";
import * as Sentry from "@sentry/react";

const { Content } = Layout;

const HomeScreen = () => {
  Sentry.configureScope((scope) => scope.setTransactionName("Landing Page"));
  return (
    <Layout className="layout">
      <SiteHeader />
      <Content
        style={{
          backgroundColor: "#fff",
          justifyContent: "space-between",
        }}
      >
        <SubHeader />
        <Fitness />
        <WorkoutSection />
        <Cats />
        <Training />
        <Nutrition />
        <Workout />
      </Content>

      <Footer />
    </Layout>
  );
};

export default Sentry.withProfiler(HomeScreen);
