import React, { useState, useEffect } from "react";
import "../styles/trainingplans.css";
import FooterDark from "../components/darkFooter";
import { Button, Layout, Menu, Result } from "antd";
import SiteHeader from "../components/header";
import Planner_pic from "../assets/images/planner.png";
import { LeftOutlined, RightOutlined, PlusOutlined } from "@ant-design/icons";
import { Calendar } from "antd";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { getMyTrainingPlans } from "../api/requests";
import { useLocation } from "react-router-dom";
import CapsuleVideo from "../components/capsule.js";
import CapsuleActivity from "../components/capsuleActivity";
import ActivityModal from "../components/modalAddActivity";
import * as Sentry from "@sentry/react";
import NutritionCapsule from "../components/nutritionCapsule";
import { Filter } from "react-ionicons";
import { TrainingPlanCard } from "../components/Skeleton/TrainingPlansSkeleton";
import { useTranslation } from "react-i18next";

function AddTrainingplan() {
  Sentry.configureScope((scope) => scope.setTransactionName("Planner Screen"));

  const location = useLocation();
  const { t } = useTranslation();
  const [selectedKey, setSelectedKey] = useState("My Calander");
  const [dateValue, setDateValue] = useState(moment());
  const [showdiv, setShowDiv] = useState(false);
  const [myPlans, setMyPlans] = useState({});
  const [singleCapsule, setSingleCapsule] = useState(true);
  const [filterIndex, setFilterIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(async () => {
    const value = localStorage.getItem("jwt");
    const params = {
      month: moment().format("YYYY-MM"),
    };
    const initialValue = JSON.parse(value);
    getMyTrainingPlans(params, initialValue).then((data) => {
      console.log("data is :::", data);
      if (data.message === "user does not exists") {
        localStorage.clear();
        navigate("/login");
      } else {
        if (data.status === 200) {
          console.log("Data is : ", data);
          setMyPlans(data.data);
          setShowDiv(true);
          setLoading(false);
        }
      }
    });
  }, []);

  const selectedDate = (e) => {
    if (moment(e).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")) {
      setSingleCapsule(false);
    } else {
      setDateValue(e);
      setSingleCapsule(true);
      var index = res
        .map(function (e) {
          return e.date;
        })
        .indexOf(moment(e).format("YYYY-MM-DD"));
      setFilterIndex(index);
    }
    console.log("filter is :::", filterIndex);
  };
  const increaseMonth = () => {
    setDateValue(moment(dateValue).add(1, "M"));
  };
  const decreaseMonth = () => {
    setDateValue(moment(dateValue).subtract(1, "M"));
  };
  const handleClick = (e) => {
    // console.log("clicked ", e);
    setSelectedKey({ selected: e.key });
  };
  function onPanelChange(value, mode) {
    // console.log("OnPANEL CHANGE IS TRIGGERED");
  }

  // this code is to get the count of the date
  // console.log("the type og my plan is", typeof myPlans);
  // const obj = Object.keys(myPlans).reduce((val, cur) => {
  //   console.log("Val :: ", val);
  //   console.log("currrr :: ", cur);
  //   val[cur.date] = val[cur.date] ? val[cur.date] + 1 : 1;
  //   return val;
  // }, {});

  // console.log("object is :::", obj);

  const res = Object.keys(myPlans).map((key) => ({
    date: key,
    count: myPlans[key],
  }));
  console.log("res is :::", res);

  console.log("res is :::", res);
  console.log("filter index is :::", filterIndex);

  function dateCellRender(value) {
    // let x = listDots(value);
    // console.log("xs is :::", x);
    // for (let j = 0; j < res.length; j++) {
    //   if (
    //     res[j].date.includes(value.format("YYYY-MM-DD")) &&
    //     res[j].count > 1
    //   ) {
    //     return getDots();
    //   }
    // }

    // for (let i = 0; i < res.length; i++) {
    //   let count = res[i].count;
    //   console.log("count is :::", count);
    //   for (let j = 0; j < count.length; j++) {
    //     // console.log("i value iss :::", res[i]);
    //     // console.log("j value iss :::", res[j]);

    //     if (count[j].date === value.format("YYYY-MM-DD")) {
    //       //console.log("j value iss :::", res[j].count[j].activity_name);
    //       // console.log("j value iss :::", res[j].count[j]);
    //       // console.log("res i is :::", res[i].date);
    //       // console.log("res i is :::", res[i].count[j].activity_name);

    //       return (
    //         <div
    //           style={{
    //             display: "flex",
    //             justifyContent: "center",
    //             alignItems: "center",
    //             flexDirection: "column",
    //           }}
    //         >
    //           {count[j].activity_name === undefined ? (
    //             <div
    //               style={{
    //                 height: 8,
    //                 width: 8,
    //                 borderRadius: 50,
    //                 backgroundColor: "#ff1e1e",
    //                 display: "flex",
    //                 marginRight: "10px",
    //               }}
    //             ></div>
    //           ) : (
    //             count[j].activity_name === "Nutrition" && (
    //               <div
    //                 style={{
    //                   height: 8,
    //                   width: 8,
    //                   borderRadius: 50,
    //                   backgroundColor: "green",
    //                   display: "flex",
    //                   marginRight: "10px",
    //                 }}
    //               ></div>
    //             )
    //           )}
    //         </div>
    //       );
    //     }
    //   }
    // }
    return (
      <>
        {Object.keys(myPlans).map((item, id) => {
          return (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                {myPlans[item]
                  .map((x, idx) => {
                    if (x.date === value.format("YYYY-MM-DD"))
                      return (
                        <div
                          style={{
                            height: 8,
                            width: 8,
                            borderRadius: 50,
                            backgroundColor:
                              x.activity_name === undefined
                                ? "#ff1e1e"
                                : // : x.activity_name === "Nutrition"
                                // ? "#fff"
                                x.activity_name === "Work"
                                ? "#7773eb"
                                : x.activity_name === "Office"
                                ? "#ff5855"
                                : x.activity_name === "Food"
                                ? "#0fa72b"
                                : x.activity_name === "Gym"
                                ? "#bc68d6"
                                : "none",
                            display: "flex",
                            marginRight: "10px",
                          }}
                        ></div>
                      );
                  })
                  .slice(0, 5)}
              </div>
            </>
          );
        })}
      </>
    );
  }
  return (
    <div className="add-tp-container">
      <div style={{ marginBottom: "40px" }}>
        <SiteHeader />
      </div>

      <div className="content-container-tp">
        <div className="training-text-and-bell-div">
          <p
            style={{
              color: "white",
              fontSize: "30px",
              fontWeight: "700",
              fontFamily: "Montserrat",
            }}
            className="training-planner-heading"
          >
            {t("planner")}
          </p>
          <ActivityModal />
        </div>
      </div>
      <div className="navbar-tp">
        <Menu
          onClick={handleClick}
          defaultSelectedKeys={["My Calander"]}
          mode="horizontal"
          style={{ background: "transparent", color: "white" }}
        >
          <Menu.Item key="My Calander">{t("my_calender")} </Menu.Item>
        </Menu>
      </div>
      <div className="planner-and-calander-div">
        {!loading ? (
          <div className="planner-div">
            <div
              className={
                Object.keys(myPlans).length > 0
                  ? "display-false"
                  : "display-true-div"
              }
            >
              <div>
                <img src={Planner_pic} width={"80%"} height={"auto"} />
              </div>
              <p
                style={{
                  fontSize: "28px",
                  color: "grey",
                  fontFamily: "Montserrat",
                  marginTop: "30px",
                }}
              >
                {t("no_plans")}
              </p>
              <Link to="/alltrainingplans">
                <Button className="add-trainings-btn">
                  {t("choose_Training_plan")}
                </Button>
              </Link>
            </div>
            {/* <div className={myPlans == [] ? "display-false" : "display-true"}> */}
            <div className={!showdiv ? "display-false" : "display-true"}>
              <div
                className={
                  !singleCapsule
                    ? "my-subscribed-plan-card-div"
                    : "display-false"
                }
              >
                {Object.keys(myPlans).length > 0 &&
                  Object.keys(myPlans)
                    ?.map((id, i) => {
                      console.log("id is :::", id);
                      return (
                        <>
                          {myPlans[id].map((item, index) => {
                            console.log("item is :::", item);
                            if (id === moment().format("YYYY-MM-DD")) {
                              if (item.activity_name === undefined) {
                                return (
                                  <>
                                    <CapsuleVideo
                                      key={index}
                                      path={true}
                                      time={item.start_time}
                                      name={item.title}
                                      video_id={item.videoid}
                                      date={id}
                                      index={item.index}
                                    />
                                  </>
                                );
                              }
                            } else {
                              if (id === moment().format("YYYY-MM-DD")) {
                                // console.log("color 2 is working");
                                // if (item.activity_name === "Nutrition") {
                                //   return (
                                //     <NutritionCapsule
                                //       time={item.start_time}
                                //       date={id}
                                //       name={item.description}
                                //       activity_name={item.activity_name}
                                //       start_time={item.start_time}
                                //       end_time={item.end_time}
                                //       description={item.description}
                                //       id={item.id}
                                //       band={item.band}
                                //     />
                                //   );
                                // } else {
                                if (
                                  item.activity_name === "Work" ||
                                  item.activity_name === "Office" ||
                                  item.activity_name === "Food" ||
                                  item.activity_name === "Gym"
                                ) {
                                  return (
                                    <CapsuleActivity
                                      time={item.start_time}
                                      date={item.date}
                                      name={item.description}
                                      activity_name={item.activity_name}
                                      id={item.id}
                                      end_time={item.end_time}
                                      start_time={item.start_time}
                                    />
                                  );
                                }
                                // }
                              }
                            }
                          })}
                        </>
                      );
                    })
                    .slice(0, 10)}
              </div>
              <div
                className={
                  singleCapsule
                    ? "my-subscribed-plan-card-div"
                    : "display-false"
                }
              >
                {Object.keys(myPlans).length > 0 &&
                  // myPlans.length > 0 &&
                  Object.keys(myPlans)

                    .map((id, index) => {
                      return (
                        <>
                          {myPlans[id].map((item, index) => {
                            // console.log("item is x :::", item.videoid);
                            if (item.activity_name === undefined) {
                              return (
                                <CapsuleVideo
                                  path={true}
                                  time={item.start_time}
                                  name={item.title}
                                  video_id={item.videoid}
                                  date={id}
                                  index={item.index}
                                />
                              );
                            } else {
                              // if (item.activity_name === "Nutrition") {
                              //   // console.log("color 3 is working");

                              //   return (
                              //     <NutritionCapsule
                              //       time={item.start_time}
                              //       date={item.date}
                              //       name={item.description}
                              //       activity_name={item.activity_name}
                              //       start_time={item.start_time}
                              //       end_time={item.end_time}
                              //       description={item.description}
                              //       id={item.id}
                              //       band={item.band}
                              //     />
                              //   );
                              // } else {
                              if (
                                item.activity_name === "Work" ||
                                item.activity_name === "Office" ||
                                item.activity_name === "Food" ||
                                item.activity_name === "Gym"
                              ) {
                                return (
                                  <CapsuleActivity
                                    time={item.start_time}
                                    date={item.date}
                                    name={item.description}
                                    activity_name={item.activity_name}
                                    id={item.id}
                                    end_time={item.end_time}
                                    start_time={item.start_time}
                                  />
                                );
                              }
                              // }
                            }
                          })}
                        </>
                      );
                    })
                    .slice(filterIndex, filterIndex + 5)}
                {}
              </div>
            </div>
          </div>
        ) : (
          <TrainingPlanCard />
        )}
        <div className="calander-div">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className="currentMonth-div"
          >
            <p
              style={{
                alignSelf: "flex-start",
                marginLeft: "30px",
                marginTop: "10px",
                fontSize: "20px",
                fontWeight: "700",
                color: "white",
                whiteSpace: "nowrap",
                marginRight: "10px",
                paddingTop: "5px",
              }}
            >
              {dateValue.format("MMMM-YYYY")}
            </p>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <LeftOutlined
                  style={{
                    marginRight: "5px",
                    fontSize: "23px",
                    fontWeight: "700",
                    cursor: "pointer",
                  }}
                  onClick={decreaseMonth}
                />
              </div>
              <div>
                <RightOutlined
                  style={{
                    fontSize: "23px",
                    fontWeight: "700",
                    cursor: "pointer",
                  }}
                  onClick={increaseMonth}
                />
              </div>
            </div>
          </div>
          <div className="site-calendar-demo-card">
            <Calendar
              value={dateValue}
              onPanelChange={onPanelChange}
              onSelect={selectedDate}
              dateCellRender={dateCellRender}
            />
          </div>
        </div>
      </div>
      {/* <WhiteGetWorkout /> */}
      <FooterDark />
    </div>
  );
}

export default Sentry.withProfiler(AddTrainingplan);
