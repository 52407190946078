import React, { useEffect } from "react";
import "../styles/workout.css";
import { PlayCircleOutlined } from "@ant-design/icons";
import clock from "../assets/images/Clock.png";
import eye from "../assets/images/Eye.png";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";

const WorkoutVideoCard = (props) => {
  Sentry.configureScope((scope) =>
    scope.setTransactionName("Workout Video Card")
  );

  const navigate = useNavigate();

  return (
    <Link
      style={{ margin: 0 }}
      to={`/video/${props.video_id}`}
      onFocus={() => {
        // localStorage.clear();
        localStorage.removeItem("video_id");
        localStorage.setItem("video_id", props.video_id);
      }}
    >
      <div
        className={
          props.class === 1
            ? "workout-video-card-x"
            : props.class === 2
            ? "workout-video-card-f"
            : props.class === 3
            ? "workout-video-card-s"
            : "workout-video-card"
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <img
            src={props.thumbnail}
            width={"100%"}
            style={{
              borderRadius: "10px",
              // marginight: "2px",
              // borderBottom: "1px solid grey ",
            }}
          />
          <PlayCircleOutlined
            style={{
              fontSize: "30px",
              color: "#ff0101",
              marginTop: "-35px",
              marginLeft: "10px",
              cursor: "pointer",
            }}
          />
        </div>
        {/* <p className="workout-catagory-text">{props.catagoryType}</p> */}
        <p className="workout-title-text">{props.title} </p>
        <div className="duration-and-views-div">
          <img src={clock} className="clock-n-eye" />
          <p className="duration-and-views-text">{props.duration}</p>
          <img
            src={eye}
            className="clock-n-eye"
            style={{ marginLeft: "10px" }}
          />
          <p className="duration-and-views-text">{props.views}</p>
        </div>
      </div>
    </Link>
  );
};
export default Sentry.withProfiler(WorkoutVideoCard);
