import React, { useState, useEffect } from "react";
import "../styles/homescreen.css";
import { getRecommenderWorkouts } from "../api/requests";
import WorkoutVideoCard from "../components/workoutVideoCard";
import { Link, useNavigate } from "react-router-dom";
import { WorkoutVideosSkeleton } from "./Skeleton/Skeleton";
import { useTranslation } from "react-i18next";

function HomeWorkout() {
  const [loading, setLoading] = useState(true);
  const [trendingVideos, setTrendingVideos] = useState([]);
  const navigate = useNavigate();
  const [t, i18n] = useTranslation();
  useEffect(async () => {
    const value = localStorage.getItem("jwt");
    const initialValue = JSON.parse(value);
    let param = {
      language_id: i18n.language === "en" ? 7 : i18n.language === "id" ? 8 : 9,
    };
    getRecommenderWorkouts(initialValue, param).then((res) => {
      if (res.message === "user does not exists") {
        localStorage.clear();
        navigate("/login");
      } else {
        if (res.status === 200) {
          setTrendingVideos(res.data);
          setLoading(false);
        }
      }
    });
  }, []);

  return (
    <div className="container-div-workout-videos-homepage">
      <div className="heading-div-workout">
        <Link to="/workoutvideos">
          <h1
            style={{
              color: "white",
              justifySelf: "flex-start",
              display: "flex",
              color: "#ff5858",
              fontSize: "1.5em",
            }}
          >
            Workouts
          </h1>
        </Link>
        <Link to="/workoutvideos">
          <h1
            style={{
              color: "white",
              justifySelf: "flex-start",
              display: "flex",
              color: "#ff5858",
              fontSize: "1.5em",
            }}
          >
            View More
          </h1>
        </Link>
      </div>

      {!loading ? (
        <div className="workout-home-videos">
          {trendingVideos.length > 0 &&
            trendingVideos
              .slice(0, 4)
              .map((item, index) => {
                return (
                  <div>
                    <WorkoutVideoCard
                      class={3}
                      thumbnail={item.thumbs.original}
                      catagoryType={item.category_name}
                      duration={item.duration}
                      views={item.views}
                      title={item.title}
                      categories_videos={item.videos}
                      tags={item.tags}
                      description={item.description}
                      video_id={item.videoid}
                    />
                  </div>
                );
              })
              .slice(0, 7)}
        </div>
      ) : (
        <div className="workout-home-videos">
          <WorkoutVideosSkeleton />
        </div>
      )}
    </div>
  );
}

export default HomeWorkout;
