import React, { useEffect, useState } from "react";
import "../styles/trainingplans.css";
import { Link } from "react-router-dom";
import moment from "moment";
import EditModal from "./modalEditTrainingPlan";
import * as Sentry from "@sentry/react";

const Capsule = (props) => {
  Sentry.configureScope((scope) =>
    scope.setTransactionName("Training Plan Capsule")
  );
  console.log("Capsule props are ::: ", props);
  const [path, setPath] = useState();
  useEffect(async () => {
    setPath(props.path);
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        color: "white",
      }}
    >
      <p
        style={{
          justifySelf: "flex-start",
          display: "flex",
          marginBottom: "2px",
        }}
      >
        {moment(props.date).format("DD MMMM, YYYY")}
      </p>
      <div className="Capsule-card-div">
        <div
          style={{
            width: "5px",
            background: "#ff1e1e",
            height: "70%",
            display: "flex",
            borderRadius: "0px 10px 10px 0px",
            alignSelf: "center",
          }}
        ></div>
        {path ? (
          <>
            <div
              style={{
                textDecoration: "none",
                color: "white",
                display: "flex",
                flexDirection: "column",
                width: "87%",
                padding: "10px",
                paddingLeft: "20px",
                textAlign: "left",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Link
                to={`/video/${props.video_id}`}
                state={{
                  video_id: props.video_id,
                  // allTrainingPlans: props.allTrainingPlans,
                }}
                onClick={() => {
                  // localStorage.clear();
                  localStorage.removeItem("video_id");
                  localStorage.setItem("video_id", props.video_id);
                }}
                onFocus={() => {
                  // localStorage.clear();
                  localStorage.removeItem("video_id");
                  localStorage.setItem("video_id", props.video_id);
                }}
                style={{
                  textDecoration: "none",
                  color: "white",
                  display: "flex",
                  flexDirection: "column",
                  width: "87%",
                  padding: "10px",
                  textAlign: "left",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <p
                  style={{
                    color: "#868686",
                    marginBottom: "2px",
                    marginTop: "10px",
                  }}
                  className="capsule-text"
                >
                  {props.time}
                </p>
                <p className="capsule-text">{props.name}</p>
              </Link>
            </div>
            <EditModal
              index={props.index}
              date={props.date}
              time={props.time}
            />
          </>
        ) : (
          <>
            <div
              style={{
                textDecoration: "none",
                color: "white",
                display: "flex",
                flexDirection: "column",
                width: "87%",
                //   border: "1px solid green",
                padding: "10px",
                paddingLeft: "30px",
                textAlign: "left",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <p
                style={{
                  color: "#868686",
                  marginBottom: "2px",
                  marginTop: "10px",
                }}
                className="capsule-text"
              >
                {props.time}
              </p>
              <p className="capsule-text">{props.name}</p>
            </div>
            <EditModal
              index={props.index}
              date={props.date}
              time={props.time}
            />
          </>
        )}

        {/* </Link> */}
      </div>
    </div>
  );
};

// export default Capsule;
export default Sentry.withProfiler(Capsule);
