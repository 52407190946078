import React, { useState } from "react";
import { Button, Spin } from "antd";
import Check from "../assets/images/check.svg";
import "../styles/login.css";
import "../styles/homescreen.css";
import "../styles/setplan.css";
import { DownCircleFilled, UpCircleFilled } from "@ant-design/icons";
import { LoadingOutlined } from "@ant-design/icons";

const SetPlan = (props) => {
  const [collapse, setCollapse] = useState(false);
  const [loading, setLoading] = useState(false);
  const buttonAntIcon = (
    <LoadingOutlined style={{ fontSize: 25, color: "#000" }} spin />
  );

  const handleButtonClick = async () => {
    setLoading(true);

    try {
      // Perform asynchronous operations here, e.g., API calls
      await props.onPress();
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="train-card">
      <p className="content-text2">{props.type}</p>
      <p className="content-text1">{props.rate}</p>
      <p className="content-text2">{props.duration}</p>
      <p className="content-paragraph">{props.description}</p>
      <span
        onClick={() => {
          props.collapseTerms(collapse, props.index);
          props.termsClick();
          setCollapse(!collapse);
        }}
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 5,
        }}
      >
        <p className="content-text2">Terms & Conditions</p>
        {props.open ? (
          <UpCircleFilled className="dropdown-arrow" />
        ) : (
          <DownCircleFilled className="dropdown-arrow" />
        )}
      </span>
      {props.term.map((item, index) => {
        return (
          <div
            key={index}
            className="check-list"
            style={{
              display: collapse && "flex",
            }}
          >
            <img src={Check} />
            <p>{item}</p>
          </div>
        );
      })}
      {JSON.parse(localStorage.getItem("mza")) ? (
        props.loading === true && props.index === props.mzaIndex ? (
          <Spin indicator={buttonAntIcon} />
        ) : (
          <Button
            // disabled={props.isActive && true}
            style={{
              background: props.isActive && "grey",
            }}
            className="subscribe-btn"
            block
            onClick={handleButtonClick}
          >
            {props.isActive
              ? `ACTIVE ${props.isPaid ? ` (PAID) ` : " (UNPAID)"} `
              : `SUBSCRIBE ${props.isPaid ? " (PAID)" : ""} `}
            {props.isActive && props.validDate !== false && (
              <p
                style={{
                  display: props.validDate === false && "none",
                  fontSize: 10,
                  marginBottom: 0,

                  textTransform: "capitalize",
                }}
              >
                Valid till: {props.validDate}
              </p>
            )}

            {props.isActive && (
              <p
                style={{
                  fontSize: 10,
                  marginBottom: 0,
                  textTransform: "capitalize",
                }}
              >
                Click here to cancel subscription
              </p>
            )}
          </Button>
        )
      ) : (
        <Button
          // disabled={props.isActive && true}
          style={{
            background: props.isActive && "grey",
          }}
          className="subscribe-btn"
          block
          onClick={props.onPress}
        >
          {props.isActive
            ? `ACTIVE ${props.isPaid ? ` (PAID) ` : " (UNPAID)"} `
            : `SUBSCRIBE ${props.isPaid ? " (PAID)" : ""} `}
          {props.isActive && props.validDate !== false && (
            <p
              style={{
                display: props.validDate === false && "none",
                fontSize: 10,
                marginBottom: 0,

                textTransform: "capitalize",
              }}
            >
              Valid till: {props.validDate}
            </p>
          )}

          {props.isActive && (
            <p
              style={{
                fontSize: 10,
                marginBottom: 0,
                textTransform: "capitalize",
              }}
            >
              Click here to cancel subscription
            </p>
          )}
        </Button>
      )}
    </div>
  );
};
export default SetPlan;
