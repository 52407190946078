import React, { useState, useEffect } from "react";
import { getSingleJob } from "../api/requests";
import { Spin, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import SiteHeader from "../components/header";
import MiniHeader from "../components/miniHeader";
import Workout from "../components/getWorkout";
import JobCard from "../components/jobCard";
import "../styles/about.css";
import { Divider } from "antd";
import * as Sentry from "@sentry/react";

const CareerDetails = () => {
  Sentry.configureScope((scope) =>
    scope.setTransactionName("Jobs Details Page")
  );

  const [loading, setLoading] = useState(true);
  const [job, setJob] = useState();
  const [alljobs, setallJobs] = useState(
    JSON.parse(localStorage.getItem("alljobs"))
  );
  useEffect(async () => {
    getSingleJob(localStorage.getItem("job_id")).then((data) => {
      if (data.status === 200) {
        setJob(data.data[0]);
        setLoading(false);
      }
    });
  }, [job]);
  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 45, color: "#FF1E1E", marginBottom: 20 }}
      spin
    />
  );
  console.log("All Jobs :", alljobs);
  console.log("one Job :", job);
  if (loading) {
    return (
      <div class="loader">
        <Spin className="spinner" indicator={antIcon} tip="Loading" />
      </div>
    );
  }
  return (
    <div className="container-div">
      <div style={{ marginBottom: "50px" }}>
        <SiteHeader />
      </div>
      <MiniHeader name={"WORK WITH US"} />

      <div className="text-box-div">
        <p className="small-text" style={{ textTransform: "uppercase" }}>
          {job.location}
        </p>
        <div className="job-left-right-container">
          <div className="job-upper-left-div">
            <h1 className="big-text">{job.title}</h1>{" "}
            <p className="text-small-heading">Who are We</p>
            <p className="text-small-description">{job.overview}</p>
            <p className="text-small-heading">Job Overview</p>
            <p className="text-small-description">{job.description}</p>
            <h2 className="small-text-black">Key Responsibilities</h2>{" "}
            <div className="small-bullet-div">
              <ul>
                {job.key_responsibilities.length > 0 &&
                  job.key_responsibilities.map((key, index) => {
                    return <li className="unorderd-list">{key}</li>;
                  })}
              </ul>
            </div>
            <h2 className="small-text-black">Skill Set</h2>{" "}
            <div className="small-bullet-div">
              <ul>
                {job.skill_set.length > 0 &&
                  job.skill_set.map((key, index) => {
                    return <li className="unorderd-list">{key}</li>;
                  })}
              </ul>
            </div>
            <h2 className="small-text-black">Perks and Benefits</h2>{" "}
            <div className="small-bullet-div">
              <ul>
                {job.benefits.length > 0 &&
                  job.benefits.map((key, index) => {
                    return <li className="unorderd-list">{key}</li>;
                  })}
              </ul>
            </div>
            <h2 className="small-text-black">Education Requirments</h2>
            <p className="text-small-description">{job.education}</p>
          </div>
          <div className="job-upper-right-div">
            <div className="job-details-div">
              <p
                style={{
                  justifySelf: "flex-start",
                  alignSelf: "flex-start",
                  fontSize: "17px",
                  fontWeight: "600",
                }}
              >
                Email Your Resume to :
              </p>
              <p
                style={{
                  justifySelf: "flex-start",
                  alignSelf: "flex-start",
                  fontSize: "17px",
                  marginTop: "-10px",
                  color: "#ff1e1e",
                  cursor: "pointer",
                }}
                onClick={() => {
                  notification.success({
                    message: "Success",
                    description: "Email Copied",
                    placement: "bottom",
                    onClick: () => {},
                    className: "always-on-top",
                  });
                  const el = document.createElement("textarea");
                  el.value = "hr.talent@fitflexapp.com";
                  document.body.appendChild(el);
                  el.select();
                  document.execCommand("copy");
                  document.body.removeChild(el);
                }}
              >
                hr.talent@fitflexapp.com
              </p>

              <div className="job-details">
                <p className="details-title">{job.working_hours}</p>
                <p className="details-description">Working Hours</p>
                <Divider
                  style={{ background: "#ff1e1e", marginTop: "-10px" }}
                />
              </div>
              <div className="job-details">
                <p className="details-title">{job.location}</p>
                <p className="details-description">Location</p>
                <Divider
                  style={{ background: "#ff1e1e", marginTop: "-10px" }}
                />
              </div>
              <div className="job-details">
                <p className="details-title">{job.shift}</p>
                <p className="details-description">Job Shift</p>
                <Divider
                  style={{ background: "#ff1e1e", marginTop: "-10px" }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="opening-div">
          <p className="small-text">OTHER OPENINGS</p>
          <p className="big-text">Other Jobs at Fitflex</p>
          <div className="jobs-div">
            {alljobs.length > 0 &&
              alljobs.map((key, index) => {
                if (key.job_id === job.job_id) {
                  return null;
                } else {
                  return (
                    <JobCard
                      job={key.title}
                      city={key.location}
                      created_date={key.created_date}
                      overview={key.description}
                      id={key.job_id}
                      alljobs={alljobs}
                    />
                  );
                }
              })}
          </div>
        </div>
      </div>
      <Workout />
    </div>
  );
};

export default Sentry.withProfiler(CareerDetails);
