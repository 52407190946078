import React, { useState, useEffect } from "react";
import "../styles/loginMarketingDirect.css";
import flag from "../assets/images/CzechFlag.svg";
import { Dropdown, Input, Menu, Modal, Spin } from "antd";
import {
  MobilePackage,
  indonesiaMarketingLogs,
  iraqLandingChargeO2,
} from "../api/requests";
import OTPInput, { ResendOTP } from "otp-input-react";
import { LoadingOutlined } from "@ant-design/icons";
import { GlobalOutlined } from "@ant-design/icons";

import { useTranslation } from "react-i18next";
import Fitflex from "../assets/images/fitflex_logo.png";
import { CloseOutline } from "react-ionicons";
import JazzLogo from "../assets/images/o2Logo.svg";
import Arrow from "../assets/images/arrow-n.svg";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#FF1E1E" }} spin />
);
const buttonAntIcon = (
  <LoadingOutlined style={{ fontSize: 25, color: "white" }} spin />
);

const O2Lp = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [isOtp, setIsOtp] = useState(false);
  const [error, setError] = useState("");
  const [number, setNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [redirectTo, setRedirectTo] = useState(false);
  const [payments, setPayments] = useState([]);
  const [packageValue, setPackageValue] = useState("");
  const [resendStatus, setResendStatus] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);
  const [redirectLoading, setRedirectLoading] = useState(true);
  const [acr, setAcr] = useState("");
  const [isAcr, setIsAcr] = useState(false);
  const [packagePrice, setPackagePrice] = useState();
  const [packageDescription, setPackageDescription] = useState();
  const [packageName, setPackageName] = useState("");
  const [terms, setTerms] = useState([]);
  const [open, setOpen] = useState(false);
  const [lang, setLang] = useState(i18n.language);
  let x = Math.floor(Math.random() * 100);

  useEffect(() => {
    fetch(
      `https://www.dot-jo.biz/operator-he/?MERCHANT=E7B5454ADED9A9E2A9C84FC38805B13CEEBA9BE4&opId=80&TXID=${x}&partnerId=fitflex-cb0b6bc3`,
      {
        method: "GET",
      }
    )
      .then((response) => {
        return response.text();
      })
      .then((response) => {
        console.log("o2 he response is :::", response);
        if (response !== "-1") {
          // alert(response);
          setAcr(response);
          setIsAcr(true);
          setRedirectLoading(false);
          // loginRedirect(response);
        } else {
          // alert(response);
          // setAcr(response);
          // setIsAcr(true);
          setRedirectLoading(false);
        }
      })
      .catch((error) => {
        console.log("error is :::", error);
      });

    let payload = {
      country_id: 57,
      operator: "O2",
      language_id: lang === "en" ? 7 : 32,
    };

    MobilePackage(payload)
      .then((data) => {
        if (data.status === 200) {
          console.log("QATAR: ", data);
          setPayments(data.data);
          setLoading(false);
          setPackageValue(data.data[0].package_id);
          setPackageName(data.data[0].package_name);
          setPackagePrice(data.data[0].package_rate);
          setPackageDescription(data.data[0].package_description);
          setTerms(data.data[0].terms_and_conditions);
          setPackageValue(data.data[0].package_id);
        }
      })
      .catch((err) => console.log(err));

    i18n.changeLanguage("cz");
    setLang("cz");
  }, []);

  useEffect(() => {
    if (resendStatus) {
      resendTimer > 0 &&
        setTimeout(() => setResendTimer(resendTimer - 1), 1000);
    }
  }, [resendTimer, resendStatus]);

  const renderButton = () => {
    return resendTimer <= 0 ? (
      <button
        style={{ cursor: "pointer" }}
        className="resend"
        onClick={() => {
          LoginFunction();
          setResendTimer(60);
          setResendStatus(true);
        }}
      >
        Resend OTP
      </button>
    ) : (
      <button className="resend">
        OTP will Expire in {resendTimer} seconds
      </button>
    );
  };

  const loginRedirect = (acr) => {
    setError("");
    setLoading(true);
    let payload = {
      msisdnAcr: acr,
      package_id: packageValue,
      country_id: 57,
      marketPartner: "web",
    };
    console.log("payload is :::", payload);
    iraqLandingChargeO2(payload)
      .then((data) => {
        console.log("My Payloadss xxx", data);
        if (data.status === 200) {
          setResendStatus(true);
          setLoading(false);
          setError("");
          setIsOtp(true);
          setRedirectLoading(false);
        } else {
          setLoading(false);
          setRedirectLoading(false);
          setError(data.message);
        }
      })
      .catch((err) =>
        setError("Could not login at the moment, please try again later.")
      );
  };

  // } else {
  //   setError(`${t("invalid_pattern")}`);
  // }

  const renderTime = () => React.Fragment;

  // function checkFirstTwoCharacters(str) {
  //   const forbiddenPatterns = /^(50|55|53)/; // Regular expression pattern

  //   // Check if the string matches any of the forbidden patterns
  //   return forbiddenPatterns.test(str);
  // }

  const LoginFunction = () => {
    setError("");
    if (number == "") {
      console.log("Empty Check");
      setError("Please Enter A Valid Number to Continue");
      return;
    }
    //  else if (checkFirstTwoCharacters(number) === false) {
    //   console.log("Invaalid Substring check");
    //   setError("Invalid Number, please try again");
    //   return;
    // }
    else {
      console.log("Test Clear");
      setLoading(true);
      console.log("package value is ::::", packageValue);
      let payload = {
        msisdnAcr: "420" + number,
        package_id: packageValue,
        country_id: 57,
        marketPartner: "web",
      };
      console.log("payload is :::", payload);
      iraqLandingChargeO2(payload)
        .then((data) => {
          console.log("My Payloadss", data);
          if (data.status === 200) {
            // if (data.data.status === 200) {
            setResendStatus(true);
            setLoading(false);
            setError("");
            setIsOtp(true);
            // }
            // else {
            //   setLoading(false);
            //   setError(data.data.msg);
            // }
          } else {
            setLoading(false);
            setError(data.message);
          }
        })
        .catch((err) =>
          setError("Could not login at the moment, please try again later.")
        );
    }
  };

  const onSubmit = () => {
    if (otp.length !== 4) {
      setError(`OTP must be of 4 digits`);
    } else {
      setLoading(true);
      let otpPayload = {
        msisdnAcr: isAcr ? acr : "420" + number,
        package_id: packageValue,
        country_id: 57,
        otp: otp,
        marketPartner: "web",
      };

      iraqLandingChargeO2(otpPayload)
        .then((data) => {
          console.log("response data: ", data);
          if (data.status === 200) {
            // if (data.data.status === 200) {
            let marketingLogPayload = {
              msisdn: isAcr ? acr : "420" + number,
              package_id: packageValue,
              country_id: 57,
              otp: otp,
              marketPartner: "web",
              MarketingUrl: window.location.href,
            };
            indonesiaMarketingLogs(marketingLogPayload)
              .then((response) => {
                if (response.status === 200) {
                  setLoading(false);
                  setError("Package is subscribed succesfully");
                  setTimeout(() => {
                    setRedirectTo(true);
                  }, 1000);
                } else {
                  setLoading(false);
                  setError("Something went wrong.Please try again later!");
                }
              })
              .catch((error) => {
                console.log("error is :::", error);
              });
          } else {
            setLoading(false);
            setError(data.message);
          }
        })
        .catch((err) => {
          setError("Invalid OTP");
          setLoading(false);
        });
    }
  };

  if (redirectTo) {
    window.location.replace("https://fitflexapp.com/checkuserlp");
  }

  if (redirectLoading) {
    return (
      <div className="loader">
        <Spin
          className="spinner"
          indicator={antIcon}
          tip="Fetching Details..."
        />
      </div>
    );
  }

  function toggleClick(language) {
    setLoading(true);
    i18n.changeLanguage(language);
    setLang(language);
    let payload = {
      operator: "zain",
      country_id: 57,
      language_id: language === "en" ? 7 : 32,
    };

    MobilePackage(payload)
      .then((data) => {
        if (data.status === 200) {
          console.log('dadadadadad"""" :::', data.data);
          // setPayments(data.data);
          setLoading(false);
          setPackageName(data.data[0].package_name);
          setPackagePrice(data.data[0].package_rate);
          setPackageDescription(data.data[0].package_description);
          setTerms(data.data[0].terms_and_conditions);
          setPackageValue(data.data[0].package_id);
        }
      })
      .catch((err) => console.log(err));
  }
  function handleMenuClick(e) {
    e.key === "1"
      ? toggleClick("en")
      : e.key === "2"
      ? toggleClick("cz")
      : e.key === "3"
      ? toggleClick("id")
      : toggleClick("en");
  }

  const menu_iq = (
    <Menu onClick={handleMenuClick} className="menu_item">
      <Menu.Item key="1">English</Menu.Item>
      <Menu.Item key="2">Czech</Menu.Item>
    </Menu>
  );

  return (
    <>
      <Modal
        closable
        visible={open}
        closeIcon={<CloseOutline />}
        onCancel={() => setOpen(false)}
        footer={null}
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          borderRadius: "20px",
          padding: "20px",
        }}
        bodyStyle={{
          borderRadius: "20px",
          padding: "30px",
        }}
      >
        <h1
          style={{
            color: "#000",
            textAlign: "center",
            fontFamily: " Manrope",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "92.6%",
            marginBottom: "20px",
            marginTop: "15px",
          }}
        >
          {t("terms_and_conditions")}
        </h1>
        {terms?.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <ul>
                <li
                  style={{
                    color: " #434343",
                    fontFamily: "Manrope",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                  }}
                >
                  {item}
                </li>
              </ul>
            </React.Fragment>
          );
        })}
        {/* // <p>Some contents...</p>
        // <p>Some contents...</p> */}
      </Modal>
      <div className="main-container-div">
        <div className="main-left-container-div">
          {/* <p className="main-left-heading-one">FITFLEX</p> */}
          {/* <Dropdown overlay={menu_pk}>
            <a className="dropdown-ln" onClick={handleMenuClick}>
              {lang.toUpperCase()}
              <GlobalOutlined
                style={{ color: "#ff1e1e", marginLeft: "20px" }}
              />
            </a>
          </Dropdown> */}

          <Dropdown overlay={menu_iq}>
            <a
              className="dropdown-ln"
              style={{ fontFamily: "Manrope" }}
              onClick={handleMenuClick}
            >
              {lang.toUpperCase()}
              <GlobalOutlined
                style={{ color: "#ff1e1e", marginLeft: "20px" }}
              />
            </a>
          </Dropdown>

          <img src={Fitflex} alt="fitflexLogo" />
          <p className="main-left-heading-two">
            {t("world_class_fitness_app")}
          </p>
          <p className="main-left-heading-three">{t("enjoy_videos")} </p>
          <p className="package-text" style={{ fontWeight: "bold" }}>
            {lang === "cz"
              ? "Začněte fitness cestu s 2 dny ZDARMA zkušební verze."
              : "Start Fitness Journey with 2 days of FREE trial"}
          </p>
          <p
            className="package-text"
            style={{
              marginBottom: 0,
              marginTop: 0,
              fontSize: "17px",
              fontWeight: "bold",
            }}
          >
            {packagePrice} CZK/{lang === "cz" ? "týden" : "week"}
          </p>
          {isOtp ? (
            <>
              <p className="enter-number-and-otp-text">{t("otp_digit_o2")}</p>
              <OTPInput
                value={otp}
                onChange={setOtp}
                autoFocus={false}
                OTPLength={4}
                otpType="number"
                disabled={false}
                // secure
                style={{
                  justifyContent: "center",
                }}
                inputClassName="otpinputDiv"
                className="otp-box"
              />
              <ResendOTP
                renderButton={renderButton}
                renderTime={renderTime}
                onResendClick={() => console.log("Resend clicked")}
              />
              <p className="error-text">{error}</p>
              <button className="continueButton-new" onClick={() => onSubmit()}>
                {loading ? <Spin indicator={buttonAntIcon} /> : t("pay")}
              </button>
              <p className="package-text">{t("consent_otp_o2")}</p>
            </>
          ) : isAcr ? (
            <>
              <button
                className="continueButton-new"
                onClick={() => loginRedirect(acr)}
              >
                {loading ? <Spin indicator={buttonAntIcon} /> : t("buy")}
              </button>
              <p className="package-text">{t("consent_landing_two_o2")}</p>
            </>
          ) : (
            <>
              <p className="enter-number-and-otp-text">
                {t("enter_number_o2")}
              </p>
              <div className="input-div">
                <img
                  src={flag}
                  alt="flagIcon"
                  className="flagstyle"
                  style={{
                    marginLeft: "5px",
                    width: "10%",
                    // border: "2px solid black",
                  }}
                />
                <Input
                  className="inputfield"
                  type="number"
                  value={number}
                  onChange={(e) => {
                    const regex = /^[0-9\b]+$/;
                    let lengthCheck = 9;
                    console.log("length check is :::", lengthCheck);
                    if (
                      e.target.value === "" ||
                      (regex.test(e.target.value) &&
                        e.target.value.length <= lengthCheck)
                    ) {
                      setNumber(e.target.value);
                    }
                  }}
                  placeholder="XXXXXXXXXX"
                  bordered={false}
                  prefix={
                    <>
                      <div
                        style={{
                          borderLeft: "1px solid #6f6f6f",
                          height: "30px",
                        }}
                      ></div>
                      <span
                        style={{
                          fontSize: 16,
                          color: "#6f6f6f",
                          // borderLeft: "1px solid black",
                          paddingLeft: "10px",
                          // height: "25px",
                        }}
                      >
                        +420
                      </span>
                    </>
                  }
                  suffix={
                    <>
                      <img src={JazzLogo} alt="operator-logo" />
                    </>
                  }
                />
              </div>

              <p className="error-text">{error}</p>

              <button
                className="continueButton-new"
                onClick={() => LoginFunction()}
              >
                {loading ? <Spin indicator={buttonAntIcon} /> : t("buy")}
              </button>
              <p className="package-text">{t("consent_landing_o2")}</p>
            </>
          )}
          <div className="termsAndCancelDiv" style={{ marginTop: "10px" }}>
            <button
              className="termsAndConditionBtn"
              style={{ marginTop: "0px", width: "50%" }}
              onClick={() => {
                // setOpen(true);
                window.open("https://fitflexapp.com/terms", "_blank");
              }}
            >
              <img src={Arrow} alt="arrow" />
              <div
                style={{
                  height: "15px",
                  border: "0.5px solid rgba(0, 0, 0, 1)",
                }}
              ></div>
              {t("terms_and_conditions")}
            </button>
            <button
              style={{
                width: "20%",
                background: "white",
                textDecoration: "underline",
                border: "none",
                cursor: "pointer",
                marginTop: "0px",
              }}
              className="package-text"
              onClick={() => {
                setNumber("");
                setIsOtp(false);
              }}
            >
              {lang === "cz" ? "zrušení" : "Cancel"}
            </button>
          </div>
          <p className="package-text">customer.support@fitflexapp.com</p>
        </div>
        <div className="main-right-container-div">
          {/* <img
          src={GirlDancingImg}
          style={{ width: "66%", height: "100%" }}
          alt="girlDancing"
        /> */}
        </div>
      </div>
    </>
  );
};

export default O2Lp;
