import React, { useState, useEffect } from "react";
import "../styles/login.css";
import flag from "../assets/images/flag.png";
import iraq from "../assets/images/iraq.png";
import indonesia from "../assets/images/indonesia.png";
import appstore from "../assets/images/appstore.png";
import apple from "../assets/images/apple.png";
import android from "../assets/images/android.png";
import Facebook from "../assets/images/facebook.svg";
import Instagram from "../assets/images/instagram.svg";
import youtube from "../assets/images/youtube.svg";
import { Button, Input, Spin, Alert, Dropdown, Menu, Modal } from "antd";
import {
  userSignup,
  otpVerify,
  authenticate,
  MobilePackage,
  checkNetwork,
  getNumber,
  getmsisdn,
  iraqLandingCharge,
  jazzCashAccount,
  logUser,
  isAuthenticated,
} from "../api/requests";
import OTPInput, { ResendOTP } from "otp-input-react";
import { Navigate, useParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import Icon, {
  GlobalOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import MarketPlan from "../components/marketingPlan";
import { Helmet } from "react-helmet";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#FF1E1E" }} spin />
);
const URL = "https://ip.nf/me.json";

const LoginMarketing = () => {
  const { t, i18n } = useTranslation();

  console.log("T: ", t);
  console.log("I18: ", i18n);
  let x = 0;
  let y = x + 1;
  let { bundle, country, operator, hash } = useParams();
  const [loading, setLoading] = useState(true);
  const [isOtp, setIsOtp] = useState(false);
  const [error, setError] = useState("");
  const [number, setNumber] = useState("");
  const [OTP, setOTP] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [secret, setSecret] = useState("");
  const [redirectTo, setRedirectTo] = useState(false);
  const [payments, setPayments] = useState([]);
  const [lang, setLang] = useState(i18n.language);
  const [info, setInfo] = useState({ ip: "" });
  const [currencyName, setCurrencyName] = useState("");
  const [packageValue, setPackageValue] = useState("");
  const [resendStatus, setResendStatus] = useState(false);
  const [resendTimer, setResendTimer] = useState(country !== "iq" ? 60 : 300);

  let tokenCheck = isAuthenticated();

  console.log("bundle  : ", bundle);
  console.log("country  : ", country);
  console.log("Operator : ", operator);
  console.log("HASH: ", hash);

  useEffect(async () => {
    if (country === "iq") {
      if (window.location.href.indexOf("daily-iq-zain?uniqid") > -1) {
        console.log("Href: ", window.location.href);
        await fetch("https://api.ipify.org/?format=json")
          .then((res) => res.json())
          .then((json) => {
            let user = {
              query: window.location.href,
              page: "failure_page",
              ip: json.ip,
            };
            console.log("User: ", user);
            logUser(user).then((data) => {
              console.log("LOG USER: ", data);
              if (data.status === 200) {
                console.log("Sucess", data);
              }
            });
          });
      }
    }

    if (resendStatus) {
      resendTimer > 0 &&
        setTimeout(() => setResendTimer(resendTimer - 1), 1000);
    }
    fetch(URL, { method: "get" })
      .then((response) => response.json())
      .then((data) => {
        setInfo({ ...data });
      });

    if (operator == "jazz") {
      getmsisdn().then((res) => {
        console.log("RESPONSE: ", res);
        if (res !== undefined) {
          if (res.status === 200) {
            console.log(res);
            if (res.data !== null) {
              let str = res.data;
              str = str.substring(1);
              console.log("Str: ", str);
              setNumber(str);
            }
          } else {
            console.log(res);
          }
        }
      });
    }

    let payload = "";

    if (hash !== undefined) {
      payload = {
        country_id: country === "iq" ? 102 : country === "id" ? 100 : 162,

        operator:
          country === "iq" ? "zain" : country === "id" ? "telkomsel" : "jazz",

        // language_id: i18n.language === "ar" ? 9 : i18n.language === "id" ? 8 : 7,

        language_id: country === "iq" ? 9 : country === "id" ? 8 : 7,
        witskies: hash,
      };
    } else {
      payload = {
        country_id: country === "iq" ? 102 : country === "id" ? 100 : 162,

        operator:
          country === "iq" ? "zain" : country === "id" ? "telkomsel" : "jazz",

        // language_id: i18n.language === "ar" ? 9 : i18n.language === "id" ? 8 : 7,

        language_id: country === "iq" ? 9 : country === "id" ? 8 : 7,
      };
    }

    console.log("Payload: ", payload);

    MobilePackage(payload)
      .then((data) => {
        if (data.status === 200) {
          console.log("DATA : : :", data.data);
          setPayments(data.data);
          setLoading(false);
          setPackageValue(
            bundle === "daily"
              ? data.data[0].package_id
              : bundle === "weekly"
              ? data.data[1].package_id
              : data.data[2].package_id
          );
        }
      })
      .catch((err) => console.log(err));
    if (country === "iq") {
      toggleClick("ar");
    } else if (country === "id") {
      toggleClick("id");
    } else {
      toggleClick("en");
    }

    country === "iq"
      ? setCurrencyName("Iqd. ")
      : country === "id"
      ? setCurrencyName("Rp. ")
      : setCurrencyName("Rs. ");

    if (resendStatus) {
      resendTimer > 0 &&
        setTimeout(() => setResendTimer(resendTimer - 1), 1000);
    }
  }, [bundle]);

  // console.log("URL: ", window.location.);

  function gtag_report_conversion() {
    window.gtag("event", "conversion", {
      send_to: "AW-10900259174/4sC9CPGm19ADEOaC080o",
      value: 1.0,
      currency: "PKR",
    });
    return false;
  }

  useEffect(() => {
    if (resendStatus) {
      resendTimer > 0 &&
        setTimeout(() => setResendTimer(resendTimer - 1), 1000);
    }
  }, [resendTimer, resendStatus]);

  function toggleClick(language) {
    setLoading(true);
    i18n.changeLanguage(language);
    setLang(language);

    let payload = {
      country_id: country === "iq" ? 102 : country === "id" ? 100 : 162,

      operator:
        country === "iq" ? "zain" : country === "id" ? "telkomsel" : "jazz",

      language_id: i18n.language === "ar" ? 9 : i18n.language === "id" ? 8 : 7,
    };

    MobilePackage(payload).then((data) => {
      if (data.status === 200) {
        console.log("DATA : : :", data.data);
        setPayments(data.data);

        setPackageValue(
          bundle === "daily"
            ? data.data[0].package_id
            : bundle === "weekly"
            ? data.data[1].package_id
            : data.data[2].package_id
        );
      }
      setLoading(false);
    });
  }
  if (bundle === "daily") {
    x = 0;
    // i18n.language("ar");
    y = 1;
  } else if (bundle === "weekly") {
    x = 1;
    y = 2;
  } else if (bundle === "monthly") {
    x = 2;
    y = 3;
  }

  // console.log("Bundle: ", bundle);
  // console.log("PAyments: ", payments);
  // console.log("Package_id ", packageValue);

  function handleButtonClick(e) {
    // message.info("Click on left button.");
    console.log("KEEEYY ", e);
    // handleMenuClick(e);
  }
  function handleMenuClick(e) {
    console.log("click", e);
    e.key == "1"
      ? toggleClick("ar")
      : e.key == "2"
      ? toggleClick("en")
      : e.key == "3"
      ? toggleClick("id")
      : toggleClick("en");

    // console.log("LANGUA : ", i18n.language);
  }
  const menu_pk = (
    <Menu onClick={handleMenuClick} className="menu_item">
      {/* <Menu.Item key="1">Arabic</Menu.Item> */}
      <Menu.Item key="2">English</Menu.Item>
      {/* <Menu.Item key="3">Bhasha</Menu.Item> */}
    </Menu>
  );
  const menu_iq = (
    <Menu onClick={handleMenuClick} className="menu_item">
      <Menu.Item key="1">Arabic</Menu.Item>
      <Menu.Item key="2">English</Menu.Item>
      {/* <Menu.Item key="3">Bhasha</Menu.Item> */}
    </Menu>
  );
  const menu_id = (
    <Menu onClick={handleMenuClick} className="menu_item">
      {/* <Menu.Item key="1">Arabic</Menu.Item> */}
      <Menu.Item key="2">English</Menu.Item>
      <Menu.Item key="3">Bhasha</Menu.Item>
    </Menu>
  );

  const renderButton = () => {
    return resendTimer <= 0 ? (
      <button
        style={{ cursor: "pointer" }}
        className="resend"
        onClick={() => {
          LoginFunction();
          setResendTimer(country !== "iq" ? 60 : 300);
          setResendStatus(true);
        }}
      >
        Resend OTP
      </button>
    ) : (
      <button className="resend">
        OTP will Expire in {resendTimer} seconds
      </button>
    );
  };
  const renderTime = () => React.Fragment;

  const LoginFunction = () => {
    if (country == "pk") {
      console.log("Login");
      if (number == "") {
        setError(`${t("is_empty")}`);
        console.log("Login1");
      } else if (number.substr(0, 2) == "03" && number.length == "11") {
        setError("Invalid entry. Required Pattern : +92300-0000000");
      } else if (
        number.substr(0, 1) == "3" &&
        number.length == "10" &&
        number.match(/^[0-9]+$/)
      ) {
        setLoading(true);
        setError("");

        let netCheck = {
          msisdn: number,
        };

        checkNetwork(tokenCheck, netCheck).then((data) => {
          if (data !== undefined) {
            if (data.status === 200) {
              if (
                data.data.network_type === "postpaid" &&
                bundle !== "monthly"
              ) {
                setLoading(false);
                Modal.confirm({
                  title: "Information",
                  icon: <ExclamationCircleOutlined />,
                  content: "This package is only for prepaid customers",
                  okText: "Ok",
                  centered: true,
                  cancelText: () => null,
                  closable: true,
                });
                return;
              } else {
                let pakistanPayload = {
                  msisdn: "92" + number,
                  package_id: packageValue,
                  country_id: 162,
                };
                // console.log("My Payload: ", iraqPayload);
                iraqLandingCharge(pakistanPayload).then((data) => {
                  console.log("My Payloadss", data);
                  if (data.status === 200) {
                    if (data.data.status === 200) {
                      setResendStatus(true);
                      setLoading(false);
                      setError("");
                      setIsOtp(true);
                    } else {
                      setLoading(false);
                      setError(data.data.msg);
                    }
                  } else {
                    setLoading(false);
                    setError(
                      "Could not login at the moment, please try again later."
                    );
                  }
                });
                // const user = {
                //   msisdn: "92" + number,
                // };
                // console.log(user);
                // userSignup(user).then((data) => {
                //   if (data.message == "error") {
                //     setError(data.data);
                //     setLoading(false);
                //   } else {
                //     console.log("Secret: ", data.data);
                //     console.log("Phone: ", user.msisdn);
                //     setResendStatus(true);

                //     setLoading(false);
                //     setError("");
                //     setSecret(data.data);
                //     // setNumber(user.msisdn);
                //     setIsOtp(true);
                //   }
                // });
              }
            }
          }
        });
      } else {
        setError(`${t("invalid_pattern")}`);
      }
    } else if (country == "iq") {
      if (number == "") {
        setError(`${t("is_empty")}`);
        console.log("Login1");
      } else if (number.substr(0, 3) == "964" && number.length == "10") {
        setError("Invalid entry. Required Pattern : +964-XXXXXXXXXX");
      } else if (number.length == "10" && number.match(/^[0-9]+$/)) {
        setLoading(true);
        setError("");

        let iraqPayload = {
          msisdn: "964" + number,
          package_id: packageValue,
          country_id: 102,
        };
        console.log("My Payload: ", iraqPayload);
        iraqLandingCharge(iraqPayload).then((data) => {
          console.log("My Payloadss", data);
          if (data.status === 200) {
            if (data.data.status === 200) {
              setResendStatus(true);
              setLoading(false);
              setError("");
              setIsOtp(true);
            } else {
              setLoading(false);
              setError(data.data.msg);
            }
          } else {
            setLoading(false);
            setError("Could not login at the moment, please try again later.");
          }
        });

        // const user = {
        //   msisdn: "964" + number,
        // };
        // console.log(user);
        // userSignup(user).then((data) => {
        //   if (data.message == "error") {
        //     setError(data.data);
        //     setLoading(false);
        //   } else {
        //     console.log("Secret: ", data.data);
        //     console.log("Phone: ", user.msisdn);

        //     setLoading(false);
        //     setError("");
        //     setSecret(data.data);
        //     // setNumber(user.msisdn);
        //     setIsOtp(true);
        //   }
        // });
      } else {
        setError(`${t("invalid_pattern")}`);
      }
    } else if (country === "id") {
      if (number == "") {
        setError("Field is empty. Enter a valid number.");
      } else if (number.length < 11) {
        setError("Invalid entry");
      } else if (
        // number.substr(0, 1) == "3" &&
        number.length == 11 &&
        number.match(/^[0-9]+$/)
      ) {
        setLoading(true);
        setError("");
        let indonesiaPayload = {
          msisdn: "62" + number,
          package_id: packageValue,
          country_id: 100,
        };
        console.log("My Payload: ", indonesiaPayload);
        iraqLandingCharge(indonesiaPayload).then((data) => {
          console.log(data);
          if (data.status === 200) {
            if (data.data.status === 200) {
              setResendStatus(true);
              setLoading(false);
              setError("");
              setIsOtp(true);
            } else {
              setLoading(false);
              setError(data.data.msg);
            }
          } else {
            setLoading(false);
            setError("Could not login at the moment, please try again later.");
          }
        });
      }
    }
  };

  const onSubmit = () => {
    if (country === "pk") {
      if (OTP.length === 0) {
        return;
      }
      if (OTP.length !== 4) {
        setError(`${t("four_digits")}`);
      } else {
        setLoading(true);

        let otpPayload = {
          msisdn: "92" + number,
          package_id: packageValue,
          country_id: 162,
          otp: OTP,
          marketPartner: "web",
        };

        iraqLandingCharge(otpPayload)
          .then((data) => {
            console.log("response data: ", data);
            if (data.status === 200) {
              if (data.data.status === 200) {
                setLoading(false);
                setError("Package is subscribed succesfully");
                if (bundle === "daily") {
                  window.gtag("event", "conversion", {
                    send_to: "AW-10779246142/WUnCCPbawc8DEL78-JMo",
                  });
                } else if (bundle === "weekly") {
                  window.gtag("event", "conversion", {
                    send_to: "AW-10779246142/MJkbCMTqwc8DEL78-JMo",
                  });
                } else if (bundle === "monthly") {
                  window.gtag("event", "conversion", {
                    send_to: "AW-10779246142/T4jyCJ779s8DEL78-JMo",
                  });
                }
                setTimeout(() => {
                  setRedirectTo(true);
                }, 1000);
              } else {
                setLoading(false);
                setError(data.data.msg);
              }
            } else {
              setLoading(false);
              setError("Invalid OTP");
            }
          })
          .catch((err) => {
            setError("Invalid OTP");
            setLoading(false);
          });
      }

      //   const otpdetails = {
      //     msisdn: secret,
      //     pin: OTP,
      //   };
      //   otpVerify(otpdetails).then((data) => {
      //     if (data.status !== 200) {
      //       setError("Invalid OTP, try again");
      //       setLoading(false);
      //     } else {
      //       let token = data.user.token;
      //       let balance_data = new FormData();
      //       balance_data.append("package_id", packageValue);
      //       balance_data.append("gateway", "jazz_balance");

      //       jazzCashAccount(balance_data, token).then((data) => {
      //         if (data.status === 200) {
      //           setError(
      //             "Your package has been subscribed, please wait for redirection"
      //           );
      //           if (bundle === "daily") {
      //             window.gtag("event", "conversion", {
      //               send_to: "AW-10779246142/WUnCCPbawc8DEL78-JMo",
      //             });
      //           } else if (bundle === "weekly") {
      //             window.gtag("event", "conversion", {
      //               send_to: "AW-10779246142/MJkbCMTqwc8DEL78-JMo",
      //             });
      //           } else if (bundle === "monthly") {
      //             window.gtag("event", "conversion", {
      //               send_to: "AW-10779246142/T4jyCJ779s8DEL78-JMo",
      //             });
      //           }
      //           gtag_report_conversion();
      //           authenticate(token, 0 + number, "PK", () =>
      //             setRedirectTo(true)
      //           );

      //           // setRedirectTo(true);
      //           setTimeout(() => setLoading(false), 2000);
      //         } else {
      //           console.log("API ERRROR ::: ", data);

      //           setError("Package is already subscribed");

      //           setLoading(false);
      //           setTimeout(
      //             () =>
      //               authenticate(token, 0 + number, "PK", () =>
      //                 setRedirectTo(true)
      //               ),
      //             2000
      //           );
      //         }
      //       });
      //     }
      //   });
    } else if (country === "iq") {
      if (OTP.length === 0) {
        return;
      }
      if (OTP.length !== 5) {
        setError("Invalid Entry, try again");
      } else {
        setLoading(true);
        let otpPayload = {
          msisdn: "964" + number,
          package_id: packageValue,
          country_id: 102,
          otp: OTP,
          marketPartner: "web",
        };

        iraqLandingCharge(otpPayload)
          .then((data) => {
            console.log("response data: ", data);
            if (data.status === 200) {
              if (data.data.status === 200) {
                setLoading(false);
                setError("Package is subscribed succesfully");
                gtag_report_conversion();
                setTimeout(() => {
                  setRedirectTo(true);
                }, 1000);
              } else {
                setLoading(false);
                setError(data.data.msg);
              }
            } else {
              setLoading(false);
              setError("Invalid OTP");
            }
          })
          .catch((err) => {
            setError("Invalid OTP");
            setLoading(false);
          });
      }
    } else if (country === "id") {
      if (OTP.length === 0) {
        return;
      }
      if (OTP.length !== 4) {
        setError("Invalid Entry, try again");
      } else {
        setLoading(true);
        let otpPayload = {
          msisdn: "62" + number,
          package_id: packageValue,
          country_id: 100,
          otp: OTP,
          marketPartner: "web",
        };

        console.log(otpPayload);

        iraqLandingCharge(otpPayload)
          .then((data) => {
            console.log("response data: ", data);
            if (data.status === 200) {
              if (data.data.status === 200) {
                setLoading(false);
                setError("Package is subscribed succesfully");
                setTimeout(() => {
                  setRedirectTo(true);
                }, 1000);
              } else {
                setLoading(false);
                setError(data.data.msg);
              }
            } else {
              setLoading(false);
              setError("Invalid OTP");
            }
          })
          .catch((err) => {
            setError("Invalid OTP");
            setLoading(false);
          });
      }
    }
  };

  if (redirectTo) {
    // if (window.location.protocol === "http:") {
    //   window.location.replace("http://fitflexapp.com/checkuserhttp");
    // } else {
    window.location.replace("https://fitflexapp.com/checkuserlp");

    // }

    // return <Navigate to="/home" />;
  }

  return (
    <div className="Login-page-container">
      <div className="containerLeft">
        <div className="terms">
          {loading ? (
            <div class="loader">
              <Spin
                className="spinner"
                indicator={antIcon}
                tip="Fetching Details..."
              />
            </div>
          ) : (
            payments.length > 0 &&
            payments
              .map((item, index) => {
                return (
                  <div className="marketCard">
                    <MarketPlan
                      hash={hash}
                      type={`${t("subscription_plan")}`}
                      rate={
                        t(item.package_name) +
                        ` ${currencyName} ` +
                        item.package_rate
                      }
                      duration={item.package_description}
                      description={t("watch_unlimited")}
                      term={item.terms_and_conditions}
                    />
                  </div>
                );
              })
              .slice(x, y)
          )}
        </div>
      </div>
      <div className="containerRight">
        <div
          style={{
            flexDirection: "row",
            alignItems: "center",
            width: "25vw",
            // border: "1px solid green",
          }}
        >
          <span className="titletext" style={{ color: "#ff1e1e" }}>
            FIT
          </span>
          <span className="titletext" style={{ color: "#707070" }}>
            FLEX
          </span>

          <span className="myDropdown">
            {country == "iq" ? (
              <Dropdown overlay={menu_iq}>
                <a className="dropdown" onClick={handleButtonClick}>
                  {lang.toUpperCase()}
                  <GlobalOutlined
                    style={{ color: "#ff1e1e", marginLeft: "20px" }}
                  />
                </a>
              </Dropdown>
            ) : country == "id" ? (
              <Dropdown overlay={menu_id}>
                <a className="dropdown" onClick={handleButtonClick}>
                  {lang.toUpperCase()}
                  <GlobalOutlined
                    style={{ color: "#ff1e1e", marginLeft: "20px" }}
                  />
                </a>
              </Dropdown>
            ) : (
              <Dropdown overlay={menu_pk}>
                <a className="dropdown" onClick={handleButtonClick}>
                  {lang.toUpperCase()}
                  <GlobalOutlined
                    style={{ color: "#ff1e1e", marginLeft: "20px" }}
                  />
                </a>
              </Dropdown>
            )}
          </span>
        </div>

        {isOtp ? (
          <>
            <h1
              className={lang === "ar" ? "welcome_arabic" : "welcome"}
              style={{
                fontSize: lang === "ar" && 26,
              }}
            >
              {t("enter_otp")}
            </h1>
            <p
              className={lang === "ar" ? "ttext_arabic" : "ttext"}
              style={{
                fontSize: lang === "ar" && 16,
              }}
            >
              <ResendOTP
                renderButton={renderButton}
                renderTime={renderTime}
                onResendClick={() => console.log("Resend clicked")}
              />
            </p>
            <OTPInput
              value={OTP}
              onChange={setOTP}
              autoFocus={false}
              OTPLength={country === "iq" ? 5 : 4}
              otpType="number"
              disabled={false}
              // secure
              inputClassName="otpinput"
              className="otp"
            />
            <p
              className="ttext"
              style={{
                color: "red",
                marginTop: 20,
                marginBottom: 0,
                display: error != "" ? "flex" : "none",
              }}
            >
              {error}
            </p>
            {loading ? (
              <div className="loading-div">
                <Spin indicator={antIcon} tip="Verifying OTP..." />
              </div>
            ) : (
              <Button
                className={lang === "ar" ? "btn-login_arabic" : "btn-login"}
                onClick={onSubmit}
              >
                {t("continue")}
              </Button>
            )}
          </>
        ) : (
          <>
            <h1
              className={lang === "ar" ? "welcome_arabic" : "welcome"}
              style={{
                fontSize: lang === "ar" && 26,
              }}
            >
              {t("welcome_back")}
            </h1>
            ,
            <p
              className={lang === "ar" ? "ttext_arabic" : "ttext"}
              style={{
                fontSize: lang === "ar" && 16,
              }}
            >
              {t("text_you")}
            </p>
            <div className={lang === "ar" ? "inputdiv_arabic" : "inputdiv"}>
              <img
                src={
                  country === "iq"
                    ? iraq
                    : country === "id"
                    ? indonesia
                    : country === "pk"
                    ? flag
                    : flag
                }
                className="flagstyle"
              />
              <Input
                className="inputfeild"
                type="number"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                placeholder="XXXXXXXXXX"
                bordered={false}
                prefix={
                  <span
                    style={{
                      // marginTop: 20,
                      fontSize: 14,
                    }}
                  >
                    {country === "iq"
                      ? "+964"
                      : country === "pk"
                      ? " +92"
                      : country === "id"
                      ? "+62"
                      : "+92"}
                  </span>
                }
              />
            </div>
            <p
              className="ttext"
              style={{
                color: "red",
                marginTop: 20,
                marginBottom: 0,
                display: error != "" ? "flex" : "none",
              }}
            >
              {error}
            </p>
            {loading ? (
              <div className="loading-div">
                <Spin indicator={antIcon} tip="Loading..." />
              </div>
            ) : (
              <Button
                className={lang === "ar" ? "btn-login_arabic" : "btn-login"}
                onClick={LoginFunction}
              >
                {t("continue")}
              </Button>
            )}
          </>
        )}

        <div className="social-icons">
          <a href="https://www.facebook.com/Fitflex-108023608383081/">
            {" "}
            <img src={Facebook} style={{ marginRight: 50 }} />
          </a>
          <a href="https://www.instagram.com/fitflex_app/">
            <img src={Instagram} style={{ marginRight: 50 }} />
          </a>
          <a href="https://www.youtube.com/channel/UCajFgus4QBtdyA0MCTDSUZg">
            {" "}
            <img src={youtube} className="icon-spacing" />
          </a>
        </div>
        <div
          style={{
            marginTop: "50px",
            justifyContent: "center",
            display: "flex",
          }}
          className="margin-top-minus-on-small"
        >
          <a
            href="https://play.google.com/store/apps/details?id=fitflex.workout.fitness.weight.gym.fat.training"
            target="_blank"
          >
            <img
              src={android}
              style={{
                // height: "auto",
                marginRight: "5px",
              }}
              alt="playstore"
              className="storeimg"
            />
          </a>
          <a
            href="https://apps.apple.com/us/app/fitflex/id1613514782"
            target="_blank"
          >
            <img
              src={apple}
              style={{
                // height: "auto",
                border: "1px solid grey",
                borderRadius: "4px",
              }}
              alt="appstore"
              className="storeimg"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default LoginMarketing;
