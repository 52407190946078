import React, { useEffect, lazy, Suspense } from "react";
import { Route, Routes, useParams, useLocation } from "react-router-dom";
import HomeScreen from "../screens/homeScreen";
import Login from "../screens/Login";
import LoginMarket from "../screens/LoginMarketing";
import Plans from "../screens/setplan";
import TermsOfUse from "../screens/TermsOfUse";
import PrivacyPolicy from "../screens/PrivacyPolicy";
import PrivateRouter from "../api/PrivateRouter";
import NotFound from "../components/notfound";
import Contact from "../components/contact";
import About from "../components/aboutUs";
import Cats from "../components/cats";
import CareerDetails from "../screens/careerDetails";
import Career from "../screens/career.js";
import Blog from "../screens/blog";
import BlogDetails from "../screens/blogDetails";
import WorkoutVideos from "../screens/workoutVideos";
import WorkoutDetails from "../screens/workoutDetails";
import TrainingPlansDetails from "../components/trainingPlansDetails";
import AllTrainingPlans from "../screens/allTrainingPlans";
import CategoryDetails from "../screens/categoriesDetails";
import MyTrainingplan from "../screens/myTrainingplan";
import MobileAuth from "../screens/mobileAuth";
import MainHomeScreen from "../screens/mainHomeScreen";
import AboutYou from "../screens/AboutYou";
import Goal from "../screens/goal";
import BlogsCategory from "../screens/blogsCategory";
import FavouritesViewmore from "../screens/favouritesViewmore.js";
import Settings from "../components/settings.js";
import * as Sentry from "@sentry/react";
import MarketingDirect from "../screens/marketingDirect";
import IraqFailure from "../screens/iraqLpFailure";
import CheckUserLp from "../components/checkuserLp";
import AllExerciseScreen from "../screens/AllExerciseScreen";
import SingleExerciseDetails from "../screens/SingleExerciseDetails";
import ExercisePlayer from "../screens/ExercisePlayer";
import ExerciseCategoryDetails from "../screens/ExerciseCategoryDetails";
import NutritionDetails from "../screens/NutritionDetails";
import NutritionActivity from "../components/NutritionActivity";
import NutritionAboutYou from "../components/NutritionAboutYou";
import NutritionConfirmationModal from "../components/NutritionConfirmationModal";
import NutritionScreen from "../screens/NutritonScreen";
import NutritionCategory from "../screens/NutritionCategory";
import LpAboutYou from "../screens/LpAboutYou";
import LpGoal from "../screens/LpGoal";
import ExerciseFavViewMore from "../screens/ExerciseFavViewMore";
import NutritionFav from "../screens/NutritionFav";
import CsLandingPage from "../screens/CsLandingPage";
import InHouseLandingPage from "../screens/InHouseLandingPage";
import SouthAfricaLp from "../screens/SouthAfricaLp";
import QatarLp from "../screens/QatarLp";
import SaudiaArabiaLp from "../screens/SaudiArabiaLp";
import CrossPromoPage from "../screens/CrossPromo";
import StcSaudiaArabiaLp from "../screens/StcSaudiArabiaLp";
import ZongLp from "../screens/ZongLp";
import O2Lp from "../screens/O2Lp";
import SouthAfricaMarketing from "../screens/SouthAfricaMarketing";
import NewPkLandingPage from "../screens/NewPkLandingPage";
// import NewIraqLp from "../screens/NewIraqLp";
import CheckUserIqLp from "../components/checkUserIqLp.js";
import SaudiaArabiaMobilyLp from "../screens/SaudiArabiaMobilyLp.js";
import MyZongApp from "../screens/MyZongApp.js";
import DeletionPolicy from "../screens/DeletionPolicy.js";
import UfoneLandingPage from "../screens/UfoneLandingPage.js";
// const MarketingDirect = lazy(() => import("../screens/marketingDirect"));
// const NewPkLandingPage = lazy(() => import("../screens/NewPkLandingPage"));
// const LoginMarket = lazy(() => import("../screens/LoginMarketing"));
// const Plans = lazy(() => import("../screens/setplan"));
// const Login = lazy(() => import("../screens/Login"));
// const LpAboutYou = lazy(() => import("../screens/LpAboutYou"));
// const Settings = lazy(() => import("../components/settings"));
// const TermsOfUse = lazy(() => import("../screens/TermsOfUse"));
// const InHouseLandingPage = lazy(() => import("../screens/InHouseLandingPage"));
// const ZongLp = lazy(() => import("../screens/ZongLp"));
// const O2Lp = lazy(() => import("../screens/O2Lp"));
// const StcSaudiaArabiaLp = lazy(() => import("../screens/StcSaudiArabiaLp"));
// const CrossPromoPage = lazy(() => import("../screens/CrossPromo"));
// const SouthAfricaMarketing = lazy(() =>
//   import("../screens/SouthAfricaMarketing")
// );
// const SaudiaArabiaLp = lazy(() => import("../screens/SaudiArabiaLp"));
// const QatarLp = lazy(() => import("../screens/QatarLp"));
// const SouthAfricaLp = lazy(() => import("../screens/SouthAfricaLp"));
// const LpGoal = lazy(() => import("../screens/LpGoal"));
// const ExerciseFavViewMore = lazy(() =>
//   import("../screens/ExerciseFavViewMore")
// );
// const NutritionFav = lazy(() => import("../screens/NutritionFav"));
// const CsLandingPage = lazy(() => import("../screens/CsLandingPage"));
// const IraqFailure = lazy(() => import("../screens/iraqLpFailure"));
// const CheckUserLp = lazy(() => import("../components/checkuserLp"));
// const AllExerciseScreen = lazy(() => import("../screens/AllExerciseScreen"));
// const SingleExerciseDetails = lazy(() =>
//   import("../screens/SingleExerciseDetails")
// );
// const ExercisePlayer = lazy(() => import("../screens/ExercisePlayer"));
// const ExerciseCategoryDetails = lazy(() =>
//   import("../screens/ExerciseCategoryDetails")
// );
// const NutritionDetails = lazy(() => import("../screens/NutritionDetails"));
// const NutritionActivity = lazy(() => import("../components/NutritionActivity"));
// const NutritionAboutYou = lazy(() => import("../components/NutritionAboutYou"));
// const NutritionConfirmationModal = lazy(() =>
//   import("../components/NutritionConfirmationModal")
// );
// const NutritionScreen = lazy(() => import("../screens/NutritonScreen"));
// const NutritionCategory = lazy(() => import("../screens/NutritionCategory"));
// const HomeScreen = lazy(() => import("../screens/homeScreen"));
// const PrivacyPolicy = lazy(() => import("../screens/PrivacyPolicy"));
// const PrivateRouter = lazy(() => import("../api/PrivateRouter"));
// const NotFound = lazy(() => import("../components/notfound"));
// const Contact = lazy(() => import("../components/contact"));
// const About = lazy(() => import("../components/aboutUs"));
// const Cats = lazy(() => import("../components/cats"));
// const CareerDetails = lazy(() => import("../screens/careerDetails"));
// const Career = lazy(() => import("../screens/career.js"));
// const Blog = lazy(() => import("../screens/blog"));
// const BlogDetails = lazy(() => import("../screens/blogDetails"));
// // const BlogsCategory=lazy(()=>import("../screens/blogsCategory"))
// const MainHomeScreen = lazy(() => import("../screens/mainHomeScreen"));
// const AboutYou = lazy(() => import("../screens/AboutYou"));
// const MobileAuth = lazy(() => import("../screens/mobileAuth"));
// const WorkoutDetails = lazy(() => import("../screens/workoutDetails"));
// const WorkoutVideos = lazy(() => import("../screens/workoutVideos"));
// const TrainingPlansDetails = lazy(() =>
//   import("../components/trainingPlansDetails")
// );
// const AllTrainingPlans = lazy(() => import("../screens/allTrainingPlans"));
// const CategoryDetails = lazy(() => import("../screens/categoriesDetails"));
// const Goal = lazy(() => import("../screens/goal"));
// const MyTrainingplan = lazy(() => import("../screens/myTrainingplan"));
// const BlogsCategory = lazy(() => import("../screens/blogsCategory.js"));
const NewIraqLp = lazy(() => import("../screens/NewIraqLp.js"));

const MainRouter = () => {
  const { pathname } = useLocation();
  Sentry.configureScope((scope) => scope.setTransactionName("Main Router"));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route exact path="/" element={<HomeScreen />} />
      <Route exact path="/login" element={<Login />} />
      <Route
        exact
        path="/:bundle-:country-:operator"
        element={<LoginMarket />}
      />
      <Route
        exact
        path="ff/:bundle-:country-:operator"
        element={<MarketingDirect />}
      />
      <Route
        exact
        path="/:bundle-:country-:operator/:hash"
        element={<LoginMarket />}
      />
      <Route exact path="/nohe/:bundle-iq-zain" element={<IraqFailure />} />
      <Route exact path="/video/:video_id_url" element={<WorkoutDetails />} />
      <Route
        exact
        path="/exercise/:exercise_id_url"
        element={<SingleExerciseDetails />}
      />
      <Route exact path="/home" element={<HomeScreen />} />
      <Route exact path="/plans" element={<PrivateRouter />}>
        <Route exact path="/plans" element={<Plans />} />
      </Route>
      <Route exact path="/mytrainingplan" element={<PrivateRouter />}>
        <Route exact path="/mytrainingplan" element={<MyTrainingplan />} />
      </Route>
      <Route exact path="/homepage" element={<PrivateRouter />}>
        <Route exact path="/homepage" element={<MainHomeScreen />} />
      </Route>
      <Route exact path="/about" element={<About />} />
      <Route exact path="/blog" element={<Blog />} />
      <Route exact path="/blogdetails/:id/:name" element={<BlogDetails />} />
      <Route
        exact
        path="/trainingplansdetails/:id/:name"
        element={<TrainingPlansDetails />}
      />
      <Route exact path="/settings/:name" element={<PrivateRouter />}>
        <Route exact path="/settings/:name" element={<Settings />} />
      </Route>
      <Route exact path="/careerdetails" element={<CareerDetails />} />
      <Route exact path="/career" element={<Career />} />
      {/* <Route exact path="/exercise" element={<PrivateRouter />}> */}
      <Route exact path="/exercise" element={<AllExerciseScreen />} />
      {/* </Route> */}

      <Route exact path="/nutritiondetails" element={<PrivateRouter />}>
        <Route exact path="/nutritiondetails" element={<NutritionDetails />} />
      </Route>
      <Route exact path="/exercisePlayer" element={<ExercisePlayer />} />
      <Route exact path="/cats" element={<Cats />} />
      <Route exact path="/terms" element={<TermsOfUse />} />
      <Route exact path="/deletionpolicy" element={<DeletionPolicy />} />
      {/* <Route exact path="/settings" element={<Settings />} /> */}
      <Route exact path="/privacy" element={<PrivacyPolicy />} />
      <Route exact path="/contactus" element={<Contact />} />
      {/* <Route exact path="/workoutvideos" element={<WorkoutVideos />} /> */}
      <Route exact path="/aboutyou" element={<AboutYou />} />
      <Route exact path="/goal" element={<Goal />} />
      {/* <Route exact path="/workoutdetails" element={<WorkoutDetails />} /> */}
      <Route exact path="/categorydetails" element={<CategoryDetails />} />
      <Route
        exact
        path="/exerciseCategoryDetails"
        element={<ExerciseCategoryDetails />}
      />
      <Route
        exact
        path="/NutritionActivityModal"
        element={<NutritionActivity />}
      />
      <Route exact path="/nutrition" element={<PrivateRouter />}>
        <Route exact path="/nutrition" element={<NutritionScreen />} />
      </Route>
      <Route exact path="/nutritionCategory" element={<NutritionCategory />} />
      <Route
        exact
        path="/nutritionConfirmation"
        element={<NutritionConfirmationModal />}
      />
      <Route exact path="/LpAboutYou" element={<PrivateRouter />}>
        <Route exact path="/LpAboutYou" element={<LpAboutYou />} />
      </Route>
      <Route exact path="/LpGoal" element={<PrivateRouter />}>
        <Route exact path="/LpGoal" element={<LpGoal />} />
      </Route>
      <Route exact path="/nutritionaboutyou" element={<NutritionAboutYou />} />
      <Route exact path="/fav" element={<FavouritesViewmore />} />
      <Route exact path="/Exercisefav" element={<ExerciseFavViewMore />} />
      <Route exact path="/Nutritionfav" element={<NutritionFav />} />

      <Route exact path="/categoryblog" element={<BlogsCategory />} />
      <Route exact path="/alltrainingplans" element={<AllTrainingPlans />} />
      <Route exact path="/workoutvideos" element={<PrivateRouter />}>
        <Route exact path="/workoutvideos" element={<WorkoutVideos />} />
      </Route>
      <Route exact path="/LpAboutYou" element={<LpAboutYou />}></Route>
      <Route exact path="/LpGoal" element={<LpGoal />}></Route>
      <Route exact path="/workoutdetails" element={<PrivateRouter />}>
        <Route exact path="/workoutdetails" element={<WorkoutDetails />} />
      </Route>
      {/* <Route exact path="/mytrainingplan" element={<MyTrainingplan />} /> */}
      {/* <Route
        exact
        path="/trainingplansdetails"
        element={<TrainingPlansDetails />}
      /> */}
      <Route
        exact
        path="/InHouseLandingPage"
        element={<InHouseLandingPage />}
      />
      <Route exact path="/crosspromotions" element={<CrossPromoPage />} />
      <Route exact path="/landingPageIraq" element={<CsLandingPage />} />
      <Route exact path="/southafricalp" element={<SouthAfricaLp />} />
      <Route exact path="/qatarlp" element={<QatarLp />} />
      <Route exact path="/mobileauth/:hashkey" element={<MobileAuth />} />
      <Route exact path="/checkuserlp" element={<CheckUserLp />} />
      <Route exact path="/checkuseriqlp" element={<CheckUserIqLp />} />

      <Route exact path="/ksazainlp" element={<SaudiaArabiaLp />} />
      <Route exact path="/ksastclp" element={<StcSaudiaArabiaLp />} />
      <Route exact path="fz/:bundle-:country-:operator" element={<ZongLp />} />
      <Route exact path="/O2lp" element={<O2Lp />} />
      <Route exact path="/salp" element={<SouthAfricaMarketing />} />
      <Route exact path="/mza" element={<MyZongApp />} />
      <Route
        exact
        path="/uf/:bundle-:country-:operator"
        element={<UfoneLandingPage />}
      />
      {/* <Suspense fallback={<div>loading....</div>}> */}
      {/* <Route
        exact
        path="/pk/:bundle-:country-:operator"
        element={<NewPkLandingPage />}
      /> */}
      {/* </Suspense> */}
      <Route
        exact
        path="/iq/:bundle-:country-:operator"
        element={<NewIraqLp />}
      />

      <Route exact path="/ksamobilylp" element={<SaudiaArabiaMobilyLp />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Sentry.withProfiler(MainRouter);
