import React, { useEffect } from "react";
import "../styles/about.css";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import moment from "moment";
import * as Sentry from "@sentry/react";

const JobCard = (props) => {
  Sentry.configureScope((scope) =>
    scope.setTransactionName("Jobs Card Component")
  );

  return (
    <Link
      to="/careerdetails"
      onClick={() => {
        // localStorage.clear();
        localStorage.removeItem("job_id");
        localStorage.setItem("job_id", props.id);
        localStorage.removeItem("alljobs");
        localStorage.setItem("alljobs", JSON.stringify(props.alljobs));
      }}
      onFocus={() => {
        // localStorage.clear();
        localStorage.removeItem("job_id");
        localStorage.setItem("job_id", props.id);
        localStorage.removeItem("alljobs");
        localStorage.setItem("alljobs", JSON.stringify(props.alljobs));
      }}
    >
      <div
        className="job-card"
        onClick={() => {
          window.scrollTo(0, 0);
        }}
      >
        <p className="small-text-card">{props.city}</p>
        <p className="job-card-heading">{props.job}</p>
        <p className="days-text">
          Posted on: {moment(props.created_date).format("DD MMM, YYYY")}
        </p>
        <div className="job-description">{props.overview}</div>
        <div className="apply-now-div">
          <p className="apply-now-txt">
            Apply Now <ArrowRightOutlined />
          </p>
        </div>
      </div>
    </Link>
  );
};
export default Sentry.withProfiler(JobCard);
