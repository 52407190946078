import React from "react";
import "../styles/homescreen.css";
import { Card } from "antd";
import apple from "../assets/images/apple.png";
import android from "../assets/images/android.png";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";

function Cats() {
  const { t } = useTranslation();
  return (
    <div className="new-catgories-container">
      <div className="new-cat-left-container">
        <p className="new-categories-title">{t("category_title")}</p>

        <p className="new-categories-description">{t("category_desc")}</p>
        {/* <p
          style={{
            textAlign: "left",
            color: "#ff1e1e",
            fontSize: "20px",
            fontWeight: "700",
          }}
        >
          GET THE APP
        </p> */}
        <div
          style={{
            marginLeft: "-80px",
          }}
          className="CTA-new-cat"
        >
          <a
            href="https://play.google.com/store/apps/details?id=fitflex.workout.fitness.weight.gym.fat.training"
            target="_blank"
          >
            <img
              src={android}
              style={{
                width: 150,
                maxWidth: "100%",
                height: "auto",
                marginBottom: "20px",
              }}
              alt="playstore"
              className="footer-div"
            />
          </a>
          <a
            href="https://apps.apple.com/us/app/fitflex/id1613514782"
            target="_blank"
          >
            <img
              src={apple}
              style={{
                width: 150,
                maxWidth: "100%",
                height: "auto",
                marginBottom: "20px",
              }}
              alt="appstore"
              href="https://apps.apple.com/us/app/fitflex/id1613514782"
              className="footer-div"
            />
          </a>
        </div>
      </div>
      <div className="new-categories-tiles-div">
        <div className="new-cat-right-container">
          <div className="new-categories-column">
            <Card
              className="new-workout-card"
              cover={
                <>
                  <img
                    className="new-img-style-category"
                    src=" https://fitflexapp.com/web/img/workout-categories-cardio-wt.png"
                  />
                </>
              }
            >
              <p className="card-heading">{t("category_card_1")}</p>
            </Card>
            <Card
              className="new-workout-card"
              cover={
                <>
                  <img
                    className="new-img-style-category"
                    src=" https://fitflexapp.com/web/img/workout-categories-stretching-wt.png"
                  />
                </>
              }
            >
              <p className="card-heading">{t("category_card_2")}</p>
            </Card>
            <Card
              className="new-workout-card"
              cover={
                <>
                  <img
                    className="new-img-style-category"
                    src="https://fitflexapp.com/web/img/workout-categories-core-wt.png"
                  />
                </>
              }
            >
              <p className="card-heading">{t("category_card_3")}</p>
            </Card>
          </div>
          <div className="new-categories-column" style={{ marginTop: "12%" }}>
            <Card
              className="new-workout-card"
              cover={
                <>
                  <img
                    className="new-img-style-category"
                    src=" https://fitflexapp.com/web/img/workout-categories-pilates-wt.png"
                  />
                </>
              }
            >
              <p className="card-heading">{t("category_card_4")}</p>
            </Card>
            <Card
              className="new-workout-card"
              cover={
                <>
                  <img
                    className="new-img-style-category"
                    src="https://fitflexapp.com/web/img/workout-categories-HIIT-wt.png"
                  />
                </>
              }
            >
              <p className="card-heading">{t("category_card_5")}</p>
            </Card>
          </div>
          <div className="new-categories-column" style={{ marginTop: "-5%" }}>
            <Card
              className="new-workout-card"
              cover={
                <>
                  <img
                    className="new-img-style-category"
                    src=" https://fitflexapp.com/web/img/workout-categories-meditation-wt.png"
                  />
                </>
              }
            >
              <p className="card-heading">{t("category_card_6")}</p>
            </Card>
            <Card
              className="new-workout-card"
              cover={
                <>
                  <img
                    className="new-img-style-category"
                    src="https://fitflexapp.com/web/img/workout-categories-booty-wt.png"
                  />
                </>
              }
            >
              <p className="card-heading">{t("category_card_7")}</p>
            </Card>
            <Card
              className="new-workout-card"
              cover={
                <>
                  <img
                    className="new-img-style-category"
                    src="https://fitflexapp.com/web/img/workout-categories-yoga-wt-1.png"
                  />
                </>
              }
            >
              <p className="card-heading">{t("category_card_8")}</p>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sentry.withProfiler(Cats);
