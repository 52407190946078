import React, { useState } from "react";
import "../styles/trainingplans.css";
import { EditFilled, DeleteOutlined } from "@ant-design/icons";
import { removeActivity } from "../api/requests";
import { notification, Modal } from "antd";
import EditActivityModal from "./EditActivity.js";
import moment from "moment";
import * as Sentry from "@sentry/react";

const CapsuleActivity = (props) => {
  Sentry.configureScope((scope) =>
    scope.setTransactionName("Activity Component")
  );
  const [showpopup, setShowpopup] = useState(false);

  const handleCancel = () => {
    setShowpopup(false);
  };
  console.log("start date is :::", props.date);
  function deleteActivity() {
    const value = localStorage.getItem("jwt");
    const initialValue = JSON.parse(value);
    const params = {
      id: props.id,
    };
    removeActivity(params, initialValue).then((res) => {
      if (res.status === 200) {
        setTimeout(() => {
          notification.success({
            message: "Success",
            description: "Your Activity Has Been Deleted",
          });
        }, 1000);

        window.location.reload(false);
      }
    });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        color: "white",
      }}
    >
      <p
        style={{
          justifySelf: "flex-start",
          display: "flex",
          marginBottom: "2px",
        }}
      >
        {moment(props.date).format("DD MMMM, YYYY")}
      </p>
      <div className="Capsule-card-div">
        <div
          style={{
            width: "5px",
            background:
              props.activity_name === "Office"
                ? "#ff5855"
                : props.activity_name === "Work"
                ? "#7773eb"
                : props.activity_name === "Food"
                ? "#0fa72b"
                : props.activity_name === "Gym"
                ? "#bc68d6"
                : "#fff",
            height: "70%",
            display: "flex",
            borderRadius: "0px 10px 10px 0px",
            alignSelf: "center",
          }}
        ></div>
        <div
          style={{
            textDecoration: "none",
            color: "white",
            display: "flex",
            flexDirection: "column",
            width: "87%",
            //   border: "1px solid green",
            padding: "10px",
            paddingLeft: "30px",
            textAlign: "left",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <div>
            <p
              style={{
                color: "#868686",
                marginBottom: "2px",
                marginTop: "10px",
              }}
              className="capsule-text"
            >
              {`${props.start_time} - ${props.end_time}`}
            </p>
            <p className="capsule-text">{props.name}</p>
          </div>
        </div>

        {/* <EditFilled
          style={{
            fontSize: "1.7em",
            color: "#868686",
            display: "flex",
            margin: "10px",
            justifySelf: "center",
            alignItems: "center",
          }}
        /> */}
        {/* <EditFilled
          style={{
            fontSize: "1.7em",
            color: "white",
            display: "flex",
            margin: "10px",
            justifySelf: "center",
            alignItems: "center",
          }}
          onClick={EditActivity}
        /> */}
        <div style={{ display: "flex", marginLeft: "-55px" }}>
          <DeleteOutlined
            style={{
              fontSize: "1.7em",
              color: "#ff1e1e",
              display: "flex",
              margin: "10px",
              justifySelf: "center",
              alignItems: "center",
            }}
            onClick={() => setShowpopup(true)}
          />
          <EditActivityModal
            date={props.date}
            activity_name={props.activity_name}
            description={props.name}
            start_time={props.start_time}
            end_time={props.end_time}
            id={props.id}
          />
          <Modal
            visible={showpopup}
            onCancel={handleCancel}
            title={"Confirmation"}
            className="custom-modal-styles"
            bodyStyle={{
              height: "auto",
            }}
            footer={false}
          >
            <div>
              <p style={{ color: "white" }}>
                Are you sure you want to delete this activity?
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  onClick={deleteActivity}
                  style={{
                    background: "#ff1e1e",
                    color: "white",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    cursor: "pointer",
                  }}
                >
                  Yes
                </button>
                <button
                  onClick={() => {
                    setShowpopup(false);
                  }}
                  style={{
                    background: "grey",
                    color: "white",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    cursor: "pointer",
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Sentry.withProfiler(CapsuleActivity);
