import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  notification,
  DatePicker,
  TimePicker,
  Dropdown,
  Input,
  Menu,
} from "antd";
import { setActivity } from "../api/requests";
import moment from "moment";
import "../styles/trainingplans.css";

import {
  CalendarOutlined,
  ClockCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { now } from "moment";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ActivityModal(props) {
  Sentry.configureScope((scope) =>
    scope.setTransactionName("AddActivity Modal")
  );
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [start_time, setStartTime] = useState("");
  const [end_time, setEndTime] = useState("");
  const [date2, setDate] = useState(moment());
  const [activity_description, setActivity_description] = useState();
  const [activity_type, setActivity_type] = useState("Gym");
  const navigate = useNavigate();
  const time_format = "HH:mm";
  function handlelMenuClick(e) {
    console.log("Selected menu is ", e.key);
    setActivity_type(e.key);
  }
  const menu = (
    <Menu onClick={handlelMenuClick}>
      <Menu.Item key="Office">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              height: 8,
              width: 8,
              borderRadius: 50,
              backgroundColor: "#ff5855",
              display: "flex",
              marginRight: "10px",
            }}
          ></div>
          Office
        </div>{" "}
      </Menu.Item>
      <Menu.Item key="Work">
        {" "}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              height: 8,
              width: 8,
              borderRadius: 50,
              backgroundColor: "#7773eb",
              display: "flex",
              marginRight: "10px",
            }}
          ></div>
          Work
        </div>{" "}
      </Menu.Item>
      <Menu.Item key="Food">
        {" "}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              height: 8,
              width: 8,
              borderRadius: 50,
              backgroundColor: "#0fa72b",
              display: "flex",
              marginRight: "10px",
            }}
          ></div>
          Food
        </div>{" "}
      </Menu.Item>
      <Menu.Item key="Gym">
        {" "}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              height: 8,
              width: 8,
              borderRadius: 50,
              backgroundColor: "#bc68d6",
              display: "flex",
              marginRight: "10px",
            }}
          ></div>
          Gym
        </div>
      </Menu.Item>
    </Menu>
  );
  function onChangeDate(date, dateString) {
    console.log("date", date);
    setDate(date);
  }
  function onChangeDescription(e) {
    console.log("Description ", e.target.value);
    setActivity_description(e.target.value);
  }

  function onChangeStartTime(time, timeString) {
    console.log("time", time.format("HH:mm"));
    setStartTime(time.format("HH:mm"));
  }
  function onChangeEndTime(time, timeString) {
    // console.log("time", time.format("HH:mm"));
    setEndTime(time.format("HH:mm"));
  }
  const showModal = () => {
    setVisible(true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props]);
  const handleOk = () => {
    // console.log("end time is :::", end_time);
    // console.log("start time is :::", start_time);
    // if (end_time < start_time) {
    //   // x = true;
    //   console.log("its working yayyy");
    // } else {
    //   console.log("why is not working");
    // }
    // return;
    const value = localStorage.getItem("jwt");
    const initialValue = JSON.parse(value);
    const parameters = {
      id: "",
      activity_name: activity_type,
      description: activity_description,
      date: date2.format("YYYY-MM-DD"),
      start_time: start_time,
      end_time: end_time,
      color_id: "2",
    };
    if (
      start_time !== "" &&
      date2 !== "" &&
      end_time !== "" &&
      activity_type !== ""
    ) {
      if (date2.format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD")) {
        if (
          date2.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") &&
          end_time <= start_time
        ) {
          notification.error({
            message: "Error",
            description:
              "Sorry Your Activity Couldn't Be Added, Start time is less than current time",
            onClick: () => {
              console.log("Notification Clicked!");
            },
          });
        } else {
          if (end_time <= start_time) {
            notification.error({
              message: "Error",
              description:
                "Sorry Your Activity Couldn't Be Added, Start time cannot be less than Current time",
              onClick: () => {
                console.log("Notification Clicked!");
              },
            });
          } else {
            setActivity(parameters, initialValue).then((result) => {
              if (result.message === "user does not exists") {
                localStorage.clear();
                navigate("/login");
              }
              if (result.status == 200) {
                setTimeout(() => {
                  notification.success({
                    message: "Success",
                    description: "Your Activity Has Been Added",
                  });
                }, 1000);
                window.location.reload(false);
              } else {
                notification.error({
                  message: "Error",
                  description:
                    "Sorry Your Activity Couldn't Be Added, Are You Subscribed?",
                  onClick: () => {
                    console.log("Notification Clicked!");
                  },
                });
              }
            });
          }
        }
      } else {
        notification.error({
          message: "Error",
          description: "Past time and Dates cannot be selected",
          onClick: () => {
            console.log("Notification Clicked!");
          },
        });
      }
    } else {
      notification.error({
        message: "Error",
        description:
          "Sorry Activity Couldn't Be Added, Some Feilds May Be Empty",
        onClick: () => {
          console.log("Notification Clicked!");
        },
      });
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };
  const disabledDate = (current) => {
    let customDate = moment().add(0, "days").format("YYYY-MM-DD");
    return current <= moment(customDate, "YYYY-MM-DD");
  };

  return (
    <div>
      <div
        style={{
          borderRadius: "10px",
          padding: "10px 12px",
          background: "#2B2B2B",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          cursor: "pointer",
          flexDirection: "row",
        }}
        onClick={showModal}
        className="add-activity-modal"
      >
        <p style={{ margin: "0", paddingRight: "10px", fontSize: "1em" }}>
          {" "}
          {t("add_activity")}{" "}
        </p>
        <PlusOutlined style={{ fontSize: "1em" }} />
      </div>
      <div>
        <Modal
          visible={visible}
          title={t("schedule_activity")}
          onOk={handleOk}
          confirmLoading={confirmLoading}
          onCancel={handleCancel}
          className="custom-modal-styles"
          bodyStyle={{
            height: "auto",
            paddingTop: "70px",
            paddingBottom: "70px",
          }}
          footer={false}
        >
          <div>
            {/* <h1 className="modal-heading-custom">Schedule Training Plan</h1> */}
            <p
              style={{
                color: "grey",
                marginTop: "-30px",
                marginBottom: "15px",
              }}
            >
              {t("select_date")}
            </p>
            <DatePicker
              onChange={onChangeDate}
              className="datepicker-div"
              disabledDate={(current) => disabledDate(current)}
              style={{ color: "red", marginBottom: "20px", marginTop: "-5px" }}
              placeholder={moment().format("YYYY-MM-DD")}
              allowClear={false}
              suffixIcon={
                <CalendarOutlined
                  style={{
                    color: "white",
                    fontSize: "20px",
                    display: "flex",
                    justifySelf: "center",
                    alignSelf: "center",
                  }}
                />
              }
            />
            <p
              style={{ color: "grey", marginBottom: "15px", marginTop: "-5px" }}
            >
              {t("activity_name")}
            </p>
            <div
              style={{
                background: "transparent",
                borderRadius: "10px",
                height: "50px",
                margin: "0px 8px 15px 0px",
                color: "white",
              }}
              className={"setActivity-style"}
            >
              <Dropdown overlay={menu}>
                <a onClick={(e) => e.preventDefault()}>{activity_type}</a>
              </Dropdown>
            </div>
            <p
              style={{ color: "grey", marginBottom: "15px", marginTop: "-5px" }}
            >
              {t("description")}
            </p>
            <Input
              placeholder="Description"
              style={{
                background: "transparent",
                borderRadius: "10px",
                height: "50px",
                margin: "0px 8px 15px 0px",
                color: "white",
              }}
              onPressEnter={onChangeDescription}
              onChange={onChangeDescription}
            />

            <p
              style={{ color: "grey", marginBottom: "15px", marginTop: "-5px" }}
            >
              {t("start_time")}
            </p>
            <TimePicker
              allowClear={false}
              onChange={onChangeStartTime}
              defaultValue={moment("00", "HH")}
              style={{ color: "red", marginBottom: "20px", marginTop: "-5px" }}
              format={time_format}
              placeholder={moment().format("hh:mm")}
              suffixIcon={
                <ClockCircleOutlined
                  style={{
                    color: "white",
                    fontSize: "20px",
                    display: "flex",
                    justifySelf: "center",
                    alignSelf: "center",
                  }}
                />
              }
              // use12Hours
            />
            <p
              style={{ color: "grey", marginBottom: "15px", marginTop: "-5px" }}
            >
              {t("end_time")}
            </p>
            <TimePicker
              allowClear={false}
              onChange={onChangeEndTime}
              defaultValue={moment("00", "HH")}
              style={{ color: "red", marginBottom: "20px", marginTop: "-5px" }}
              format={time_format}
              placeholder={moment().format("HH:mm")}
              suffixIcon={
                <ClockCircleOutlined
                  style={{
                    color: "white",
                    fontSize: "20px",
                    display: "flex",
                    justifySelf: "center",
                    alignSelf: "center",
                  }}
                />
              }
            />

            {/* </div> */}
            {/* </div> */}
            <div className="btns-div-confirm-cancle">
              <Button onClick={handleOk} className="modal-confirm-button">
                {t("confirm")}
              </Button>
              {/* <Button onClick={handleCancel} className="modal-cancel-button">
              Cancel
            </Button> */}
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default Sentry.withProfiler(ActivityModal);
