import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import "../styles/homescreen.css";
import scale from "../assets/images/scale.png";
import boot from "../assets/images/boot.png";
import arm from "../assets/images/arm.png";
import { setGoalFlag, setgoal, getGoalFlag } from "../api/requests";
import { Link, useNavigate, Navigate, useLocation } from "react-router-dom";

function Goal() {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [goalValue, setGoalValue] = useState(1);
  const [active_loseWeight, set_active_loseWeight] = useState(false);
  const [active_improvefitness, set_active_improvefitness] = useState(false);
  const [active_muscles, set_active_muscles] = useState(false);

  const [confirmLoading, setConfirmLoading] = useState(false);

  const location = useLocation();

  useEffect(async () => {
    const value = localStorage.getItem("jwt");
    const initialValue = JSON.parse(value);
    const goal_flag = "1";
    setGoalFlag(initialValue, goal_flag).then((resp) => {
      console.log("res goal", resp);
    });
    getGoalFlag(initialValue).then((res) => {
      console.log("responserrerasd : ", res);
      //   setGoalID(res.data.goal_id);
      if (res.data.goal_id === 1) {
        set_active_loseWeight(true);
      } else if (res.data.goal_id === 2) {
        set_active_improvefitness(true);
      } else if (res.data.goal_id === 3) {
        set_active_muscles(true);
      } else {
        set_active_loseWeight(true);
      }
    });
  }, []);
  const handleClick = (val) => {
    console.log("val", val);
    setGoalValue(val);
  };

  function NextFunc() {
    const value = localStorage.getItem("jwt");
    const initialValue = JSON.parse(value);
    const data = {
      goal_id: goalValue,
      flag: "1",
    };
    setgoal(data, initialValue).then((res) => {
      console.log("res is aqe", res);
      if (res.status == 200) {
        setRedirect(true);
      }
    });
  }
  if (redirect) {
    if (location.state !== null) {
      if (location.state.route === "plan") {
        return <Navigate to="/plans" />;
      } else if (location.state.route === "training") {
        return <Navigate to={location.state.path} />;
      } else if (location.state.route === "workout") {
        return <Navigate to="/workoutvideos" />;
      } else if (location.state.route === "planner") {
        return <Navigate to="/mytrainingplan" />;
      } else if (location.state.route === "settings") {
        return <Navigate to="/settings/about" />;
      } else {
        return <Navigate to="/homepage" />;
      }
    } else {
      return <Navigate to="/homepage" />;
    }
  }

  const redirectionChecks = () => {
    let route = "";
    if (location.state !== null) {
      if (location.state.route === "plan") {
        route = "/plans";
      } else if (location.state.route === "training") {
        route = location.state.path;
      } else if (location.state.route === "workout") {
        route = "/workoutvideos";
      } else if (location.state.route === "planner") {
        route = "/mytrainingplan";
      } else if (location.state.route === "settings") {
        route = "/settings/about";
      } else {
        route = "/homepage";
      }
    } else {
      route = "/homepage";
    }
    return route;
  };

  return (
    <div
      className="container-div"
      style={{
        height: "100vh",
        background: "black",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // paddingTop: "50px",
      }}
    >
      <Modal
        visible={visible}
        closable={false}
        title={"Your Goal"}
        confirmLoading={confirmLoading}
        className="custom-modal-styles"
        bodyStyle={{
          height: "auto",
        }}
        footer={false}
      >
        <div className="all-goals-div">
          <div
            className={active_loseWeight ? "goal-card-active" : "goal-card"}
            onClick={() => {
              handleClick(1);
              set_active_loseWeight(!active_loseWeight);
              set_active_muscles(false);
              set_active_improvefitness(false);
            }}
          >
            <img src={scale} />
            <p
              style={{
                marginBottom: "0px",
                marginTop: "10px",
                fontSize: "20px",
                textAlign: "center",
              }}
            >
              Lose Weight
            </p>
          </div>
          <div
            className={active_improvefitness ? "goal-card-active" : "goal-card"}
            onClick={() => {
              handleClick(2);
              set_active_improvefitness(!active_improvefitness);
              set_active_loseWeight(false);
              set_active_muscles(false);
            }}
          >
            <img src={boot} />
            <p
              style={{
                marginBottom: "0px",
                marginTop: "10px",
                fontSize: "20px",
                textAlign: "center",
              }}
            >
              Improve Fitness
            </p>
          </div>
          <div
            className={active_muscles ? "goal-card-active" : "goal-card"}
            onClick={() => {
              handleClick(3);
              set_active_loseWeight(false);
              set_active_improvefitness(false);
              set_active_muscles(!active_muscles);
            }}
          >
            <img src={arm} />
            <p
              style={{
                marginBottom: "0px",
                marginTop: "10px",
                fontSize: "20px",
                textAlign: "center",
              }}
            >
              Build Muscles
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Link to={redirectionChecks()}>
            <Button
              style={{
                color: "#868686",
                fontSize: "20px",
                marginTop: "10px",
                background: "transparent",
                outline: "none",
                border: "none",
              }}
            >
              Skip For Now
            </Button>
          </Link>
          <Button className="Next-Button" onClick={NextFunc}>
            Next
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default Goal;
