import React, { useState, useEffect } from "react";
import SiteHeader from "../components/header";
import MiniHeader from "../components/miniHeader";
import Footer from "../components/footer";
import address from "../assets/images/address.png";
import email from "../assets/images/email.png";
import phone from "../assets/images/phone.png";

const Contact = () => {
  const [loc, setpkloc] = useState(true);
  const pk =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3320.9466306762374!2d73.04906432688675!3d33.658544719031326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfbfa7e8267515%3A0xcae3da59f7b4764c!2sSwitch%20Solutions%20(Pvt)%20Limited%20Pakistan!5e0!3m2!1sen!2s!4v1653649623316!5m2!1sen!2s";
  return (
    <div className="container-div">
      <div style={{ marginBottom: "50px" }}>
        <SiteHeader />
      </div>
      <MiniHeader name={"Contact Us"} />
      <div
        style={{
          justifyContent: "space-between",
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "row",
          padding: 10,
          color: "#000",
          marginTop: "50px",
          marginBottom: "50px",
          width: "80%",
          justifySelf: "center",
          alignSelf: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
          className="contactus-left-div"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <img src={email} width={"38px"} height={"38px"} />
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  marginTop: "5px",
                }}
              >
                Email
              </p>
            </div>
            <p
              style={{
                marginLeft: " 2px",
                fontSize: "18px",
                marginTop: "-15px",
              }}
            >
              customer.support@fitflexapp.com
            </p>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <img src={phone} width={"38px"} height={"38px"} />
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  marginTop: "5px",
                }}
              >
                Phone
              </p>
            </div>
            <p
              style={{
                marginLeft: " 2px",
                fontSize: "18px",
                marginTop: "-15px",
              }}
            >
              +92 51 2722008
            </p>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <img src={address} width={"38px"} height={"38px"} />
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  marginTop: "5px",
                }}
              >
                Addresses
              </p>
            </div>
            <strong> Pakistan:</strong>
            <p style={{ marginLeft: " 2px", fontSize: "18px" }}>
              Plot 250 Street 6, I-9/2, Islamabad, 44000, Pakistan
            </p>
            <strong> United States:</strong>
            <p style={{ marginLeft: " 2px", fontSize: "18px" }}>
              2010 NE 182 Pl Unit #138 Citra FL 32113 United States
            </p>
          </div>
        </div>
        <div style={{ margin: "2px" }}>
          <iframe
            src={pk}
            width="600"
            height="400"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>{" "}
          <div style={{ display: "flex", flexDirection: "row" }}>
            {/* <button
              onClick={() => {
                setpkloc(!loc);
              }}
              style={{ background: "#ff1e1e", color: "white", border: "none" }}
            >
              {" "}
              {loc ? "Change to Pk" : "Change to USA"}
            </button> */}
          </div>
        </div>
      </div>
      {console.log(loc)}
      <Footer />
    </div>
  );
};

export default Contact;
