import React, { useState } from "react";
import "../styles/homescreen.css";
import { useTranslation } from "react-i18next";

const WorkoutSection = () => {
  const [enter, setEnter] = useState("");
  const { t } = useTranslation();

  return (
    <div className="workout-section">
      <p className="workout-heading1">{t("workout_title")}</p>
      <p className="workout-heading2">{t("workout_sc_title")}</p>
      <p className="workout-heading3">{t("workout_desc")}</p>
      <div
        className="wrapper"
        style={{
          backgroundImage: `url(
                ${
                  enter === 2
                    ? "https://fitflexapp.com/web/web_p/improveFitness.webp"
                    : // "https://fitflexapp.com/web/web_p/loseWeight.webp"
                    // "https://fitflexapp.com/web/web_p/improveFitness.webp"
                    // improve_fitness
                    enter === 3
                    ? "https://fitflexapp.com/web/web_p/buildMuscles.webp"
                    : // : lose_weight
                      "https://fitflexapp.com/web/web_p/loseWeight.webp"
                  // weightLoss
                }
              )`,
          backgroundSize: "cover",
        }}
      >
        <div
          className="line"
          onMouseEnter={() => setEnter(1)}
          onMouseLeave={() => setEnter("")}
          style={{
            transition: "1s, transform 1s",
          }}
        >
          <p
            className="myText"
            style={{
              transform: enter === 1 ? "translateY(-30%)" : "translateY(0px)",
              top: enter === 1 && 95,
              transition: "350ms, transform 350ms",
            }}
          >
            {t("workout_card_title_1")}
            {/* Build Muscles */}
          </p>
          <img
            src={require("../assets/images/arrow-right.png").default}
            className={"arrow"}
            style={{
              transform: enter === 1 ? "translateX(40%)" : "translateX(0px)",
              right: enter === 1 && 20,
              transition: "350ms, transform 350ms",
            }}
          />
          <p
            className="myDescription"
            style={{
              display: enter === 1 ? "flex" : "none",
              // transform: enter === 1 ? "translateY(-210px)" : "translateY(0px)",
              // transition: "2s, transform 2s",
              marginRight: 10,
            }}
          >
            {t("workout_card_desc_1")}
          </p>
        </div>

        <div
          className="line"
          onMouseEnter={() => setEnter(2)}
          onMouseLeave={() => setEnter("")}
          style={{
            transition: "1s, transform 1s",
          }}
        >
          <p
            className="myText"
            style={{
              transform: enter === 2 ? "translateY(-30%)" : "translateY(0px)",
              top: enter === 2 && 95,
              transition: "350ms, transform 350ms",
            }}
          >
            {/* Lose Weight */}
            {/* Build Muscles */}
            {t("workout_card_title_2")}
          </p>
          <img
            src={require("../assets/images/arrow-right.png").default}
            className={"arrow"}
            style={{
              transform: enter === 2 ? "translateX(40%)" : "translateX(0px)",
              right: enter === 2 && 20,
              transition: "350ms, transform 350ms",
            }}
          />
          <p
            className="myDescription"
            style={{
              display: enter === 2 ? "flex" : "none",
              // transform: enter === 2 ? "translateY(-250px)" : "translateY(0px)",
              // transition: "2s, transform 2s",
              marginRight: 10,
            }}
          >
            {t("workout_card_desc_2")}
            {/* Train your total body with combination of core, cardio, booty and
            strength workouts to focus your body parts, to keep your heart rate
            up and calories burning. */}
          </p>
        </div>
        <div
          className="line"
          onMouseEnter={() => setEnter(3)}
          onMouseLeave={() => setEnter("")}
          style={{
            transition: "350ms, transform 350ms",
          }}
        >
          <p
            className="myText"
            style={{
              transform: enter === 3 ? "translateY(-30%)" : "translateY(0px)",
              top: enter === 3 && 95,
              transition: "350ms, transform 350ms",
            }}
          >
            {t("workout_card_title_3")}
          </p>
          <img
            src={require("../assets/images/arrow-right.png").default}
            className={"arrow"}
            style={{
              transform: enter === 3 ? "translateX(40%)" : "translateX(0px)",
              right: enter === 3 && 20,
              transition: "350ms, transform 350ms",
            }}
          />
          <p
            className="myDescription"
            style={{
              display: enter === 3 ? "flex" : "none",
              // transform: enter === 3 ? "translateY(-280px)" : "translateY(0px)",
              // transition: "2s, transform 2s",
              marginRight: 10,
            }}
          >
            {t("workout_card_desc_3")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default WorkoutSection;
