import React, { useState, useEffect } from "react";
import SiteHeader from "../components/header";
import WorkoutVideoCard from "../components/workoutVideoCard";
import Tags from "../components/tag";
import "../styles/workout.css";
import { Layout, Button, notification, Modal } from "antd";

import {
  HeartFilled,
  PlayCircleOutlined,
  ShareAltOutlined,
  CopyOutlined,
} from "@ant-design/icons";

import clock from "../assets/images/Clock.png";
import eye from "../assets/images/Eye.png";

import {
  getTrendingVideo,
  getPlayable,
  addToFavourites,
  removeFromFavourites,
  setEffort,
} from "../api/requests";
// import WhiteGetWorkout from "../components/whiteGetworkout";
import FooterDark from "../components/darkFooter";
import { useNavigate, useParams } from "react-router-dom";
import ReactPlayer from "react-player/lazy";
// import { LoadingOutlined } from "@ant-design/icons";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
} from "react-share";
import * as Sentry from "@sentry/react";
import {
  SkeletonParagraph,
  SkeletonScreen,
  WorkoutVideosSkeleton,
} from "../components/Skeleton/Skeleton";
import { useTranslation } from "react-i18next";

// const { Content } = Layout;

const WorkoutDetails = (props) => {
  Sentry.configureScope((scope) => scope.setTransactionName("Workout Details"));
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [trending, setTrending] = useState([]);
  const [playVid, setPlaying] = useState(false);
  const [isFav, setIsFav] = useState(null);
  const [vidData, setVidData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [played_time, setPlayed_time] = useState("");
  const [MET_value, setMET_value] = useState("");
  const [captions_arr, setCaptions] = useState([]);
  const { video_id_url } = useParams();
  const { t, i18n } = useTranslation();

  const urlVideo = `https://link.fitflexapp.com/?ibi=fitflex.workout.fitness.weight.gym.fat.training&isi=1613514782&apn=fitflex.workout.fitness.weight.gym.fat.training&link=https://link.fitflexapp.com/videoID?${video_id_url}`;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      // console.log("use effect is run number ::::", x + 1);
      const value = localStorage.getItem("jwt");
      // const subStatus = localStorage.getItem("subscribed");
      // console.log("SUB STATUES", subStatus);
      const initialValue = JSON.parse(value);

      window.scrollTo(0, 0);
      const video_id = video_id_url;
      let params = { language_id: i18n.language === "en" ? 7 : 8 };

      await getTrendingVideo(initialValue, params).then((result) => {
        if (result.status === 200) {
          setTrending(result.data);
        } else {
          if (result.message === "user does not exists") {
            localStorage.clear();
            navigate("/login");
          }
        }
      });

      await getPlayable(params, video_id, initialValue).then((res) => {
        console.log(res.status);
        if (res.message === "user does not exists") {
          localStorage.clear();
          navigate("/login");
        } else {
          if (res.status === 200) {
            console.log("response : ", res);
            setIsFav(res.data[0].content.is_favourite);
            setVidData(res.data[0]);
            setLoading(false);
            setCaptions(res.data[0].captions);
          }
        }
        // localStorage.setItem("MET", vidData.content.MET);
        setMET_value(res.data[0].content.MET);
        console.log("get Playable res : a: ", vidData);
      });
    };

    fetchData();
  }, [video_id_url, i18n.language]);

  useEffect(() => {
    const sendViewTime = async () => {
      console.log("the document is hidden");
      const value = localStorage.getItem("jwt");
      const initialValue = JSON.parse(value);
      console.log("the send View Time is :::", played_time);
      let params = {
        video_id: video_id_url,
        viewTime: played_time,
        MET: MET_value,
        weight: "",
      };
      console.log("params are :::", params);
      await setEffort(params, initialValue).then((res) => {
        console.log("set effort api response is ::: ", res);
      });
    };

    window.addEventListener("visibilitychange", sendViewTime);
    return () => {
      window.removeEventListener("visibilitychange", sendViewTime);
    };
  }, [played_time, MET_value, video_id_url]);

  var arr2 = captions_arr.map((v) => ({
    kind: v.kind,
    src: v.src,
    srcLang: v.file_lang_code,
  }));
  // const antIcon = (
  //   <LoadingOutlined
  //     style={{ fontSize: 45, color: "#FF1E1E", marginBottom: 20 }}
  //     spin
  //   />
  // );
  // if (loading) {
  //   return (
  //     <div class="loader">
  //       <Spin className="spinner" indicator={antIcon} tip="Loading" />
  //     </div>
  //   );
  // }
  const handleCancel = () => {
    setVisible(false);
  };
  const addtofavfunc = (video_id) => {
    const value = localStorage.getItem("jwt");
    const initialValue = JSON.parse(value);
    addToFavourites(video_id, initialValue).then((res) => {
      if (res.status === 200) {
        console.log("res is :::", res);
        setIsFav(true);
        notification.success({
          message: "Success",
          description: "Video Added To Favourites",
          onClick: () => {
            console.log("Notification Clicked!");
          },
        });

        console.log("Respo", res);
      }
    });
  };
  const removefavfunc = (video_id) => {
    console.log(" Remove IS RUN");
    const value = localStorage.getItem("jwt");
    const initialValue = JSON.parse(value);
    removeFromFavourites(video_id, initialValue).then((res) => {
      if (res.status === 200) {
        // console.log("RespoNSEE", res);
        setIsFav(null);
        notification.success({
          message: "Success",
          description: "Video Removed From Favourites",
          onClick: () => {
            console.log("Notification Clicked!");
          },
        });
      }
    });
  };
  const Cap = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <div className="container-div">
      <div style={{ marginBottom: "50px" }}>
        <SiteHeader />
      </div>
      <div
        style={{
          marginTop: "10px",
          background: "black",
          color: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* {console.log("VIDEO URL : is THIS :: ", vidData.media[0])} */}
        {!loading ? (
          <div className="workout-img-and-details-div">
            {vidData.content.subscribed === "Yes" &&
            vidData.content.daily_limit_reached === "No" ? (
              <div className="workout-details-image-div">
                <ReactPlayer
                  style={{ borderRadius: "20px" }}
                  className="react-workout-player"
                  config={{
                    file: {
                      attributes: {
                        crossOrigin: "true",
                      },
                      tracks: arr2,
                    },
                  }}
                  url={vidData.media[0]}
                  controls={true}
                  width={"100%"}
                  // height={"auto"}
                  playing={!playVid}
                  muted={true}
                  onStart={() => {
                    setPlaying(true);
                    const value = localStorage.getItem("jwt");
                    const initialValue = JSON.parse(value);
                    const params = {
                      video_id: video_id_url,
                      viewTime: played_time,
                      MET: MET_value,
                      weight: "",
                    };
                    console.log("params are :::", JSON.stringify(params));
                    setEffort(params, initialValue).then((res) => {
                      console.log("set effort api response is ::: ", res);
                    });
                  }}
                  onProgress={(time) => {
                    console.log("the time is :::", time);
                    const time_1 = Math.round(time.playedSeconds);
                    const time_2 = JSON.stringify(time_1);
                    setPlayed_time(time_2);
                    console.log("the played time is :::", time_2);
                  }}
                  playIcon={
                    <PlayCircleOutlined
                      style={{
                        color: "#ff1e1e",
                        fontSize: "4em",
                        color: "#ff1e1e",
                      }}
                      onClick={() => {
                        setPlaying(true);
                      }}
                    />
                  }
                  light={playVid ? false : vidData.thumbs.original}
                />
              </div>
            ) : (
              <img
                src={vidData.thumbs.original}
                // style={{ width: "60%" }}
                className="workout-image-section"
              />
            )}
            <div className="workoutDetails-details-div">
              {/* <p className="workout-catagory-text">{vidData.content.category}</p> */}
              <div className="workout-details-title-div">
                <p className="workout-details-title-text">
                  {vidData.content.title}
                </p>
              </div>
              {/* {console.log(
              "SUBSCRIBED GET PLAYABLE : ",
              vidData.content.subscribed
            )} */}
              <div className="duration-and-views-div">
                <img src={clock} className="clock-n-eye" />
                <p className="duration-and-views-text">
                  {vidData.content.duration}
                </p>
                <img
                  src={eye}
                  className="clock-n-eye"
                  style={{ marginLeft: "10px" }}
                />
                <p className="duration-and-views-text">
                  {vidData.content.views}
                </p>
              </div>
              <div className="workout-tags-div">
                {vidData.content.tags.map((item, key) => (
                  <Tags text={Cap(item)} />
                ))}
              </div>

              <div className="share-and-fav-div">
                {vidData.content.subscribed === "Yes" &&
                vidData.content.daily_limit_reached === "No" ? (
                  <Button
                    className="start-workout-btn"
                    onClick={() => setPlaying(!playVid)}
                  >
                    {playVid ? "Stop Workout" : `${t("start_workout")}`}
                  </Button>
                ) : (
                  <Button
                    className="start-workout-btn"
                    onClick={() => navigate("/plans")}
                  >
                    {t("start_workout")}
                  </Button>
                )}
                <div className="circular-btns-div">
                  <div
                    className={
                      isFav !== null ? "circular-div-colored" : "circular-div"
                    }
                    onClick={() => {
                      const video_id = {
                        videoid: video_id_url,
                      };
                      if (isFav !== null) {
                        removefavfunc(video_id);
                      } else {
                        addtofavfunc(video_id);
                      }
                    }}
                  >
                    <HeartFilled
                      style={{ fontSize: "20px", marginTop: "17px" }}
                    />
                  </div>
                  <div
                    className="circular-div"
                    onClick={() => {
                      setVisible(true);
                    }}
                  >
                    <ShareAltOutlined
                      style={{
                        fontSize: "20px",
                        marginTop: "17px",
                        marginLeft: "-3px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <SkeletonScreen />
        )}
        {!loading ? (
          <div className="workout-details-paragraph">
            <p>{vidData.content.description}</p>
          </div>
        ) : (
          <div style={{ width: "85%" }}>
            <SkeletonParagraph />
          </div>
        )}
        <div className="trending-videos-div">
          <lable className="trending-lable">Trending Videos</lable>
          {!loading ? (
            <div className="trending-videos-grid-x">
              {trending.length > 0 &&
                trending
                  .map((item, index) => {
                    return (
                      <WorkoutVideoCard
                        thumbnail={item.thumbs.original}
                        catagoryType={item.category_name}
                        duration={item.duration}
                        views={item.views}
                        title={item.title}
                        categories_videos={item.videos}
                        tags={item.tags}
                        description={item.description}
                        video_id={item.videoid}
                      />
                    );
                  })
                  .slice(0, 4)}
            </div>
          ) : (
            <WorkoutVideosSkeleton />
          )}
          {/* {console.log("url is : ", window.location.href)} */}
        </div>
        {/* <WhiteGetWorkout /> */}
      </div>
      <Modal
        visible={visible}
        title={"Share Post"}
        onCancel={handleCancel}
        className="custom-modal-styles"
        bodyStyle={{
          height: "auto",
          paddingTop: "70px",
          paddingBottom: "70px",
        }}
        footer={false}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            flexWrap: "wrap",
            // width: "80%",
          }}
        >
          <div
            className="circular-div"
            style={{ height: "60px" }}
            onClick={() => {
              notification.success({
                message: "Success",
                description: "Link Copied",
                // placement: "center",
                onClick: () => {
                  console.log("Notification Clicked!");
                },
              });
              const el = document.createElement("textarea");
              el.value = urlVideo;
              document.body.appendChild(el);
              el.select();
              document.execCommand("copy");
              document.body.removeChild(el);
              setVisible(false);
            }}
          >
            <CopyOutlined
              style={{
                color: "white",
                fontSize: "24px",
                marginTop: "17px",
                marginLeft: "-2px",
              }}
            />
          </div>
          <FacebookShareButton url={urlVideo}>
            <FacebookIcon round={true} />
          </FacebookShareButton>
          <FacebookMessengerShareButton url={urlVideo}>
            <FacebookMessengerIcon round={true} />
          </FacebookMessengerShareButton>
          <WhatsappShareButton url={urlVideo}>
            <WhatsappIcon round={true} />
          </WhatsappShareButton>
          <EmailShareButton url={urlVideo}>
            <EmailIcon round={true} />
          </EmailShareButton>
          <LinkedinShareButton url={urlVideo}>
            <LinkedinIcon round={true} />
          </LinkedinShareButton>
          <TwitterShareButton url={urlVideo}>
            <TwitterIcon round={true} />
          </TwitterShareButton>
        </div>
      </Modal>
      <FooterDark />
    </div>
  );
};

export default Sentry.withProfiler(WorkoutDetails);
