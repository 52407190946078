import React, { useState } from "react";
import apple from "../assets/images/apple.png";
import android from "../assets/images/android.png";
import "../styles/myCss.css";
import { Link } from "react-router-dom";
import { isAuthenticated } from "../api/requests";
import { Input, Menu, Dropdown, Spin, Checkbox } from "antd";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
const Footer = () => {
  const { t } = useTranslation();
  const [token, setToken] = useState(isAuthenticated());
  Sentry.configureScope((scope) => scope.setTransactionName("Footer Light"));

  return (
    <div style={container}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
        }}
        className="flexDirCol"
      >
        <div
          style={{ justifyContent: "flex-start", marginRight: "5vw" }}
          className="footer-div"
        >
          <img
            src={require("../assets/images/logo_ff1e1e.png").default}
            style={{
              width: 90,
              height: 50,
              marginBottom: "40px",
              // border: "1px solid green",
            }}
          />
          <p
            style={{
              fontFamily: "Gotham",
              fontSize: "1em",
              fontWeight: "400",
              fontStyle: "italic",
              color: "#969696",
              textAlign: "center",
              marginTop: "-8%",
            }}
            className="footer-div"
          >
            © 2021 FITFLEX
          </p>
          <div
            style={{
              marginTop: "4vw",
            }}
            className="footer-div"
          >
            <a
              href="https://play.google.com/store/apps/details?id=fitflex.workout.fitness.weight.gym.fat.training"
              target="_blank"
            >
              <img
                src={android}
                style={{
                  width: 150,
                  maxWidth: "100%",
                  height: "auto",
                  marginBottom: "20px",
                }}
                alt="playstore"
                className="footer-div"
              />
            </a>
            <a
              href="https://apps.apple.com/us/app/fitflex/id1613514782"
              target="_blank"
            >
              <img
                src={apple}
                style={{
                  width: 150,
                  maxWidth: "100%",
                  height: "auto",
                  marginBottom: "20px",
                }}
                alt="appstore"
                href="https://apps.apple.com/us/app/fitflex/id1613514782"
                className="footer-div"
              />
            </a>
          </div>
        </div>
        <div className="footer-right-div">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              // marginRight: "2vw",
            }}
          >
            <a
              className="footer-title"
              // style={{
              //   fontWeight: "700",
              //   fontSize: "1em",
              //   color: "#000",
              //   marginBottom: "8vh",
              //   textAlign: "left",
              // }}
            >
              {t("products")}
            </a>
            <Link
              className="footer-small-text"
              to={token === false ? "/login" : "/workoutvideos"}
            >
              {t("video_workouts")}
            </Link>

            <Link className="footer-small-text" to="/alltrainingplans">
              {t("training_plans")}
            </Link>
            <Link className="footer-small-text" to="/exercise">
              {t("exercise")}
            </Link>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              // marginRight: "2vw",
            }}
          >
            <a className="footer-title">{t("company")}</a>
            {/* <Link to="/about" className="footer-small-text"> */}

            {/* </Link> */}

            <a className="footer-small-text" href="/terms" target="_blank">
              {t("terms_and_conditions")}
            </a>
            <a className="footer-small-text" href="/privacy" target="_blank">
              {t("privacy_policy")}
            </a>
            <a className="footer-small-text" href="/contactus" target="_blank">
              {t("contact_us")}
            </a>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              marginRight: "0vw",
            }}
          >
            <a className="footer-title">{t("socials")}</a>
            {/* <a style={{ color: "#000", marginBottom: "2vh", textAlign: "left" }}>
            Facebook
          </a> */}
            <a
              className="footer-small-text"
              href="https://instagram.com/fitflex_app?utm_medium=copy_link"
              target="_blank"
            >
              {t("instagram")}
            </a>
            <a
              className="footer-small-text"
              href="https://twitter.com/fitflex_app?s=20"
              target="_blank"
            >
              {t("twitter")}
            </a>
            <a
              className="footer-small-text"
              href="https://www.linkedin.com/company/fitflexapp"
              target="_blank"
            >
              {t("linkedin")}
            </a>
          </div>
        </div>
      </div>
      <div
        style={{
          //   width: "100%",
          display: "flex",
          color: "#969696",
          marginTop: "10vh",
          marginBottom: "10vh",
          // marginLeft: "10vw",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            paddingTop: "0vw",
          }}
        >
          {/* <p
            style={{
              justifyContent: "flex-start",
              display: "flex",
              whiteSpace: "nowrap",
              color: "#000",
              fontWeight: 600,
            }}
          >
            Contact Us
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left  ",
            }}
          >
            <p>
              Address : Plot 250 Street 6, I-9/2, Islamabad, 44000, Pakistan
            </p>
            <p> Email : customer.support@fitflexapp.com</p>
            <p> Phone : +92 51 2722008 </p>
          </div> */}

          <p
            style={{
              justifyContent: "center",
              display: "flex",
              // width: " 50vw",
              // whiteSpace: "nowrap",
              marginLeft: "10vw",
              marginRight: "9vw",
              alignSelf: "flex-end",
            }}
            className="copyright-div"
          >
            {t("copyright")}
          </p>
        </div>
      </div>
    </div>
  );
};
//  <div></div>
export default Sentry.withProfiler(Footer);
const container = {
  // height: "100vh",
  // width: "auto",
  // marginLeft: "12vw",
  paddingLeft: "5vw",
  paddingRight: "5vw",
  paddingTop: "5vw",
  paddingBottom: "0vw",
  display: "flex",
  flexDirection: "column",
  // justifyContent: "center",
  backgroundColor: "#fff",
  width: "100%",
};
