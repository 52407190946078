import React, { useState, useEffect } from "react";
import "../styles/login.css";
import "../styles/homescreen.css";
import "../styles/setplan.css";
import Header from "../components/header.js";
import Footer from "../components/footer";
import { Layout, Spin, Modal, Result, Button } from "antd";
import PlanCard from "../components/planCard";
import {
  addMobilePackage,
  isAuthenticated,
  jazzCashAccount,
  checkNetwork,
  unSubscribePackage,
  getNumber,
  getEffort,
  zongMzaSubscribe,
} from "../api/requests";
import PaymentMethod from "../components/paymentmethod";
import {
  LoadingOutlined,
  DownCircleFilled,
  UpCircleFilled,
  CloseOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import LoadingOverlay from "react-loading-overlay";
import { Link } from "react-router-dom";
import moment from "moment";

const antIcon = (
  <LoadingOutlined
    style={{ fontSize: 45, color: "#FF1E1E", marginBottom: 20 }}
    spin
  />
);

const SetPlan = () => {
  const [paymentStage, setPaymentStage] = useState("");
  const [loading, setLoading] = useState(true);
  const [payments, setPayments] = useState([]);
  const [freePackage, setFreePackage] = useState(false);
  const [reload, setReload] = useState(false);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [unsub, setUnsub] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [isPostPaid, setIsPostPaid] = useState(false);
  const [validDate, setValidDate] = useState("");
  const token = isAuthenticated();
  const country = localStorage.getItem("country");
  const [mzaLoading, setMzaLoading] = useState(false);
  const [mzaIndex, setMzaIndex] = useState();
  const [openTerms, setOpenTerms] = useState(false);
  const [termIndex, setTermIndex] = useState(false);
  const [ufoneCheck, setUfoneCheck] = useState("");

  let number = getNumber();

  console.log("NUMBER: ", number.substring(4, 6));

  console.log("Country: ", country);
  let operatorZong = JSON.parse(localStorage.getItem("operator"));
  let postpaidUfone = localStorage.getItem("postpaid");
  console.log("postpaid ufone is :::", postpaidUfone);
  console.log("zong operator is :::", operatorZong);
  const currency =
    country === "PK"
      ? " Rs "
      : country === "IND"
      ? " Rp "
      : country === "QA"
      ? " QAR "
      : country === "ZA"
      ? " ZAR "
      : country === "KSA"
      ? "SAR"
      : country === "CZ"
      ? "CZK"
      : " IQD ";

  console.log("Country: ", currency);

  const payload = {
    language_id: 7,
    country_id:
      country === "PK"
        ? 162
        : country === "IQ"
        ? 102
        : country === "QA"
        ? 174
        : country === "ZA"
        ? 197
        : country === "KSA"
        ? 187
        : country === "CZ"
        ? 57
        : 100,
    operator:
      country === "PK"
        ? operatorZong === 9
          ? "zong"
          : operatorZong === 11
          ? "ufone"
          : "jazz"
        : country === "IQ"
        ? "zain"
        : country === "QA"
        ? "vodafone"
        : country === "ZA"
        ? "vodacom"
        : country === "KSA"
        ? number.substring(4, 6) === "50" ||
          number.substring(4, 6) === "55" ||
          number.substring(4, 6) === "53"
          ? "KSA-STC"
          : number.substring(4, 6) === "54" || number.substring(4, 6) === "56"
          ? "KSA-MOBILY"
          : "KSA-ZAIN"
        : country === "CZ"
        ? "O2"
        : "telkomsel",
  };

  console.log("add mobile package payload is :::", payload);

  useEffect(() => {
    console.log("Hit");
    setLoading(true);
    window.scrollTo(0, 0);
    let netCheck = {
      msisdn: "92" + number.substring(1),
    };

    console.log("number for check network is :::", number);

    // console.log("set plan payload is :::", payload);
    // return;
    var postPaid;
    if (country !== "IND") {
      checkNetwork(token, netCheck).then((data) => {
        console.log("MY DATA::", data);
        if (data !== undefined) {
          if (data.status === 200) {
            if (data.data.operator === 11) {
              setUfoneCheck(true);
            } else {
              // setLoading(false);
            }
            if (data.data.network === "postpaid") {
              setIsPostPaid(true);
              postPaid = true;
              // setLoading(false);

              console.log("data of check network is :::", data);
            } else {
              // setLoading(false);
            }
          } else {
            // setLoading(false);
          }
        } else {
          // setLoading(false);
        }
      });
    }

    addMobilePackage(payload, token)
      .then((data) => {
        if (data.status === 200) {
          console.log("mobile package data is :::", data.data);
          let payment = data.data.slice(
            operatorZong === 11
              ? postpaidUfone === "postpaid"
                ? 1
                : 0
              : undefined,
            operatorZong === 11
              ? postpaidUfone === "postpaid"
                ? undefined
                : 1
              : undefined
          );
          setPayments(payment);
          getEffort(token).then((data) => {
            if (data.message == "Error") {
              console.log("error effort api has is :::", data);
            } else {
              console.log("My effort", data);
              setValidDate(data.data);
              setLoading(false);
            }
          });
        }
      })
      .catch((err) => console.log(err));
  }, [reload]);

  console.log("payments are :::", payments);

  const setSubscriptionPlan = (packageType, packageId, isActive) => {
    // if (country === "ZA") {
    //   window.location.replace(
    //     "https://qa.fitflexapp.com:7172/sa_callback?package=daily"
    //   );
    //   return;
    // }

    let payload = new FormData();
    payload.append("package_id", packageId);
    payload.append("gateway", "free");

    console.log("payload is :::", payload);

    if (isActive === true) {
      setUnsub(packageId);
      setOpen(true);
      return;
    }

    if (
      isPostPaid &&
      packageId !== 10 &&
      packageId !== 23 &&
      packageId !== 43
    ) {
      Modal.confirm({
        title: "Information",
        icon: <ExclamationCircleOutlined />,
        content: "This package is only for prepaid customers",
        okText: "Ok",
        centered: true,
        cancelText: () => null,
        closable: true,
      });
      return;
    }

    if (packageId === 23) {
      setFreePackage(true);
      jazzCashAccount(payload, token)
        .then((data) => {
          console.log("x data is ::::", data);
          if (data.status === 200) {
            setPaymentStage(packageType);
            setFreePackage(false);
          } else {
            console.log("error", data);
            setError(true);
          }
        })
        .catch((err) => setError(true));
    } else {
      let id = "";
      let iteration = 0;
      payments.forEach((val, index) => {
        if (val.activate === true) {
          console.log("Here", val.package_id);
          iteration = iteration + 1;
          id = val.package_id;
        }
      });
      if (iteration > 0) {
        console.log(
          "You need to unsubscribe your current package first which is: ",
          packageType
        );

        Modal.confirm({
          title: "Information",
          icon: <ExclamationCircleOutlined />,
          content:
            "In order to subscribe this package, you first need to unsubscribe your current package",
          okText: "Ok",
          centered: true,
          cancelText: () => null,
          closable: true,
        });
        return;
      } else {
        setPaymentStage(packageType);
      }

      // setPaymentStage(packageType);
    }
  };

  const zongMzaSubscription = async (index, packageId, activate) => {
    setMzaIndex(index);
    setMzaLoading(true);
    console.log("parameters are :::", { index, packageId, activate });
    let msisdn = JSON.parse(localStorage.getItem("number"));
    console.log("msisdn is :::", msisdn.substring(1));
    if (activate === false) {
      let body = {
        Msisdn: "92" + msisdn.substring(1),
        IdService: "999",
        IdChannel: "999",
        IdChannelOption: "1",
        IdBundle:
          packageId === 36
            ? "95004553"
            : packageId === 37
            ? "95004554"
            : "95004555",
        Timestamp: "rr2r",
      };

      zongMzaSubscribe(body)
        .then((response) => {
          if (response.Code === 0) {
            setMzaLoading(false);
            Modal.confirm({
              title: "Information",
              // icon: <ExclamationCircleOutlined />,
              content: "Congratulations. You have subscribed Sucessfully!",
              okText: "Ok",
              onOk: () => {
                setReload(!reload);
              },
              centered: true,
              cancelText: () => null,
              closable: true,
            });
          } else {
            setMzaLoading(false);
            Modal.confirm({
              title: "Information",
              // icon: <ExclamationCircleOutlined />,
              content: response.Description,
              okText: "Ok",
              centered: true,
              onOk: () => {
                setReload(!reload);
              },
              cancelText: () => null,
              closable: true,
            });
          }
        })
        .catch((error) => {
          console.log("error is :::", error);
        });
    } else {
      let body = {
        Msisdn: "92" + msisdn.substring(1),
        IdService: "999",
        IdChannel: "999",
        IdChannelOption: "2",
        IdBundle: "95004553",
        Timestamp: "rr2r",
      };

      zongMzaSubscribe(body).then((response) => {
        // if (response.status === 200) {
        console.log("response is :::", response);
        if (response.Code === 0) {
          setMzaLoading(false);
          Modal.confirm({
            title: "Information",
            icon: <ExclamationCircleOutlined />,
            content: "You have unsubscribed this package",
            okText: "Ok",
            onOk: () => {
              setReload(!reload);
            },

            centered: true,
            cancelText: () => null,
            closable: true,
          });
        }
        // }
      });
    }
  };

  // console.log("the postpaid are :::", payments[0].country_terms);

  const unsubscribe = () => {
    setFreePackage(true);
    setOpen(false);
    let payload = {
      package_id: unsub,
    };
    unSubscribePackage(payload, token)
      .then((data) => {
        console.log("error is :::", data);
        if (data.status === 200) {
          setFreePackage(false);
          setReload(!reload);
        } else {
          console.log("second error is :::", data);
          setOpen(false);
          setError(true);
        }
      })
      .catch((err) => setError(true));
  };

  const collapseTerms = (collapse, index) => {
    if (collapse === true && index === termIndex) {
      setOpenTerms(true);
    } else {
      setOpenTerms(false);
    }
    debugger;
  };

  if (loading) {
    return (
      <div class="loader">
        <Spin
          className="spinner"
          indicator={antIcon}
          tip="Fetching Details..."
        />
      </div>
    );
  }

  if (error) {
    return (
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={<Link to="/">Back Home</Link>}
      />
    );
  }

  return (
    <>
      <Layout className="layout ">
        <LoadingOverlay
          active={freePackage}
          spinner
          text="Loading..."
          styles={{
            content: (base) => ({
              ...base,
              color: "#ff1e1e",
              fontWeight: "500",
              textTransform: "capitalize",
            }),
            spinner: (base) => ({
              ...base,
              width: "70%",
              "& svg circle": {
                stroke: "rgba(255, 0, 0, 0.5)",
              },
            }),
          }}
        >
          <Header />

          <div className="container">
            <div className="crossLine" />
            <div
              className="child1"
              style={{
                display: "flex",
                flexDirection: "column",
                paddingLeft: 0,
              }}
            >
              {paymentStage !== "" ? (
                <PaymentMethod
                  type={payments[paymentStage].package_name + " Plan"}
                  rate={
                    payments[paymentStage].package_name +
                    currency +
                    payments[paymentStage].package_rate
                  }
                  duration={payments[paymentStage].package_description}
                  description="Now watch unlimited workout videos and exercises"
                  money={currency + payments[paymentStage].package_rate}
                  changePlan={(val) => setPaymentStage(val)}
                  packageId={payments[paymentStage].package_id}
                  reloading={() => setReload(!reload)}
                />
              ) : (
                <>
                  <p className="heading1">PRICING</p>
                  <p className="heading2">Simple, Transparent, Pricing</p>
                  <div
                    className="train-wrapper"
                    style={{
                      textTransform: "capitalize",
                    }}
                  >
                    {
                      payments.length > 0 &&
                        payments.map((item, index) => {
                          return (
                            <PlanCard
                              validDate={
                                validDate.subs_detail.package_id !== null
                                  ? moment(
                                      validDate.subs_detail.end_date
                                    ).format("MMM D, YYYY")
                                  : false
                              }
                              isPaid={item.paid}
                              isActive={item.activate}
                              type={item.package_name + " Plan"}
                              rate={
                                item.package_name +
                                " " +
                                currency +
                                " " +
                                item.package_rate
                              }
                              duration={item.package_description}
                              description="Now watch unlimited workout videos and exercises"
                              term={item.terms_and_conditions}
                              loading={mzaLoading}
                              index={index}
                              mzaIndex={mzaIndex}
                              open={openTerms}
                              termsClick={() => {
                                setTermIndex(index);
                              }}
                              collapseTerms={(collapse, id) => {
                                collapseTerms(collapse, id);
                              }}
                              onPress={() => {
                                console.log(
                                  JSON.parse(localStorage.getItem("mza"))
                                );
                                const mza = JSON.parse(
                                  localStorage.getItem("mza")
                                );
                                // return;
                                if (operatorZong === 9 && mza === true) {
                                  zongMzaSubscription(
                                    index,
                                    item.package_id,
                                    item.activate
                                  );
                                } else {
                                  setSubscriptionPlan(
                                    index,
                                    item.package_id,
                                    item.activate
                                  );
                                }
                              }}
                            />
                          );
                        })
                      // .slice(
                      //   ufoneCheck
                      //     ? isPostPaid === true
                      //       ? 1
                      //       : 0
                      //     : undefined,
                      //   ufoneCheck
                      //     ? isPostPaid === true
                      //       ? undefined
                      //       : 1
                      //     : undefined
                      // )
                    }
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="terms-div">
            <div
              className="terms-parent"
              onClick={() => setCollapse(!collapse)}
            >
              <p
                className="terms-text"
                style={{
                  color: "#ff1e1e",
                }}
              >
                Terms and conditions
              </p>
              {collapse ? (
                <UpCircleFilled className="dropdown-terms-arrow" />
              ) : (
                <DownCircleFilled className="dropdown-terms-arrow" />
              )}
            </div>
            <ul
              className="conditions-div"
              style={{
                display: collapse && "none",
                // opacity: collapse && 0,
                // transition: collapse
                //   ? "opacity 0.1s ease-out"
                //   : "opacity 1s ease-out",
                // height: collapse && 40,
              }}
            >
              {payments[0].country_terms.length !== 0 &&
                payments[0].country_terms.map((value, key) => {
                  return (
                    <li style={styles.listItem} key={key}>
                      {value}
                    </li>
                  );
                })}
            </ul>
          </div>
        </LoadingOverlay>
      </Layout>
      <Modal
        title="Confirmation"
        className="alert-modal"
        visible={open}
        closable={true}
        closeIcon={
          <CloseOutlined
            style={{
              color: "black",
            }}
          />
        }
        footer={[
          <Button key="back" onClick={() => setOpen(false)}>
            Back
          </Button>,
          <Button key="submit" type="primary" onClick={unsubscribe}>
            Yes
          </Button>,
        ]}
        onCancel={() => setOpen(false)}
      >
        Are you sure you want to cancel your subscription?
        {/* <button style={styles.btn} onClick={unsubscribe}>
          Yes
        </button> */}
      </Modal>
      <Footer />
    </>
  );
};

export default SetPlan;

const styles = {
  terms_parent: {
    display: "flex",
    justifyContent: "space-between",
    width: "90%",
    marginRight: 20,
  },
  conditionsDiv: {
    paddingLeft: "15px",
  },
  listItem: {
    textAlign: "justify",
    fontWeight: "500",
    marginRight: 10,
    marginBottom: 5,
    color: "black",
  },
  btn: {
    float: "right",
    background: "#ff1e1e",
    color: "white",
    border: 0,
    borderRadius: 10,
    padding: 10,
    marginTop: 20,
    marginBottom: 10,
  },
};
