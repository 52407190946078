import React from "react";
import "../styles/homescreen.css";
import SiteHeader from "../components/header";
import MiniHeader from "../components/miniHeader";
import Footer from "../components/footer";

const TermsOfUse = () => {
  return (
    <div className="container-div">
      <div style={{ marginBottom: "50px" }}>
        <SiteHeader />
      </div>
      <MiniHeader name={"Terms of Use"} />
      <div
        style={{
          justifyContent: "flex-start",
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
          padding: 10,
          color: "#000",
          marginTop: "50px",
          marginBottom: "50px",
          width: "90%",
          justifySelf: "center",
          alignSelf: "center",
          borderBottom: "1px solid #888888",
        }}
      >
        {/* <p style={{ textAlign: "left" }}> Terms of Use</p> */}
        <p style={{ textAlign: "left" }}>Last modified: Mar 11, 2024</p>
        <p style={{ textAlign: "left", fontWeight: 900 }}> REFUND POLICY</p>
        <p style={{ textAlign: "left" }}> We have no refund policy</p>
        <p style={{ textAlign: "left" }}>
          Fitflex recognizes that privacy is important, and we value and take
          steps to ensure the privacy of our users. This Privacy Policy applies
          to your use of the Mobile applications on all platforms and the
          related services we provide. These Terms of Use set out the basis upon
          which Fitflex Pvt. Ltd. located in Pakistan (Fitflex, we and/or
          related terms such as us and our) agree to provide Fitflex platform
          and related products and services (together, Fitflex) to a user (you
          and/or related terms such as your).
        </p>
        <h4 style={{ textAlign: "left", fontWeight: "700" }}>
          1. Terms of Use
        </h4>
        <p style={{ textAlign: "left" }}>
          1.1 If you do not agree to these Terms of Use and the rest of this
          Agreement you cannot use Fitflex. This Agreement will form a binding
          legal contract between you and us.
        </p>
        <p style={{ textAlign: "left" }}>
          1.2 We may change the terms of this Agreement from time to time. If
          you do not agree to the change, you must discontinue your use of
          Fitflex. Your continued use of Fitflex will constitute your acceptance
          of the change.
        </p>
        <p style={{ textAlign: "left" }}>
          1.3 We may (but do not promise that we will) offer additional or
          amended elements of Fitflex from time to time. Depending on the
          additional or amended element you may not be able to continue to use
          Fitflex until you have downloaded an update and/or accepted any new or
          revised terms of this Agreement.
        </p>
        <p style={{ textAlign: "left" }}>
          1.4 You must be, or must obtain the permission of, the owner of the
          device on which you download and access FITFLEX. You are responsible
          for your use of FITFLEX on or in relation to any device.
        </p>
        <p style={{ textAlign: "left" }}>
          1.5 Certain functionality we offer within FITFLEX may be subject to
          additional terms and conditions (Product Terms) which will be made
          available to you from the relevant functionality. In the event of any
          conflict or inconsistency between any such Product Terms and this
          Agreement, the relevant Product Terms shall take precedence.
        </p>
        <h4 style={{ textAlign: "left", fontWeight: "700" }}>
          2. Registration and Account
        </h4>
        <p style={{ textAlign: "left" }}>
          2.1 In order to use FITFLEX you must register an account with FITFLEX.
          For this, You must be:
        </p>
        <p style={{ textAlign: "left" }}>
          (a) at least eighteen (18) years or greater age required to register
          for and use FITFLEX or related Services. If you are not old enough to
          have legal authority to agree to these Terms of Use, your parent or
          guardian must agree to our Terms of Use on your behalf; and
        </p>
        <p style={{ textAlign: "left" }}>
          (b) an individual, not a company or commercial entity, and a
          non-business user.
        </p>
        <p style={{ textAlign: "left" }}>
          If you do not comply with these requirements you cannot register for
          or use FITFLEX. You may only register for and hold one account.
        </p>
        <p style={{ textAlign: "left" }}>
          2.2 Registering requires you to give us certain information about you,
          at the point of registration and/or later if requested
        </p>
        <p style={{ textAlign: "left" }}>
          2.3 You promise that the information which you provide to us in
          connection with your registration and account (Your Data) is truthful,
          accurate and complete. You must keep Your Data in your account
          updated.
        </p>
        <p style={{ textAlign: "left" }}>
          2.4 Your Data and other information which we receive or generate in
          connection with FITFLEX may contain information about living
          individuals (Personal Data). We will use Personal Data in accordance
          with the Privacy Policy. It is important that you read the Privacy
          Policy before you register as it governs what we may do with your
          Personal Data.
        </p>
        <p style={{ textAlign: "left" }}>
          2.5 Subject always to your compliance with the terms of this
          Agreement, FITFLEX permits you to use FITFLEX provided that:
        </p>{" "}
        <p style={{ textAlign: "left" }}>
          (a) your use of FITFLEX is solely for your own personal use and you
          therefore must not attempt to resell or charge others for use of or
          access to FITFLEX or use it for any business purposes; and
        </p>
        <p style={{ textAlign: "left" }}>
          (b) you must not, nor attempt to replicate, transfer, give access to
          or re-distribute any part of FITFLEX by any means or in any media.
        </p>{" "}
        <p style={{ textAlign: "left" }}>
          2.6 We shall close your account if it is unable to pay for a period of
          one (01) month and terminate this Agreement after intimation.
        </p>
        <p style={{ textAlign: "left" }}>
          2.7 Including when required by law or by our partners, we may require
          you to provide evidence of government-issued photo or other ID in an
          approved form and reserve the right to suspend or terminate your
          account and/or your use of FITFLEX if such ID is not provided to our
          reasonable satisfaction. You must co-operate with and pass all other
          identification, anti-money laundering, fraud-protection or security
          checks which we or our partners may operate.
        </p>{" "}
        <h4 style={{ textAlign: "left", fontWeight: "700" }}>
          3. FITFLEX Service
        </h4>
        <p style={{ textAlign: "left" }}>
          3.1 FITFLEX is provided to you on an as is and as available basis
          only. From time to time we may modify FITFLEX automatically and
          without notice.
        </p>
        <p style={{ textAlign: "left" }}>
          3.2 You must comply with all the terms of this Agreement and
          applicable laws when using FITFLEX.
        </p>{" "}
        <p style={{ textAlign: "left" }}>
          3.3 You are responsible for your use of FITFLEX including content
          (Such as: Images, Videos, Social Media content etc.) which you post,
          submit or send to FITFLEX (Your Content). Your Content must at all
          times comply with the usage policy for FITFLEX set out in Clause 5.1
          below (the Usage Policy).
        </p>{" "}
        <h4 style={{ textAlign: "left", fontWeight: "700" }}>
          4. Fitness and Wellness Activities and Dietary Guidance
        </h4>
        <h4 style={{ textAlign: "left", fontWeight: "700" }}>
          {" "}
          4.1 Prioritizing Safety
        </h4>{" "}
        <p style={{ textAlign: "left" }}>
          4.1.1 At FITFLEX, your safety is our utmost concern. Before engaging
          in any fitness, wellness, or dietary programs ("Programs") available
          through our Services, it is imperative to consult with your healthcare
          provider(s) to assess associated risks. By using our Services, you
          confirm that you have obtained your physician's consent for
          participation in the Programs and any related activities.
          Additionally, you agree that consulting your physician before making
          dietary changes based on information from our Services is essential.
          Given the diversity in individual conditions and abilities, engaging
          in Programs and associated activities is at your own risk. Even
          individuals in good health may encounter risks, and your voluntary
          participation implies assuming all associated risks.
        </p>
        <p style={{ textAlign: "left" }}>
          {" "}
          4.1.2 Except as otherwise specified in these Terms, to the maximum
          extent allowed by applicable law, FITFLEX is not liable for any
          injury, illness, or damages resulting from your use or inability to
          use any Services or features, including Content or activities accessed
          through our Services, regardless of causation by FITFLEX or others'
          actions or negligence.
        </p>
        <h4 style={{ textAlign: "left", fontWeight: "700" }}>
          {" "}
          4.2 Disclaimer Regarding Content Accuracy and Reliance
        </h4>
        <p style={{ textAlign: "left" }}>
          {" "}
          4.2.1 We make no assurances about the accuracy, reliability,
          completeness, or timeliness of any Content available through our
          Services, and we do not commit to updating such Content.
        </p>{" "}
        <p style={{ textAlign: "left" }}>
          4.2.2 Our food database ("Food Database") contains nutritional
          information provided by FITFLEX and its members. FITFLEX does not
          guarantee the accuracy, completeness, or usefulness of any nutritional
          information or ingredients in the Food Database. Under no
          circumstances is FITFLEX responsible for any loss or damage resulting
          from reliance on nutritional information. You are solely responsible
          for ensuring the accuracy, completeness, and usefulness of nutritional
          information in the Food Database.
        </p>
        <p style={{ textAlign: "left" }}>
          {" "}
          4.2.3 FITFLEX does not verify the licensing of users who identify as
          professional trainers or dieticians in all applicable jurisdictions.
          Users must consider the risk associated with relying on advice from
          users who may not be licensed where they offer their services. FITFLEX
          assumes no responsibility for any loss or damage resulting from
          reliance on information or advice provided by users.
        </p>
        <h4 style={{ textAlign: "left", fontWeight: "700" }}>
          {" "}
          4.3 Not Medical Advice
        </h4>
        <p style={{ textAlign: "left" }}>
          {" "}
          4.3.1 All services provided through our Services, including Content,
          are for informational purposes only. FITFLEX is not a medical
          professional and does not provide medical services or advice.
          Information on or through the Services should not be construed as
          medical advice or diagnosis. Using the Services does not establish a
          doctor-patient relationship between you and FITFLEX.
        </p>
        <p style={{ textAlign: "left" }}>
          {" "}
          4.3.2 Consult a physician or medical professional with any
          health-related questions before starting dietary programs, exercise
          regimens, or other fitness and wellness activities referenced in the
          Services. If you are undergoing treatment, taking prescription
          medication, or following a therapeutic diet, consult your physician
          before using the Services. Represent that you are not using the
          Services for seeking medical attention and agree to consult your
          physician before using the Services, especially if at risk for
          exercise or diet-related issues. Follow your physician's advice if
          information from the Services conflicts with their advice.
        </p>
        <p style={{ textAlign: "left" }}>
          {" "}
          4.3.3 Specialized Content, such as coaching and guidance on fitness
          regimens and meal planning ("Plans"), is not a medical or health
          service. Plans are not a substitute for healthcare diagnosis and
          treatment. Seek advice from licensed healthcare professionals for
          dietary or health conditions. Interactions with Plans do not create a
          physician-patient relationship or fiduciary duty. You are solely
          responsible for interactions with Plans.
        </p>
        <h4 style={{ textAlign: "left", fontWeight: "700" }}> 4.4 Accuracy</h4>
        <p style={{ textAlign: "left" }}>
          {" "}
          4.4.1 The Services aim to provide information to support your wellness
          and fitness activities. Activity Tracking Services track physical
          movements and dietary activities, relying on data from various
          sources. Acknowledge that FITFLEX is not responsible for inaccuracies
          in data provided by Activity Tracking Services, including calorie and
          nutritional values data.
        </p>
        <h4 style={{ textAlign: "left", fontWeight: "700" }}>
          5. Usage Rights
        </h4>
        <p style={{ textAlign: "left" }}>
          5.1 You always retain ownership of Your Content but:
        </p>
        <p style={{ textAlign: "left" }}>
          (a) by posting, submitting or sending Your Content to FITFLEX you
          grant us (and our contractors and suppliers) a license at no cost to,
          and to permit our contractors and suppliers to, disclose the data to
          law enforcement agencies and any other authorized officers authorized
          by Pakistan Telecommunication Authority (PTA) under applicable laws,
          if required.
        </p>{" "}
        <p style={{ textAlign: "left" }}>
          5.2 Decompiling, reverse engineering, disassembling, or otherwise
          reducing the code used in any software in FITFLEX into a readable form
          in order to examine the construction of such software and/or to copy
          or create other products based (in whole or in part) on such software,
          is prohibited except to the extent permitted by law.
        </p>{" "}
        <p style={{ textAlign: "left" }}>
          5.3 FITFLEX reserves to itself and Partner. All rights in and to
          FITFLEX not expressly granted to you in this Agreement.
        </p>{" "}
        <p style={{ textAlign: "left" }}>
          5.4 The FITFLEX name and trade marks (including all related branding,
          logos and domain names) are the sole and exclusive property of
          Fitflex.
        </p>
        <p style={{ textAlign: "left" }}>
          5.5 Anyone who believes that their intellectual property or related
          rights are infringed by any content on the FITFLEX should contact us
          and provide the Partner with all relevant information in writing
          including:
        </p>
        <p style={{ textAlign: "left" }}>
          (a) Identification of the content which is claimed to be infringed and
          its location on FITFLEX;
        </p>{" "}
        <p style={{ textAlign: "left" }}>
          (b) Which rights are claimed to be infringed by that content; and
        </p>
        <p style={{ textAlign: "left" }}>
          (c) The complainant's full contact details (including physical
          address, telephone number, and email address).
        </p>
        <p style={{ textAlign: "left" }}>
          Without prejudice to the legal rights, further information and actions
          may be requested before steps can be taken by Partner.
        </p>
        <p style={{ textAlign: "left" }}>
          5.6 We reserve the right to suspend or cancel your access to FITFLEX
          any time in our absolute discretion.
        </p>{" "}
        <h4 style={{ textAlign: "left", fontWeight: "700" }}>
          6. Usage Policy
        </h4>
        <p style={{ textAlign: "left" }}>6.1 You agree that at all times:</p>
        <p style={{ textAlign: "left" }}> (a) Your Content shall not:</p>
        <p style={{ textAlign: "left" }}>
          (i) be pornographic or obscene, or contain sexually explicit content
          or child pornography;
        </p>
        <p style={{ textAlign: "left" }}>
          (ii) Defame or denigrate, nor impersonate or misrepresent, other
          persons;
        </p>
        <p style={{ textAlign: "left" }}>
          (iii) Infringe privacy, applicable law or rights of publicity or
          confidentiality;
        </p>
        <p style={{ textAlign: "left" }}>
          (iv) Incite racial or religious hatred;
        </p>
        <p style={{ textAlign: "left" }}>
          (v) Threaten, harass, intimidate or incite violence against any
          individual or group;
        </p>{" "}
        <p style={{ textAlign: "left" }}>
          (vi) be unlawful or encourage unlawful activity including use or sale
          of prohibited drugs, terrorism or crime; or
        </p>{" "}
        <p style={{ textAlign: "left" }}>
          (vii) Advertise or promote business, political or religious
          activities;
        </p>
        <p style={{ textAlign: "left" }}>
          (viii) contain any material which may breach or cause to breach
          national security or national integrity;
        </p>{" "}
        <p style={{ textAlign: "left" }}>
          (b) You should not, nor attempt to interfere with or disrupt the
          operation of FITFLEX and must notify us immediately of any breach of
          security or unauthorized use of FITFLEX of which you become aware; and
        </p>
        <p style={{ textAlign: "left" }}>
          (c) You shall not, nor attempt to, use FITFLEX or any third-party
          service accessible via FITFLEX:
        </p>{" "}
        <p style={{ textAlign: "left" }}>
          (i) in any manner which is unlawful or inconsistent with this
          Agreement or fraudulent, malicious, illegal, or in breach of
          applicable law, including by hacking or inserting a virus, worm,
          malicious code or harmful data into FITFLEX, or could otherwise
          threaten or compromise the security or integrity of our systems;
        </p>{" "}
        <p style={{ textAlign: "left" }}>
          (ii) Through any automated means or in any manner that could damage,
          disable, impair or seek to obtain unauthorized access to our systems
          or interfere with ot
        </p>
        <p style={{ textAlign: "left" }}>
          (iii) To collect, scrape or harvest information or data from any user
          or our systems or attempt to interfere with transmissions to or from
          the servers running FITFLEX;
        </p>{" "}
        <p style={{ textAlign: "left" }}>
          (iv) For any commercial or business activity including to send spam or
          other unsolicited or bulk communications or any form of commercial
          communications or marketing; or
        </p>{" "}
        <p style={{ textAlign: "left" }}>
          (v) To make unauthorized use, copies or distribution of content
          obtained via FITFLEX or to transmit counterfeit or pirated material
          nor otherwise infringe the intellectual property or related rights of
          FITFLEX or any third party. YOUR COMPLIANCE WITH THE ABOVE IS AN
          IMPORTANT LEGAL OBLIGATION AND YOU SHOULD CONSIDER IT CAREFULLY. If
          you have any questions about the above, please contact us.
        </p>{" "}
        <p style={{ textAlign: "left" }}>
          6.2 If you breach Clause 6.1 above you will be in breach of this
          Agreement and in addition to our rights of termination of this
          Agreement under Clause 7 below:
        </p>{" "}
        <p style={{ textAlign: "left" }}>
          (a) We may take legal action against you; and
        </p>
        <p style={{ textAlign: "left" }}>
          (b) You may have additional legal liability, not just to us, but also
          to other users, the providers of Content displayed in Official
          Accounts and other third parties (together, the Additional Parties)
          who may also take action against you. You will indemnify Fitflex, will
          be liable to damages and you also agree to compensate the Additional
          Parties for any loss or damage that we or the Additional Parties may
          suffer arising out of your breach of Clause 5.1 and for any other
          breach of this Agreement, improper use of FITFLEX and/or breach of any
          law or the rights of a third party from your account.
        </p>
        <p style={{ textAlign: "left" }}>
          6.3 We shall determine compliance with the Usage Policy at our sole
          discretion, acting reasonably. We may (but do not promise that we
          will) disable and/or delete Your Content which
        </p>{" "}
        <h4 style={{ textAlign: "left", fontWeight: "700" }}>7. Feedback </h4>
        <p style={{ textAlign: "left" }}>
          7.1 “Feedback” shall mean any input regarding our products and/or
          services (including FITFLEX), including changes or suggested changes
          to our current or future products and/or services.
        </p>{" "}
        <p style={{ textAlign: "left" }}>
          7.2 All Feedback provided by you shall not be considered confidential
          information and shall be received and treated by us on a
          non-confidential and unrestricted basis.
        </p>{" "}
        <p style={{ textAlign: "left" }}>
          7.3 You agree that FITFLEX and partner. shall have and retain all
          ownership and intellectual property rights (including all derivatives
          or improvements thereof) in and to any Feedback provided by you or any
          other party, and acknowledge that we may use the Feedback for any
          purpose, including but not limited to incorporation or implementation
          of such Feedback into a FITFLEX product or service, and to display,
          market, sublicense and distribute such Feedback as incorporated or
          embedded in any product or service distributed or offered by us.
        </p>{" "}
        <h4 style={{ textAlign: "left", fontWeight: "700" }}>
          8. Termination and Suspension{" "}
        </h4>
        <p style={{ textAlign: "left" }}>
          8.1 Subject always to Clause 7.3, you may terminate this Agreement at
          any time by cancelling your subscription to your account by immediate
          notice provided to us in accordance with Clause 10.2 or using any
          specific termination option in FITFLEX that we may make available.
        </p>{" "}
        <p style={{ textAlign: "left" }}>
          8.2 Subject always to Clause 2.7, we may terminate this Agreement on
          immediate notice provided to you in accordance with Clause 10.2 if:
        </p>{" "}
        <p style={{ textAlign: "left" }}>
          (a) You are in material or persistent breach of any provision of this
          Agreement.
        </p>{" "}
        <p style={{ textAlign: "left" }}>
          (b) We intend to cease providing FITFLEX (in whole or in part).
        </p>
        <p style={{ textAlign: "left" }}>8.3 Termination of this Agreement:</p>
        <p style={{ textAlign: "left" }}>
          (a) Will mean that you will no longer be able to access and use
          FITFLEX.
        </p>{" "}
        <p style={{ textAlign: "left" }}>
          (b) Will require you to immediately delete or remove FITFLEX from your
          device.
        </p>{" "}
        <h4 style={{ textAlign: "left", fontWeight: "700" }}>
          9. Disclaimer of Warranties
        </h4>
        <p style={{ textAlign: "left" }}>
          To the extent permitted by applicable law, FITFLEX does not make any
          representations, warranties or guarantees to you regarding Fitflex,
          including, but not limited to, its quality, functionality,
          availability, accessibility or performance. Fitflex is provided to you
          on an "as is" and "as available" basis. Fitflex does not assume
          liability for any injuries or damages you may sustain that result from
          your use of, or inability to use, the features of the App. If you are
          not satisfied with the quality, functionality, availability,
          accessibility or performance of Fitflex, you may cancel your account
          as set out in clause 7.1 above. In jurisdictions that prohibit the
          disclaimer of implied warranties or the exclusion of certain
          warranties, FITFLEX shall disclaim and exclude warranties to the
          maximum extent permitted by law. This clause 8 shall survive the
          termination of this Agreement.
        </p>
        <h4 style={{ textAlign: "left", fontWeight: "700" }}>
          10. Limitation of Liability
        </h4>
        <p style={{ textAlign: "left" }}>
          Liability of Fitflex will be limited and it shall not be liable for
          any claims, proceedings or actions brought or made against the Partner
          by any third persons pursuant to a contractual relationship with you.
          Other than as provided above, Fitflex affiliates will not be liable to
          you for any loss or damages, including without limitation, any
          indirect, incidental, special, exemplary, punitive or consequential
          damages. This clause 9 shall survive the termination of this
          agreement.
        </p>
        <h4 style={{ textAlign: "left", fontWeight: "700" }}>
          11. Claims of Copyright Infringement
        </h4>
        <p style={{ textAlign: "left" }}>
          11.1 Notifications of claimed copyright infringement and counter
          notices must be sent to our designated copyright agent:
          customer.support@fitflexapp.com
        </p>
        <p style={{ textAlign: "left" }}>
          11.2 We are only able to accept notices in the languages in which we
          make this Agreement available.
        </p>{" "}
        <p style={{ textAlign: "left" }}>
          11.3 We will respond expeditiously to claims of copyright infringement
          that are reported to our designated copyright agent, identified above,
          in accordance with the U.S. Digital Millennium Copyright Act of 1998
          or, as applicable, other laws.
        </p>
        <h4 style={{ textAlign: "left", fontWeight: "700" }}>12. Disputes </h4>
        <p style={{ textAlign: "left" }}>
          12.1 We cannot and do not judge or arbitrate disputes between users of
          FITFLEX or between users and third parties. You must settle any
          disputes directly with other users and third parties. If a user has
          breached this Agreement with us, we may, but do not undertake to, take
          action in respect of that.
        </p>
        <h4 style={{ textAlign: "left", fontWeight: "700" }}>13. Contact Us</h4>
        <p style={{ textAlign: "left" }}>
          13.1 Unless otherwise specified, any notices that you are required to
          give us under this Agreement should be sent to
          customer.support@fitflexapp.com
        </p>
        <p style={{ textAlign: "left" }}>
          We may send any notices that we are required to give you under this
          Agreement by any of the following methods: posting to your account,
          email to the email address which you have provided to us and/or
          placing a notice on FITFLEX.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfUse;
