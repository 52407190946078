import React, { useEffect } from "react";
import "../styles/exercise.css";
import { PlayCircleOutlined } from "@ant-design/icons";
import clock from "../assets/images/Clock.png";
import eye from "../assets/images/Eye.png";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";

const ExerciseVideoCard = (props) => {
  Sentry.configureScope((scope) =>
    scope.setTransactionName("Workout Video Card")
  );

  const navigate = useNavigate();

  return (
    <Link
      to={`/exercise/${props.id}`}
      // onClick={() => {
      //   window.open(`/exercise/${props.id}`, "_blank", "noopener,noreferrer");
      // }}
      state={{
        image: props.thumbnail,
        description: props.description,
        duration: props.duration,
        name: props.name,
      }}
      // onClick={() => {
      //   localStorage.setItem("exerciseProps", JSON.stringify(props));
      //   console.log(
      //     "exercise Props are :::",
      //     localStorage.getItem("exerciseProps")
      //   );
      // }}
      target="_blank"
      // rel="noopener noreferrer"
    >
      <div className="exercise-video-card">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <img className="exerciseVideoImg" src={props.thumbnail} />
          <PlayCircleOutlined
            style={{
              fontSize: "30px",
              color: "#ff0101",
              marginTop: "-35px",
              marginLeft: "10px",
              cursor: "pointer",
            }}
          />
        </div>
        {/* <p className="workout-catagory-text">{props.catagoryType}</p> */}
        <p className="exercise-title-text">{props.name} </p>
        <div className="duration-and-views-div">
          <img src={clock} className="clock-n-eye" />
          <p className="duration-and-views-text">
            {props.duration / 60 + "min"}
          </p>
          {/* <img
            src={eye}
            className="clock-n-eye"
            style={{ marginLeft: "10px" }}
          /> */}
          {/* <p className="duration-and-views-text">{props.views}</p> */}
        </div>
      </div>
    </Link>
  );
};
export default Sentry.withProfiler(ExerciseVideoCard);
