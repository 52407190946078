import React, { useState, useEffect } from "react";
import { getAlljobs } from "../api/requests";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import SiteHeader from "../components/header";
import MiniHeader from "../components/miniHeader";
import Workout from "../components/getWorkout";
import Footer from "../components/footer";
import JobCard from "../components/jobCard";
import "../styles/about.css";
import * as Sentry from "@sentry/react";

const Career = () => {
  Sentry.configureScope((scope) => scope.setTransactionName("Jobs Main Page"));

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(async () => {
    getAlljobs().then((data) => {
      if (data.status === 200) {
        setItems(data.data);
        setLoading(false);
      }
    });
  }, []);
  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 45, color: "#FF1E1E", marginBottom: 20 }}
      spin
    />
  );
  if (loading) {
    return (
      <div class="loader">
        <Spin className="spinner" indicator={antIcon} tip="Loading" />
      </div>
    );
  }
  return (
    <div className="container-div">
      <div style={{ marginBottom: "50px" }}>
        <SiteHeader />
      </div>
      <MiniHeader name={"WORK WITH US"} />
      <div className="headings-and-search-div">
        <p className="small-text-jobs" style={{ marginLeft: "60px" }}>
          Current Openings
        </p>
        <p className="job-title-text">
          Apply today and help us change an industry.
        </p>
      </div>
      <div className={"jobs-display-flexbox-div"}>
        {items.length > 0 ? (
          <div className="all-jobs-div">
            {items.length > 0 &&
              items.map((key, index) => {
                return (
                  <JobCard
                    job={key.title}
                    city={key.location}
                    created_date={key.created_date}
                    overview={key.description}
                    id={key.job_id}
                    alljobs={items}
                  />
                );
              })}
          </div>
        ) : (
          <h1
            style={{ marginLeft: "50px", fontSize: "30px", fontWeight: "700" }}
          >
            NO JOBS YET !!!
          </h1>
        )}
      </div>

      <Workout />
      <Footer />
    </div>
  );
};

export default Sentry.withProfiler(Career);
